import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { SettingTemplateState } from "src/@types/settingTemplateState";
import { ResponseSettingTemplate } from "src/@types/settingTemplate";
import apiClient from "../../utils/axios";

const initialState: SettingTemplateState = {
  isLoading: false,
  error: "",
  dataSettingTemplate: null,
  headers: [],
};

const slice = createSlice({
  name: "settingTemplate",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.dataSettingTemplate = action.payload.response.data;
    },

    // GET LICENSE
    getLayoutsTemplateSuccess(state, action) {
      state.isLoading = false;
      state.dataSettingTemplate = action.payload.setting;
      state.headers = action.payload.headers;
      // console.log("getLayoutsTemplateSuccess: " + state.headers);
      // console.log("getLayoutsTemplateSuccess: " + state.dataSettingTemplate);
    },

    setPatternSetting(state, action) {
      if (state.dataSettingTemplate?.pattern) {
        state.dataSettingTemplate!.pattern = action.payload;
        // console.log(action.payload);
        // console.log("setPatternSetting: " + state.dataSettingTemplate?.pattern);
      }
    },
    setSetting(state, action) {
      if (state.dataSettingTemplate?.filter) {
        state.dataSettingTemplate!.filter = action.payload.filter;
      }
      if (state.dataSettingTemplate?.limit) {
        state.dataSettingTemplate!.limit = action.payload.limit;
      }
      // console.log("start");
      // console.log(state.dataSettingTemplate?.filter);
      // console.log(state.dataSettingTemplate?.limit);
      // console.log("end");
    },
  },
});

// Reducer
export default slice.reducer;
export function getSettingTemplate(template_id: String) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `setting-template/${template_id}`;
      const response = await apiClient.get<ResponseSettingTemplate>(url);
      dispatch(
        slice.actions.getLayoutsTemplateSuccess({
          setting: response.data.settings[0],
          headers: response.data.headers,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSettingPattern(pattern: any) {
  return () => {
    dispatch(slice.actions.setPatternSetting(pattern));
  };
}

export function updateSetting(filter: any, limit: number) {
  return () => {
    dispatch(
      slice.actions.setSetting({
        filter: filter,
        limit: limit,
      })
    );
  };
}
