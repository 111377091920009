import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import apiClient from '../../utils/axios';
import { resendMessageState } from 'src/@types/resendMessageState';

const initialState: resendMessageState = { isLoading: false, error: '', status: false };

const slice = createSlice({
  name: 'resendMessage',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //REMOVE USER SUCCESS
    resendMessageSuccess(state, action) {
      state.isLoading = false;
      state.status = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export function resendMessage(id: String, companyID: String) {
  return async () => {
    try {
      const url = 'messages/' + companyID + '/invites/resend';
      await apiClient.post(url, { id: id });
      dispatch(slice.actions.resendMessageSuccess(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
