import { useAuth0 } from '@auth0/auth0-react'
import React from 'react';
import { Loading } from 'src/components/ex/components/loading';
import { CreateUserProfile } from 'src/layouts/header-only-layout';
import { initExAuth } from 'src/redux/slices/auth';
import { dispatch, useSelector } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import apiClient from 'src/utils/axios';

type Props = React.PropsWithChildren;

export const Auth0Guard = (props: Props) => {
    const { isAuthenticated, isLoading, loginWithRedirect, user, logout, getAccessTokenSilently } = useAuth0();
    const [isLoadingToken, setIsLoadingToken] = React.useState(true);

    const accessTokenRef = React.useRef<string>();

    const authState = useSelector(state => state.auth);

    React.useEffect(() => {
        if (!isLoading && !isAuthenticated) {
          loginWithRedirect();
        }
        if (isAuthenticated && !user?.email_verified) {
            return logout({
              returnTo: window.location.origin + PATH_DASHBOARD.invoice.list,
            });
         }
        if(isAuthenticated) {
            getAccessTokenSilently()
              .then((accessToken) => {
                accessTokenRef.current = accessToken;
              })
              .then(() => {
                apiClient.interceptors.request.use((config) => {
                  if (config.headers) {
                    config.headers[
                      "Authorization"
                    ] = `Bearer ${accessTokenRef.current}`;
                  }
                  return config;
                });

                apiClient.interceptors.response.use(
                  (response) => response,
                  async (error) => {
                    const config = error.config;
                    if (error?.response?.status === 401 && !config?.sent) {
                      config.sent = true;
                      const accessToken = await getAccessTokenSilently();
                      if (accessToken) {
                        config.headers[
                          "Authorization"
                        ] = `Bearer ${accessToken}`;
                        accessTokenRef.current = accessToken;
                      }
                      return apiClient(config);
                    }
                    return Promise.reject(error);
                  }
                );
              })
              .then(() => {
                setIsLoadingToken(false);
              })
              .then(() => {
                dispatch(initExAuth());
              });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isAuthenticated])

    const isAuth0Ready = !isLoading && isAuthenticated && user?.email_verified && !isLoadingToken;

    const isNotExistEmail = isAuth0Ready && !authState.isLoading &&  !authState.isExist;

    if(isNotExistEmail) {
        return <CreateUserProfile/>
    }
    
    if(authState.isExist) {
        return<>{props.children}</>
    }


    return <Loading/>
}