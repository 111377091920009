// @mui
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box, ListItemButtonProps } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// guards
import Iconify from '../../Iconify';
import { NavItemProps } from '../type';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';

// ----------------------------------------------------------------------

type Props = NavItemProps & ListItemButtonProps;
// EX3-955: fix show tooltip for side menu
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#434343",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#434343",
    borderRadius: "4px",
    fontFamily: "Meiryo UI, sans-serif",
    fontSize: '15px',
    fontWeight: 'bold',
  },
}));

export default function NavItem({ item, depth, active, open, isCollapse, ...other }: Props) {
  const { translate } = useLocales();

  const { title, icon, info, children, disabled, caption } = item;

  const renderContent = (
    <ListItemStyle depth={depth} active={active} disabled={disabled} {...other}>
      {icon &&
        <CustomTooltip title={title} placement="right">
          <ListItemIconStyle>{icon}</ListItemIconStyle>
        </CustomTooltip>}

      {/* {depth !== 1 && <DotIcon active={active && depth !== 1} />} */}
      <ListItemTextStyle
        isCollapse={isCollapse}
        primary={translate(title)}

        secondary={
          caption && (
            <Tooltip title={translate(caption)} placement="top-start">
              <span>{translate(caption)}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
          color: '#636363',
          fontSize: '14px', fontWeight: 'bold',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
          color: '#636363',
          fontSize: '14px', fontWeight: 'bold',
        }}
      />

      {!isCollapse && (
        <>
          {info && (
            <Box component="span" sx={{ lineHeight: 0 }}>
              {info}
            </Box>
          )}

          {!!children && (
            <Iconify
              icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
            />
          )}
        </>
      )}
    </ListItemStyle>
  );

  return renderContent;
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          // width: 4,
          // height: 4,
          // borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}
