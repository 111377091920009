import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

interface BindColor {
    backgroundColor: string;
    borderColor: string;
    color: string;
    hover: {
        backgroundColor: string;
        borderColor: string;
    };
}

const bindColorStyles = {
    mainly: {
        boderRadius: '4px',
        backgroundColor: '#085749',
        color: '#FFFFFF',
        hover: {
            backgroundColor: '#085749',
        },
    },
    primary: {
        backgroundColor: '#0066FF',
        borderColor: '#0066FF',
        color: '#FFFFFF',
        hover: {
            backgroundColor: '#0066FF',
            borderColor: '#3D00FF',
        },
    },
    danger: {
        backgroundColor: '#FF3D00',
        borderColor: '#FF3D00',
        color: '#FFFFFF',
        hover: {
            backgroundColor: '#FF3D00',
            borderColor: '#B43006',
        },
    },
    lawngreen: {
        backgroundColor: '#2DB19A',
        borderColor: '#2DB19A',
        color: '#FFFFFF',
        hover: {
            backgroundColor: '#2DB19A',
            borderColor: '#259A85',
        },
    },
    light: {
        backgroundColor: '#FFFFFF',
        borderColor: '#C2C2C2',
        color: '#8D8D8D',
        borderStyle: 'none',
        hover: {
            backgroundColor: '#FFFFFF',
            borderColor: '#C2C2C2',
        },
        after: {
            borderStyle: 'none',
        },
    },
    darkgray: {
        backgroundColor: '#F5F2F2',
        borderColor: '#C2C2C2',
        color: '#8D8D8D',
        hover: {
            backgroundColor: '#F5F2F2',
            borderColor: '#BEBABA',
        },
    },
    maingray: {
        backgroundColor: '#949393',
        borderRadius: '4px',
        // borderColor: '#C2C2C2',
        color: '#FFFFFF',
        hover: {
            // backgroundColor: '#F5F2F2',
            // borderColor: '#BEBABA',
            backgroundColor: '#949393',
            opacity: 0.5,
        },
    }
}

const CustomButton = styled(Button)((props: { bindcolordata: BindColor }) => ({
    fontSize: 15,
    backgroundColor: props.bindcolordata.backgroundColor,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: props.bindcolordata.borderColor,
    color: props.bindcolordata.color,
    '&:hover': {
        backgroundColor: props.bindcolordata.hover.backgroundColor,
        borderColor: props.bindcolordata.hover.borderColor,
    },
}))

const getStyleProfile = (customstyle: string) => {

    switch (customstyle) {
        case 'mainly':
            return bindColorStyles.mainly
        case 'primary':
            return bindColorStyles.primary
        case 'danger':
            return bindColorStyles.danger
        case 'lawngreen':
            return bindColorStyles.lawngreen
        case 'light':
            return bindColorStyles.light
        case 'darkgray':
            return bindColorStyles.darkgray
        case 'maingray':
            return bindColorStyles.maingray
    }

};

const ProfileButton = (props: any) => {
    const { customstyle, ...other } = props
    const bindColorData = getStyleProfile(customstyle) || bindColorStyles.light;
    return <CustomButton bindcolordata={bindColorData} {...other} />
}

export default ProfileButton
