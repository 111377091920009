import { styled } from "@mui/material";
import { COLORS } from "src/utils/colors";

export const Input = styled('input')({
  width: "100%",
  border: '1px solid #CECECE',
  outline: 'none',
  backgroundColor: 'white',
  color: '#5A607F',
  height: '30px',
  fontFamily: "Meiryo UI, sans-serif",
  fontSize: '18px',
  padding: '0px 8px',
  borderRadius: '2px',
  '::placeholder': {
    color: COLORS.placeholderGrey
  },

});