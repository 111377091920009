
import {
  TableRow,
  TableCell,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  styled,
  Grid,
} from '@mui/material';
import CssLog from 'src/utils/CssLog';
import { ExQuestionMarkIcon } from "src/assets/ExQuestionMarkIcon";
import { closeSVGIcon } from 'src/assets';
import React from 'react';
type Props = {
  message: String;
  onClickOk: VoidFunction;
  onClickCancel: VoidFunction;
  open: boolean;
  close: VoidFunction;
  title: string;
  header?: string;
};



const CloseButton = styled(Box)({
  top: "18px",
  right: "20px",
  position: "absolute",
  cursor: "pointer",
  zIndex: 1,
});

export default function AcceptDialog({
  message,
  title,
  header,
  onClickOk,
  onClickCancel,
  close,
  open
}: Props) {


  const handleCancel = () => {
    onClickCancel();
    close();
  };

  const handleOK = () => {
    onClickOk();
  };

  const [isDisable, setIsDisable] = React.useState(false);


  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleCancel}
      PaperProps={{
        style: {
          borderRadius: 5,
          width: '659px',
          height: '255px',
          maxWidth: "unset"
        }
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          pl: '18px'
        }}
        style={{
          ...CssLog().popup.topViewAccept,
        }}>
        <ExQuestionMarkIcon />
        <DialogTitle style={{ ...CssLog().popup.title, padding: 'unset', paddingLeft: '16px' }} sx={{ wordBreak: 'break-word' }}>{title}</DialogTitle>

        <CloseButton onClick={handleCancel}>
          <img alt="close" width={14} height={14} src={closeSVGIcon} />
        </CloseButton>
      </Grid>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
      >
        <Box sx={{
          width: "100%",
          margin: "auto 0",
          overflowY: "auto ",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <TableRow>
            <TableCell style={{ padding: 'unset' }}>
              <DialogContent style={{
                padding: '0px 99px 0px 99px',
                maxHeight: '133px'
              }}>
                {(header !== undefined) ? < DialogTitle style={{ ...CssLog().popup.title }} sx={{ wordBreak: 'break-word', justifyContent: 'center', padding: 'unset' }}>{header}</DialogTitle> : undefined}
                <DialogContentText style={{ ...CssLog().popup.content }} sx={{ overflowWrap: 'break-word', justifyContent: 'center' }}>
                  {message}
                </DialogContentText>
              </DialogContent>
            </TableCell>
          </TableRow>
        </Box>
        {/* <Label sx={{ ...CssLog().popup.line }}></Label> */}
        <DialogActions style={{ marginTop: -24, justifyContent: 'center', padding: '24px' }}>
          <Button onClick={handleCancel} sx={{ ...CssLog().popup.buttonCommon }} style={{ backgroundColor: '#FFFFFF', borderColor: '#949393', borderRadius: '4px', color: '#949393' }}>キャンセル</Button>
          <Button disabled={isDisable} onDoubleClick={() => { }} onClick={() => {
            setIsDisable(true);
            handleOK();
            setTimeout(function () {
              setIsDisable(false);
            }, 500);
          }} sx={{ ...CssLog().popup.buttonCommon }} style={{ ...CssLog().popup.successButton, borderRadius: '4px', }}>OK</Button>
        </DialogActions>
      </Box>
    </Dialog >
  );
}
