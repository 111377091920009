import { Theme } from '@mui/material/styles';
import { COLORS } from './colors';

export default function CssLog(theme?: Theme) {
  return {
    textRed: {
      color: "#e96e6d",
    },
    solidRed: {
      color: "#e03131",
    },
    mainGray: {
      backgroundColor: "#8f8f8f",
    },
    mainGreen: {
      backgroundColor: "#085749",
    },
    mainWhite: {
      backgroundColor: "white",
    },
    tableBackroundColor: {
      backgroundColor: "white",
    },
    headerColor: {
      backgroundColor: "#F5F6FA",
    },
    whiteColor: {
      color: "#FFFFFF",
    },
    logList: {
      button: {
        marginRight: 10,
        fontWeight: "bold",
        borderRadius: "4px",
      },
      toggleButtonGroup: {
        height: 35,
        width: 148,
      },
      stack: {
      },
      tableCell: {
        fontWeight: "bold",
        paddingLeft: 32,
      },

      table: {
        marginTop: -5,
        marginRight: 13,
        marginLeft: 21,
      },
      // EX3-658: Fix bug layout
      tableLayout: {
        paddingTop: 10,
        paddingRight: 10,
        paddingLeft: 10,
      },
      headerTotal: {
        height: 25,
        marginLeft: 45,
        color: "#FFFFFF",
      },
      container: {
        paddingTop: 20,
        paddingBottom: 20,
      },
    },
    tableRow: {
      commonRow: {
        borderTop: "5px solid #ECECEC",
        backgroundColor: "white",
      },
      tableCell: {
        paddingRight: "20px",
      },
    },
    tableHeaderCustom: {
      heightHeader: {
        height: "80px",
      },
    },
    tableToolbar: {
      stack: {
        height: "46px",
        backgroundColor: "#085749",
      },
      tableCell: {
        color: "#085749",
        fontWeight: "bold",
      },
      paper: {
        display: "flex",
        alignItems: "center",
      },

      inputBase: {
        text: {
          marginLeft: "10px",
          textColor: "white",
        },
        textOpenFilter: {
          marginLeft: "10px",
          color: "#085749",
        },
        placeholder: {
          "::placeholder": {
            color: "white",
            opacity: 1,
          },
          ":-ms-input-placeholder": {
            color: "white",
          },
          "::-ms-input-placeholder": {
            color: "white",
          },
        },
        searchIcon: {
          height: 17,
          width: 17,
          marginRight: "14px",
          marginTop: "2px",
        },
      },
    },
    popup: {
      tableRow: {
        // marginTop: -4,
        minHeight: 165,
      },
      tableCell: {
        paddingRight: 0,
        paddingTop: 20,
      },
      topView: {
        borderRadius: 1,
        backgroundColor: "#EDEDED",
        height: "59px",
      },
      topViewError: {
        borderRadius: 1,
        backgroundColor: "#EDEDED",
        height: "59px",
      },
      topViewWarning: {
        borderRadius: 1,
        backgroundColor: "#EDEDED",
        height: "59px",
      },
      topViewAccept: {
        borderRadius: 1,
        backgroundColor: "#EDEDED",
        height: "59px",
      },
      icon: {
        marginLeft: '10px',
        width: '32px',
        height: '29px',
      },
      title: {
        fontFamily: "Meiryo UI, sans-serif",
        fontSize: "20px",
        fontWeight: "bold",
        color: COLORS.textGrey,
      },
      content: {
        fontFamily: "Meiryo UI, sans-serif",
        fontSize: "20px",
        maxLines: 2,
        color: "#677781",
      },
      line: {
        backgroundColor: "#70707038",
        height: 1.2,
      },
      cancelButton: {},
      successButton: {
        backgroundColor: COLORS.primary,
        color: "white",

        // marginRight: -7,
      },

      successRemoveUserButton: {
        backgroundColor: COLORS.primary,
        color: "white",
        // marginRight: -7,
      },
      buttonCommon: {
        borderColor: "#70707038",
        // m: 1,
        border: 1,
        width: 129,
        height: 31,
        // marginTop: 2,
        // marginBottom: -1,
      },
    },
  };
}

