import { PaginationData } from "src/config";
import { customFetcher } from "src/utils/axios";
import useSWR from "swr/immutable";

export type CompanyUser = {
  id: number;
  autho_id: string;
  mail: string;
  sei: string;
  mei: string;
  sei_katakana: string;
  mei_katakana: string;
  company_name: string;
  user_company: {
    role_id: number;
    company_id: number;
    can_add: boolean;
    can_edit: boolean;
    can_remove: boolean;
    company_name: string;
  }[];
};

const itemPerPape = 12;

export const useCompanyUsers = (companyId: string, page?: number) => {
  const { data, error, mutate, isValidating } = useSWR<PaginationData<CompanyUser[]>>(
    [`/companies/${companyId}/users?order=role,priorID,-lastUpdatedRole&limit=${itemPerPape}`, page], (url, page) => customFetcher(url, { page })
  );
  return {
    data,
    error,
    isLoading: (!data && !error) || isValidating,
    mutate,
  };
};

export const useCompanyAllUsers = (companyId: string, page?: number) => {
  const { data, error, mutate, isValidating } = useSWR<PaginationData<CompanyUser[]>>(
    [`/companies/${companyId}/all-users?order=role,priorID,-lastUpdatedRole`, page], (url, page) => customFetcher(url, { page })
  );

  return {
    data,
    error,
    isLoading: (!data && !error) || isValidating,
    mutate,
  };
};
