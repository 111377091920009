import * as React from "react";
import WarningSVGIcon from 'src/assets/warning_ic.svg';

import {
    TableRow,
    TableCell,
    Dialog,
    Avatar,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    styled,
    Grid,
} from '@mui/material';
import CssLog from 'src/utils/CssLog';
import { closeSVGIcon } from "src/assets";

type Props = {
    title: String;
    message: String;
    onClickOk: VoidFunction;
};

const CloseButton = styled(Box)({
    top: "18px",
    right: "20px",
    position: "absolute",
    cursor: "pointer",
    zIndex: 1,
});

export default function WarningDialog({
    title,
    message,
    onClickOk,
}: Props) {

    const [open, setOpen] = React.useState(true);

    const handleOK = () => {
        onClickOk();
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleOK}
            PaperProps={{
                style: {
                    borderRadius: 5,
                    width: '659px',
                    height: '255px',
                    maxWidth: "unset"
                }
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                    pl: '18px'
                }}
                style={{
                    ...CssLog().popup.topViewWarning,
                }}>
                <Avatar variant="rounded" style={{ ...CssLog().popup.icon }} src={WarningSVGIcon} />
                <DialogTitle style={{ ...CssLog().popup.title }} sx={{ wordBreak: 'break-word', padding: 'unset', paddingLeft: '16px' }}>{title}</DialogTitle>
                <CloseButton onClick={handleOK}>
                    <img alt="close" width={14} height={14} src={closeSVGIcon} />
                </CloseButton>
            </Grid>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
            }}
            >
                <Box sx={{
                    width: "100%",
                    margin: "auto 0",
                    overflowY: "auto ",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <TableRow>
                        <TableCell style={{ padding: 'unset' }}>
                            <DialogContent style={{
                                padding: '0px 99px 0px 99px',
                                maxHeight: '133px'
                            }}>
                                <DialogContentText style={{ ...CssLog().popup.content }} sx={{ overflowWrap: 'break-word', justifyContent: 'center' }}>
                                    {message}
                                </DialogContentText>
                            </DialogContent>
                        </TableCell>
                    </TableRow>
                </Box>
                {/* <Label sx={{ ...CssLog().popup.line }}></Label> */}
                <DialogActions style={{ marginTop: -24, justifyContent: 'center', }}>
                    <Button onClick={handleOK} sx={{ ...CssLog().popup.buttonCommon }} style={{ ...CssLog().popup.successButton, borderRadius: '4px', }}>OK</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
