const LogoutIcon = ({ width = 16, height = 16, color = 'rgb(102, 102, 102)' }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox="0 0 123.609 130.005"
            opacity='1'
            style={{
                width: width,
                height: height,
            }}
        >


            <g id="ログアウト_アイコン" transform="translate(-4.92)">
                <path id="パス_587" data-name="パス 587" d="M109.2,34.961a10.634,10.634,0,0,0-14.7,15.367c.033.026.058.059.091.085a39.64,39.64,0,0,1,12.63,29.219,40.5,40.5,0,0,1-80.987,0,39.689,39.689,0,0,1,12.63-29.219A10.633,10.633,0,1,0,24.343,34.87c-.033.026-.059.058-.091.085a61.812,61.812,0,1,0,84.945.013Z" transform="translate(0 -11.202)" fill={color} />
                <path id="パス_588" data-name="パス 588" d="M94.148,69.572c6.006,0,10.868-3.894,10.868-8.7V8.7c0-4.8-4.862-8.7-10.868-8.7S83.28,3.894,83.28,8.7V60.881c0,4.8,4.869,8.7,10.868,8.7Z" transform="translate(-27.424)" fill={color} />
            </g>
        </svg>
    )
}

export default LogoutIcon