import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { LicenseState } from 'src/@types/licenseState'
import { License } from 'src/@types/license';
import apiClient from '../../utils/axios';

// EX3-602: add api create and check license
const initialState: LicenseState = { isLoading: false, error: '', dataLicense: {success: false, enterprise_id: '', api_key: '', status: -2, message: '', error: ''}};

const slice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.dataLicense = action.payload.response.data;
    },

    // GET LICENSE
    getLicenseSuccess(state, action) {
      state.isLoading = false;
      state.dataLicense = action.payload;
    },
  },
});



// Reducer
export default slice.reducer;
export function getLicense(company_id: String) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `license/${company_id}`;
      const response = await apiClient.get<License>(url);
      dispatch(slice.actions.getLicenseSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}