// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

import { Auth0Provider } from '@auth0/auth0-react'
import { ENV_SETTING } from './config';
import apiClient from './utils/axios';
import { SWRConfig } from 'swr'

// ----------------------------------------------------------------------



export default function App() {

  const fetcher = (url: string) => apiClient.get(url).then(res => res.data);


  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
            <SWRConfig value={{fetcher}}>
              <Auth0Provider
                clientId={ENV_SETTING?.auth0ClientId ?? ''}
                domain={ENV_SETTING?.auth0Domain ?? ''}
                redirectUri={
                  window.location.origin +
                  '/auth/callback'
                }
                audience={`https://${ENV_SETTING?.auth0Domain}/api/v2/`}
                scope={
                  'read:client_grants create:client_grants read:posts'
                }
                useRefreshTokens
              >
                <ProgressBarStyle />
                <ScrollToTop />
                <Router />
              </Auth0Provider>
            </SWRConfig>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
