import { Box, MenuItem, styled } from "@mui/material"
import React from "react";
import { Button } from "src/components/ex/components/button";
import { COLORS } from "src/utils/colors";
import { useForm } from 'react-hook-form';
import { createUserProfile, CreateUserProfileData } from "src/redux/slices/auth";
import { useDispatch } from "src/redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Icon } from "@iconify/react";
import clsx from 'clsx'

const StyledBox = styled(Box)(({ theme, textTransformStyle, ellipsis }: any) => ({
  textTransform: textTransformStyle || 'none',
  whiteSpace: ellipsis ? 'nowrap' : 'normal',
  overflow: ellipsis ? 'hidden' : '',
  textOverflow: ellipsis ? 'ellipsis' : '',
}))

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  height: '52px',
  alignContent: 'right',
  justifyContent: 'right',
  verticalAlign: 'middle',
  '& span': {
    color: '#ffffff',
    fontWeight: 'bold',
  },
}))

const Span = ({ children, className, ellipsis, ...props }: any) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      component='span'
      lineHeight={1.2}
      {...props}
    >
      {children}
    </StyledBox>
  )
}

const Page = styled(Box)(() => ({
  background: '#EEEAEA',
  minHeight: '93vh !important',
}))

const Input = styled('input')({
  width: "100%",
  border: '1px solid #CECECE',
  outline: 'none',
  backgroundColor: 'white',
  color: '#5A607F',
  height: '38px',
  fontFamily: "Meiryo UI, sans-serif",
  fontSize: '18px',
  padding: '0px 8px',
  borderRadius: '2px',
  '::placeholder': {
    color: COLORS.placeholderGrey
  },
});

const InputBox = styled(Box)(() => ({
  "& input:hover": {
    border: '1px solid #000000 !important',
  },
  "& input:focus-visible": {
    outline: 'none',
    borderRadius: '3px !important',
    border: '2px solid #085749 !important',
  },
}))

const Header = styled(Box)({
  backgroundColor: '#085749',
  padding: '0px 10px',
  minWidth: '1vw !important',
  height: '52px',
  display: 'flex',
  verticalAlign: 'middle',
  justifyContent: 'space-between',
});

const Logo = styled('img')({
  height: 48
})

const MainSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F4F6FA',
  minHeight: '92vh !important',
  flexDirection: 'column',
});

const FormWrapper = styled('form')({
  borderRadius: 5,
  backgroundColor: '#FFFFFF',
  padding: '5px 30px 5px 30px',
  maxWidth: 950,
});

const FormTitle = styled(Box)({
  borderBottom: '1px solid #ACACAC',
  fontSize: 20,
  fontWeight: 'bold',
  paddingTop: '30px',
  color: '#707070',
})

const FormGrid = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  border: '0px solid ' + COLORS.borderGrey,
  color: COLORS.textGrey,
});

const FormRow = styled('tr')({
  display: 'flex'
})

const InputCell = styled('td')({
  width: '100%',
  'flex': '1',
  border: '1px solid ' + COLORS.borderGrey,
  display: "flex",
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 9px'
})

const InputCenterCell = styled('td')({
  width: '100%',
  'flex': '1',
  borderLeft: '1px solid ' + COLORS.borderGrey,
  borderRight: '1px solid ' + COLORS.borderGrey,
  display: "flex",
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 9px'
})

const LabelCell = styled('td')({
  height: '58px',
  border: '1px solid ' + COLORS.borderGrey,
  borderRight: '0px solid ' + COLORS.borderGrey,
  width: '240px',
  fontSize: '16px',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 9px',
  backgroundColor: COLORS.panelBg
})

const LabelCenterCell = styled('td')({
  height: '58px',
  borderLeft: '1px solid ' + COLORS.borderGrey,
  width: '240px',
  fontSize: '16px',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 10px',
  backgroundColor: COLORS.panelBg
})

const Badge = styled(Box)({
  width: '44px',
  height: '20px',
  backgroundColor: COLORS.badgeRed,
  color: 'white',
  textAlign: 'center',
  lineHeight: '20px',
  fontSize: '14px',
  borderRadius: '3px'
})

const InputGroup = styled(Box)({
  display: 'flex',
  width: '210px',
  justifyContent: 'space-between',
  'input': {
    width: '180px'
  }
})

const InputLabel = styled(Box)({
  marginTop: "5px",
  fontSize: "16px",
  fontWeight: "bold",
  color: COLORS.textGrey,
})

const ErrorAlert = styled(Box)({
  fontSize: '16px',
  maxHeight: '70px',
  padding: '10px 0px 10px 11px',
  borderRadius: '5px',
  margin: '20px 0px 20px',
  color: 'rgb(175, 68, 66)',
  backgroundColor: 'rgb(242, 222, 222)',
})

const KATAKANA_REQUIERED = 'MUST_BE_KATAKANA';

const katakanaReg = /^[ァ-ヾ]|[ｧ-ﾝﾞﾟ]+$/;

const fieldsNameMap: Record<string, string> = {
  'sei_katakana': 'セイ',
  'mei_katakana': 'メイ'
}

export const CreateUserProfile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<CreateUserProfileData>({
    mode: "onSubmit",
  });

  const { logout } = useAuth0();

  const [haveEmptyFields, setHaveEmptyFields] = React.useState(true);

  const watcher = watch(['mei', 'mei_katakana', 'sei', 'sei_katakana', 'company_name'])

  const dispatch = useDispatch();

  const onSubmit = (data: CreateUserProfileData) => {
    dispatch(
      createUserProfile({ ...data, company_name: data.company_name ?? "" })
    );
  }

  React.useEffect(() => {
    setHaveEmptyFields(watcher.includes(""));
  }, [watcher])


  const invalidKatakanaField = Object.entries(errors).find(
    ([_key, value]) =>
      value.type === "pattern" &&
      ["sei_katakana", "mei_katakana"].includes(_key)
  )?.[0];

  const isNotKatakana = Boolean(invalidKatakanaField);

  return (
    <Page>
      <Header>
        <Logo src="/assets/ex/ex-logo-white.png" />
        <StyledItem
          onClick={() => {
            logout({ returnTo: window.location.origin + PATH_DASHBOARD.invoice.list })
          }}
        >
          <Icon icon="fa:power-off" width={18} height={18} color={'#ffffff'} style={{ marginRight: '12px' }} />
          <Span> ログアウト </Span>
        </StyledItem>
      </Header>
      <MainSection>
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <FormTitle>EXQR現品照合のユーザー情報を登録</FormTitle>
          {isNotKatakana && (
              <ErrorAlert>
                {fieldsNameMap[invalidKatakanaField as string] +
                  "はカタカナで入力してください。"}
              </ErrorAlert>
              // <ErrorBox>
              //   {fieldsNameMap[invalidKatakanaField as string] + "はカタカナで入力してください。"}
              // </ErrorBox>
            )}
          <Box sx={{ padding: isNotKatakana ? "0px" : "20px 0px 0px 0px", margin: "20px 0px 0px" }}>
            
            <FormGrid>
              <FormRow>
                <LabelCell>
                  名前(姓/名) <Badge>必須</Badge>
                </LabelCell>

                <InputCell>
                  <InputGroup>
                    <InputLabel>姓</InputLabel>
                    <InputBox>
                      <Input
                        // Fix #EX3-587: add max length for input
                        {...register("sei", { required: true })}
                        placeholder="(例)山田"
                        maxLength={40}
                      />
                    </InputBox>

                  </InputGroup>

                  <InputGroup sx={{ marginLeft: "12px" }}>
                    <InputLabel>名</InputLabel>
                    <InputBox>
                      <Input
                        {...register("mei", { required: true })}
                        placeholder="(例)太郎"
                        maxLength={40}
                      />
                    </InputBox>

                  </InputGroup>
                </InputCell>
              </FormRow>

              <FormRow>
                <LabelCenterCell>
                  フリガナ(セイ/メイ)<Badge>必須</Badge>
                </LabelCenterCell>

                <InputCenterCell>
                  <InputGroup>
                    <InputLabel>セイ</InputLabel>
                    <InputBox>
                      <Input
                        {...register("sei_katakana", {
                          required: true,
                          pattern: {
                            value: katakanaReg,
                            message: KATAKANA_REQUIERED,
                          },
                        })}
                        placeholder="(例)ヤマダ"
                        maxLength={40}
                      />
                    </InputBox>

                  </InputGroup>

                  <InputGroup sx={{ marginLeft: "12px" }}>
                    <InputLabel>メイ</InputLabel>
                    <InputBox>
                      <Input
                        {...register("mei_katakana", {
                          required: true,
                          pattern: {
                            value: katakanaReg,
                            message: KATAKANA_REQUIERED,
                          },
                        })}
                        placeholder="(例)タロウ"
                        maxLength={40}
                      />
                    </InputBox>

                  </InputGroup>
                </InputCenterCell>
              </FormRow>

              <FormRow>
                <LabelCell>
                  会社名<Badge>必須</Badge>
                </LabelCell>
                <InputCell>
                  <InputBox>
                    <Input
                      sx={{ width: "450px" }}
                      placeholder="(例)株式会社エクス"
                      maxLength={40}
                      {...register("company_name", { required: true })}
                    />
                  </InputBox>

                </InputCell>
              </FormRow>
            </FormGrid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "5px 10px",
              margin: "40px 10px 30px",
            }}
          >
            <Button
              variant="contained"
              disabled={haveEmptyFields}
              type="submit"
            >
              登録
            </Button>
          </Box>
        </FormWrapper>
      </MainSection>
    </Page>
  );
}