import Logo from './exqr.png';

// EX3-735: add page do not have license
const FullLogoVert = ({ width = 250, height = 250 }) => {
    return (
        <img
            width={width}
            height={height}
            alt='EXQR'
            src={Logo}
            style={{
                display: 'inline-block'
            }}
        />
    )
}

export default FullLogoVert
