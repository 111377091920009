import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// @mui
import { Link } from "@mui/material";
//
import { NavListProps } from "../type";
import NavItem from "./NavItem";
import NavExtItem from "./NavExtItem";
import { getActive, isExternalLink } from "..";

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChildren: boolean;
  isCollapse?: boolean;
};

export default function NavList({
  data,
  depth,
  hasChildren,
  isCollapse = false,
}: NavListRootProps) {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const active = getActive(data.path, pathname);

  const [open, setOpen] = useState(active);

  const handleClickItem = () => {
    if (!hasChildren) {
      navigate(data.path);
    }
    setOpen(!open);
  };

  return (
    <>
      {isExternalLink(data.path) ? (
        <Link href={data.path} target="_blank" rel="noopener">
          <NavExtItem
            item={data}
            depth={depth}
            open={open}
            active={active}
            isCollapse={isCollapse}
          />
        </Link>
      ) : (
        // <Stack direction={{ sm: 'row' }}>
        //   <Box sx={{backgroundColor: 'red'}}>fff</Box>
        //   <NavItem
        //   item={data}
        //   depth={depth}
        //   open={open}
        //   active={active}
        //   isCollapse={isCollapse}
        //   onClick={handleClickItem}
        // />
        //   </Stack>

        <NavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isCollapse={isCollapse}
          onClick={handleClickItem}
        />
      )}
    </>
  );
}

// ----------------------------------------------------------------------
