// @mui
import { TableRow, TableCell } from '@mui/material';
//
import EmptyContent from '../EmptyContent';

// ----------------------------------------------------------------------

type Props = {
  isNotFound: boolean;
  colSpan: number;
  isCollapseSearch: boolean;
};

export default function TableNoData({ isNotFound, colSpan, isCollapseSearch }: Props) {
  return (
    <TableRow>
      {isNotFound && colSpan > 0 ? (
        <TableCell colSpan={colSpan}>
          <EmptyContent
            title="データがありません"
            sx={{
              top: isCollapseSearch ? '60%' : '50%'
            }}
          />
        </TableCell>
      ) : (colSpan === 0) ?
        (
          <TableCell colSpan={colSpan}>
            <EmptyContent
              title="条件を入力し、照合実績を検索してください"
              sx={{
                top: isCollapseSearch ? '60%' : '50%'
              }}
            />
          </TableCell>
        )

        : (
          <TableCell colSpan={colSpan} sx={{ p: 0 }} />
        )}
    </TableRow>
  );
}
