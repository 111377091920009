import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import LoadingScreen from '../components/LoadingScreen';
import { Auth0Guard } from 'src/guards/Auth0Guard';
import { RoleGuard } from 'src/guards/RoleGuard';
import { Callback } from 'src/pages/auth/Callback';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();


  // eslint-disable-next-line react-hooks/rules-of-hooks
  // EX3-658: Fix bug load page
  const isDashboard = pathname.includes('/dashboard');
  const isCSVLayout = pathname.includes('/csv-layout');
  const isLogsCSV = pathname.includes('/logs-csv');

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard || isCSVLayout || isLogsCSV} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        { path: "callback", element: <Callback /> },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <Auth0Guard>
          <DashboardLayout />
        </Auth0Guard>
      ),
      children: [
        { element: <Navigate to={'/dashboard/logs/list'} replace />, index: true },

        {
          path: "logs",
          children: [
            {
              element: <Navigate to="/dashboard/logs/list" replace />,
              index: true,
            },
            { path: "list", element: <InvoiceList /> }
          ],
        }
      ],
    },

    // upload CSV Routes
    {
      path: "logs-csv",
      element: (
        <Auth0Guard>
          <DashboardLayout />
        </Auth0Guard>
      ),
      children: [
        { element: <Navigate to={'/logs-csv/download'} replace />, index: true },
        {
          path: "download",
          element: <RoleGuard><DownloadLogsCSV /></RoleGuard>,
        }
      ],
    },

    // download logs CSV Routes
    {
      path: "csv-layout",
      element: (
        <Auth0Guard>
          <DashboardLayout />
        </Auth0Guard>
      ),
      children: [
        { element: <Navigate to={'/csv-layout/edit'} replace />, index: true },
        {
          path: "edit",
          element: <UploadCSV />,
        }
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <Navigate to={"/dashboard/logs/list"} />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/ScanLogsList')));
const UploadCSV = Loadable(lazy(() => import('../pages/dashboard/UpdateLayoutFromCSV')));
const DownloadLogsCSV = Loadable(lazy(() => import('../pages/dashboard/DownloadLogsCSV')));


const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
