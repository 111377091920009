import {
  Box,
  styled,
} from "@mui/material";
import useToggle from "src/hooks/useToggle";
import { AddConnectionPopup } from "../connection-menu/AddConnectionPopup";

const Item = styled(Box)({
  display: 'flex',
  justifyContent: "center",
  alignItems: 'center',
  fontSize: "14px",
  fontWeight: "bold",
  position: "absolute",
  marginLeft: '2px',
  paddingLeft: '2px',
  color: "#707070",
  height: "33px",
  width: '100%',
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: "0px 0px 3px 3px",
  top: '0px',
  left: "100%",
  backgroundColor: "white"
});

type AddConnectionProps = {
};

export const AddConnection = (props: AddConnectionProps) => {
  const { toggle, onClose, onOpen } = useToggle();

  return (
    <>
      <Item onClick={onOpen} > 新しい接続先の登録 </Item>
      <AddConnectionPopup
        open={toggle}
        onClose={onClose}
      />
    </>
  );
};
