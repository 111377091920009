import { Pagination, PaginationItem, PaginationProps, styled } from '@mui/material';
import { Icon } from "@iconify/react";
import { COLORS } from 'src/utils/colors';

const StyledPagination = styled(Pagination)({
  '.MuiPaginationItem-outlined': {
    backgroundColor: 'white',
  },
  '.Mui-selected': {
    backgroundColor: COLORS.primary + ' !important',
    color: 'white !important'
  }
})

export const ExPagination = (props: PaginationProps) => {
  return (
    <StyledPagination {...props}
    renderItem={(item) => (
      <PaginationItem
        components={{
          next: () => <Icon icon="ri:arrow-right-double-fill" />,
          previous: () => <Icon icon="ri:arrow-left-double-fill" />
        }}
        {...item}
      />
    )}/>
  )
}