import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { SettingSate } from "src/@types/settingState";
import moment from "moment";

const initialState: SettingSate = {
  isFirst: true,
  isOpenSetting: false,
  isUpdated: false,
  fromDate: moment().format("YYYY/MM/DD"),
  toDate: moment().format("YYYY/MM/DD"),
  searchText: "",
  isOutputCompleted: false,
  listNewFilter: [],
};

const slice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setIsFirstValue(state, action) {
      state.isFirst = action.payload;
    },
    setOjectSettingValue(state, action) {
      state.isOpenSetting = action.payload.isOpenSetting;
      state.isUpdated = action.payload.isUpdated;
    },
    setFromDateValue(state, action) {
      state.fromDate = action.payload;
      // console.log("setFromDateValue: " + state.fromDate);
    },
    setToDateValue(state, action) {
      state.toDate = action.payload;
    },
    setSearchTextValue(state, action) {
      state.searchText = action.payload;
    },
    setListNewFilterValue(state, action) {
      state.listNewFilter = action.payload;
      // console.log("setListNewFilterValue: " + state.listNewFilter)
    },
    setIsOutputCompletedValue(state, action) {
      state.isOutputCompleted = action.payload;
      // console.log("setIsOutputCompletedValue: " + state.isOutputCompleted)
    },
  },
});

// Reducer
export default slice.reducer;

export function setIsFirst(isFirst: boolean) {
  return () => {
    dispatch(slice.actions.setIsFirstValue(isFirst));
  };
}

export function setSettingValue(isOpenSetting: boolean, isUpdated: boolean) {
  return () => {
    dispatch(
      slice.actions.setOjectSettingValue({
        isOpenSetting: isOpenSetting,
        isUpdated: isUpdated,
      })
    );
  };
}

export function setFromDate(value: any) {
  return () => {
    dispatch(slice.actions.setFromDateValue(value));
  };
}

export function setToDate(value: any) {
  return () => {
    dispatch(slice.actions.setToDateValue(value));
  };
}

export function setSearchText(value: any) {
  return () => {
    dispatch(slice.actions.setSearchTextValue(value));
  };
}

export function setListNewFilter(value: any) {
  return () => {
    dispatch(slice.actions.setListNewFilterValue(value));
  };
}

export function setIsOutputCompleted(value: any) {
  return () => {
    dispatch(slice.actions.setIsOutputCompletedValue(value));
  };
}

export function setInitSettingValue() {
  return () => {
    const currentDate = moment().format("YYYY/MM/DD");
    dispatch(slice.actions.setFromDateValue(currentDate));
    dispatch(slice.actions.setToDateValue(currentDate));
    dispatch(slice.actions.setSearchTextValue(""));
    dispatch(slice.actions.setListNewFilterValue([]));
    dispatch(slice.actions.setIsOutputCompletedValue(false));
    // dispatch(slice.actions.setIsFirstValue(true));
  };
}
