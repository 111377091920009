// Profile
export const invalidParamProfileError = `全ての必須項目を入力してください。`

export const profileOtherError = `登録に失敗しました。後でもう一度やり直してください。`

// Change Password
export const pwNotmatched = 'パスワードと確認が同じであることを確認してください。'

export const pwNotMachedRegex = `※8文字以上を設定して下さい。
※以下の条件のうち、3つ以上を満たす必要があります。
　*小文字のアルファベット (a-z)
　*大文字のアルファベット (A-Z)
　*数字 (0-9)
　*特殊記号 (例 : !＠#$％^&*)
※以下のパスワードは設定できません。
　*前回と同じパスワード
　*安易に推測できるパスワード
　*個人データの一部を含むパスワード`

export const passwordStrengthError = 'パスワードが脆弱です。'

export const passwordHistoryError = 'パスワードが以前に使用されています。'

export const PasswordDictionaryError = 'パスワードが単純すぎます。'

export const passwordNoUserInfoError = 'ユーザー情報を含むパスワードは避けてください。'

export const otherChangePWError = `申し訳ございません。
ユーザーパスワード変更に何らかの理由によりエラーが発生しました。`

export const passwordLengthAtLeast = '8文字以上を設定して下さい。'

export const passwordRegexError = `※以下の条件のうち、3つ以上を満たす必要があります。
　*小文字のアルファベット (a-z)
　*大文字のアルファベット (A-Z)
　*数字 (0-9)
　*特殊記号 (例 : !＠#$％^&*)`
