import {
  styled,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Snackbar,
  Alert,
  AlertTitle,
  Portal,
} from "@mui/material";
import { COLORS } from "src/utils/colors";
import React from "react";
import { useRole } from "src/hooks/api/useRoles";
// import { mockCompanyId } from '../users-management-popup';
import { USER_ROLE_NAME } from "src/config";
import useToggle from "src/hooks/useToggle";
import AcceptDialog from "./AcceptDialog";
import { Icon } from "@iconify/react";
import apiClient from "src/utils/axios";
import { AxiosError } from "axios";
import { ExModal } from "../components/modal";
import { Input } from "../components/input";
import { Button } from "../components/button";
import { useDispatch } from 'src/redux/store';
import { getLicense } from "src/redux/slices/license";
import { ErrorDialog, SuccessDialog } from "src/components/table";

type Props = {
  open: boolean;
  onClose: () => void;
  connectionName: string;
  connectionId: number;
  reload: () => Promise<unknown>
};

const FormWrapper = styled(Box)({
  padding: "18px 19px"
})


const InputBox = styled(Box)(() => ({
  "& input:hover": {
    outline: 'none',
    border: '1px solid #000000 !important',
  },
  "& input:focus-visible": {
    outline: 'none',
    borderRadius: '2px !important',
    border: '2px solid #085749 !important',
  },
}))


const InputLabel = styled(Box)({
  fontFamily: "Meiryo UI, sans-serif",
  fontSize: '18px',
  fontWeight: 'bold',
  color: COLORS.textGray,
  '&.role-label': {
    marginTop: '5px'
  }
});

const RoleSelect = styled(Select)({
  outline: 'none',
  fontFamily: "Meiryo UI, sans-serif",
  borderRadius: '2px',
  backgroundColor: 'white',
  width: '100px',
  height: '30px',
  padding: '0px 8px',
  color: COLORS.grey,
  // border: '1px solid #CECECE',
  '&:focus': {
    border: '1px solid #CECECE',
  },
  '&:active': {
    border: '1px solid #CECECE'
  },
  '.MuiOutlinedInput-notchedOutline': {
    // border: 'none'
    border: '1px solid #CECECE',
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // borderRadius: '3px',
    border: '2px solid #085749 !important',
  },

  '& .MuiSelect-select': {
    padding: '0px',
    outline: 'none',
    borderRadius: '0px'
  },
  '& .MuiPaper-root': {
    borderRadius: '0px',
    padding: '0',
    marginTop: '2px !importain'
  },
});

const ButtonWrapper = styled(Box)({
  width: "100%",
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
})

const StyledIcon = styled(Icon)({
  userSelect: "none",
  width: "1em",
  height: "1em",
  display: "inline-block",
  fill: "currentColor",
  flexShrink: "0",
  transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  fontSize: "16px",
  right: "7px",
  position: "absolute",
  pointerEvents: "none",
  top: "calc(50% - .5em)",
  color: "#637381",
})

const SelectIcon = () => (
  <StyledIcon
    icon="ant-design:caret-down-filled"
  />
);


export const AddUserPopup = (props: Props) => {
  const emailRef = React.useRef<HTMLInputElement>(null);
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState<string | undefined>();
  const [isDuplicateEmail, setIsDuplicateEmail] = React.useState(false);

  const {
    toggle: isOpenComfirm,
    onClose: handleCloseConfirm,
    onOpen: handleOpenConfirm,
  } = useToggle();

  const { data } = useRole({ companyId: props.connectionId.toString() });

  const roles = data?.data;

  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  React.useEffect(() => {
    if (roles) setSelectedRole(roles[0].id.toString());
  }, [roles]);

  React.useEffect(() => {
    if (emailRef.current) {
      emailRef.current.value = '';
    }
    setIsValidEmail(false);
  }, [props.open])

  const dispatch = useDispatch();

  const handleAddUser = async () => {
    try {
      const response = await apiClient.post(`/companies/${props.connectionId}/users`, {
        mail: emailRef.current?.value,
        role_id: selectedRole,
      },);
      if (response) {
        // EX3-737: add limit user device
        dispatch(getLicense(props.connectionId.toString())).then(() => props.reload());
        handleCloseConfirm();
        props.onClose();
      }
    } catch (error) {
      if ((error as AxiosError).response?.status === 400) {
        setIsDuplicateEmail(true);
        handleCloseConfirm();
      }
    }

  }

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const isValid = validRegex.test(event.target.value);
    setIsValidEmail(isValid);
  };

  const handleRoleChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedRole(event.target.value as string);
  };

  return (
    <>
      <ExModal
        open={props.open}
        onClose={props.onClose}
        height={256}
        width={659}
        title={"新規ユーザー追加"}
      >
        <FormWrapper>
          <InputLabel>ユーザーID</InputLabel>
          <InputBox>
            <Input
              id="e-id"
              ref={emailRef}
              type="email"
              required
              onChange={handleEmailChange}
            />
          </InputBox>

          <InputLabel className="role-label">権限</InputLabel>
          <RoleSelect
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedRole}
            onChange={handleRoleChange}
            IconComponent={SelectIcon}
            MenuProps={{
              PaperProps: {
                sx: {
                  color: '#5A607F',
                  borderRadius: "1%",
                  maxHeight: 555
                },
              },
            }}
          >
            {roles?.map((role) => (
              <MenuItem value={role.id.toString()}>
                {USER_ROLE_NAME[role.id]}
              </MenuItem>
            ))}
          </RoleSelect>
          <ButtonWrapper>
            <Button
              // variant="contained"
              disabled={!isValidEmail}
              onClick={handleOpenConfirm}
            >
              追加
            </Button>
          </ButtonWrapper>
        </FormWrapper>
      </ExModal>
      <AcceptDialog
        title={""}
        header={emailRef.current?.value ?? ""}
        open={isOpenComfirm}
        onClickCancel={handleCloseConfirm}
        close={handleCloseConfirm}
        onClickOk={handleAddUser}
        message={`このユーザーIDを「${props.connectionName}」に登録します。よろしいですか?`}
      />

      {isDuplicateEmail &&
        (
          <SuccessDialog
            isError={true}
            title={""}
            message={"既に追加されているユーザーです"}
            onClickOk={() => {
              setIsDuplicateEmail(false);
            }}
            onCancel={() => {
              setIsDuplicateEmail(false);
            }}
          />
        )
      }


      {/* <Portal>
        <Snackbar
          sx={{ width: 475 }}
          open={isDuplicateEmail}
          onClose={() => setIsDuplicateEmail(false)}
          // TransitionComponent={}
          autoHideDuration={5000}
        >
          <Alert severity="error" sx={{ width: 475 }}>
            <AlertTitle>失敗した</AlertTitle>
            <strong>{"既に追加されているユーザーです"}</strong>
          </Alert>
        </Snackbar>
      </Portal> */}
    </>
  );
};
