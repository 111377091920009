import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, dispatch } from '../store';
import { DownloadCSVState } from 'src/@types/downloadCSV';
import { LayoutsDetails } from "src/@types/layoutsDetails";
import apiClient from '../../utils/axios';
import JSZip from 'jszip';

const initialState: DownloadCSVState = { isLoading: false, error: '', isSuccess: null, isReset: false };

const slice = createSlice({
  name: 'downloadCSV',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isReset = !state.isReset;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isSuccess = false;
      state.isReset = !state.isReset;
    },

    downloadCSVSuccess(state, action) {
      state.isLoading = false;
      state.error = '';
      state.isSuccess = action.payload;
      state.isReset = !state.isReset;
    },

    resetDownloadCSVState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.isSuccess = initialState.isSuccess;
      state.isReset = initialState.isReset;
    }
  },
});

function escapeRegExp(string: any) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function replaceAll(str: any, find: any, replace: any) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function groupByElement(input: Array<any>): Array<Array<any>> {
  const grouped: { [key: string]: Array<[any, any]> } = input.reduce((acc, current) => {
    const key = String(current[3]);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(current);
    return acc;
  }, {});

  return Object.values(grouped);
}


// Reducer
export default slice.reducer;
export const resetDownloadCSVState = slice.actions.resetDownloadCSVState;
export function downloadCSV(
  templateName: String,
  ids: Array<String>,
  isSelectAll: boolean,
  template_id: String,
  fromDate: String,
  toDate: String,
  searchText: String,
  excludedIds: String[],
  isOutputCompleted: boolean,
  isDownNewLayout: boolean,
  layoutCSV?: LayoutsDetails,
  limit?: string | null | undefined
): AppThunk<Promise<void>> {
  const formattedFromDate = replaceAll(fromDate, "/", "-");
  const formattedToDate = replaceAll(toDate, "/", "-");

  return async (_, getState) => {
    try {
      const selectAllValue: string = isSelectAll ? "1" : "0";
      const connectionId =
        getState().connections.selectedConnection?.company_id;
      // const url = "/qr-scan-results/csv/" + connectionId;
      const url = "/qr-scan-results/csv/view/" + connectionId;


      const params = {
        scan_ids: ids.toString(),
        select_all: selectAllValue,
        page: "1",
        page_limit: limit ? limit : "1000",
        template_id,
        start_date: formattedFromDate,
        end_date: formattedToDate,
        search_term: searchText,
        exclude_ids: excludedIds.toString(),
        is_downloaded: isOutputCompleted,
        is_custom: isDownNewLayout,
      };

      const result = await apiClient.get(url, { params });

      // EX3-601: Creat data for layout CSV
      var resData = "";
      var resLotData = "";

      let tmpLayoutCSV = layoutCSV?.rows || [];
      let tmpLayoutLotCSV = layoutCSV?.lotRows || [];

      // If choose type new layout
      if (isDownNewLayout) {
        result.data = String(result.data).substring(0, result.data.length - 1);
        let tmpData = result.data.split(/\r?\n/);
        for (let i = 0; i < tmpData.length; i++) {
          tmpData[i] = tmpData[i].split(',');
        };
        const listIndex: { no: number }[] = [];
        const listLotIndex: { no: number }[] = [];

        // setting position of Quantity
        let indexComputeTotal = -1;
        let posGetVariableCompute = 7;

        if (tmpLayoutLotCSV.length > 0) {
          // Case 2: download by custom csv type 2 (Lot)
          // Get list index has in tmpLayoutCSV base on tmpData
          //EX3-679-677: change layout CSV follow design, fix download CSV wrong design
          for (let i = 0; i < tmpLayoutCSV.length; i++) {

            // save position computing
            if (i === posGetVariableCompute) {
              indexComputeTotal = tmpLayoutCSV[i].layoutCellNo - 2;
            }

            //EX3-833: fix output CSV has duplicate item
            if (tmpLayoutCSV[i].layoutCellNo !== 0) {
              listIndex.push({ no: tmpLayoutCSV[i].layoutCellNo - 2 });
            }
            else {
              if (tmpLayoutCSV[i].defaultCell === "") {
                listIndex.push({ no: -1 });
              }
              else {
                listIndex.push({ no: -2 });
              }
            }
          }

          for (let i = 0; i < tmpLayoutLotCSV.length; i++) {
            //EX3-833: fix output CSV has duplicate item
            if (tmpLayoutLotCSV[i].layoutCellNo !== 0) {
              listLotIndex.push({ no: tmpLayoutLotCSV[i].layoutCellNo - 2 });
            }
            else {
              if (tmpLayoutLotCSV[i].defaultCell === "") {
                listLotIndex.push({ no: -1 });
              }
              else {
                listLotIndex.push({ no: -2 });
              }
            }
          }

          // Add new header base on tmpLayoutCSV
          // EX3-650: add property for CSV layout when download
          for (let i = 0; i < tmpLayoutCSV.length; i++) {
            resData += layoutCSV?.doubleQuotation.value;
            // EX3-725: fix header for CSV output
            resData += tmpLayoutCSV[i].csvCell;

            resData += layoutCSV?.doubleQuotation.value;
            if (i < tmpLayoutCSV.length - 1) {
              resData += layoutCSV?.delimiter.value;
            }
          }
          resData += "\n";

          // Add new header base on tmpLayoutotCSV
          for (let i = 0; i < tmpLayoutLotCSV.length; i++) {
            resLotData += layoutCSV?.doubleQuotation.value;
            // EX3-725: fix header for CSV output
            resLotData += tmpLayoutLotCSV[i].csvCell;

            resLotData += layoutCSV?.doubleQuotation.value;
            if (i < tmpLayoutLotCSV.length - 1) {
              resLotData += layoutCSV?.delimiter.value;
            }
          }
          resLotData += "\n";

          // Add value base on listIndex
          let tempDatas = groupByElement(tmpData).reverse();
          let listError = [];
          for (let i = 1; i < tempDatas.length; i++) {
            let temps = tempDatas[i];
            // min SEQ
            let temp = temps[temps.length - 1];
            for (let j = 0; j < listIndex.length; j++) {
              resData += layoutCSV?.doubleQuotation.value;
              if (indexComputeTotal > 0 && listIndex[j].no === indexComputeTotal) {
                // computing total quantity
                let total = 0;
                for (let l = 0; l < temps.length; l++) {
                  let value = temps[l][listIndex[j].no];
                  if (isNaN(value) || value === '') {
                    listError.push(temps[l][0]);
                  }
                  else {
                    total = total + parseInt(value);
                  }
                }
                resData += total;
              } else {
                if (listIndex[j].no === -1) {
                  resData += "";
                }
                else if (listIndex[j].no === -2) {
                  resData += tmpLayoutCSV[j].defaultCell;
                }
                else {
                  if (tmpData[i][listIndex[j].no] === undefined) {
                    resData += "";
                  }
                  else {
                    resData += temp[listIndex[j].no];
                  }
                }
              }
              resData += layoutCSV?.doubleQuotation.value;
              if (j < listIndex.length - 1) {
                resData += layoutCSV?.delimiter.value;
              }
            };

            resData += "\n";
            const listGetMinSEQ = [0, 1, 2, 3];

            // add LotCSV
            for (let l = 0; l < temps.length; l++) {
              for (let j = 0; j < listLotIndex.length; j++) {
                resLotData += layoutCSV?.doubleQuotation.value;
                // fix(EX3-1103): fail output CSV.
                if (listGetMinSEQ.includes(j)) {
                  if (temps[temps.length - 1][listLotIndex[j].no] === undefined) {
                    resLotData += "";
                  }
                  else {
                    resLotData += temps[temps.length - 1][listLotIndex[j].no];
                  }
                } else {
                  if (listLotIndex[j].no === -1) {
                    resLotData += "";
                  }
                  else if (listLotIndex[j].no === -2) {
                    resLotData += tmpLayoutLotCSV[j].defaultCell;
                  }
                  else {
                    if (temps[l][listLotIndex[j].no] === undefined) {
                      resLotData += "";
                    }
                    else {
                      resLotData += temps[l][listLotIndex[j].no];
                    }
                  }
                }
                resLotData += layoutCSV?.doubleQuotation.value;
                if (j < listLotIndex.length - 1) {
                  resLotData += layoutCSV?.delimiter.value;
                }
              };
              resLotData += "\n";
            }
          }

          if (listError.length > 0) {
            let msg = "出荷数 項目に数値以外のデータが紐付けされています。CSVレイアウト画面を確認してください。";
            // listError.forEach((element) => {
            //   msg = msg + "\n照合SEQ = " + element;
            // })
            dispatch(
              slice.actions.hasError(
                msg
              )
            );
            return;
          }
        } else {
          // case 1: download by custom csv type 1
          // Get list index has in tmpLayoutCSV base on tmpData
          //EX3-679-677: change layout CSV follow design, fix download CSV wrong design
          for (let i = 0; i < tmpLayoutCSV.length; i++) {
            //EX3-833: fix output CSV has duplicate item
            if (tmpLayoutCSV[i].layoutCellNo !== 0) {
              listIndex.push({ no: tmpLayoutCSV[i].layoutCellNo - 2 });
            }
            else {
              if (tmpLayoutCSV[i].defaultCell === "") {
                listIndex.push({ no: -1 });
              }
              else {
                listIndex.push({ no: -2 });
              }
            }
          }

          // Add new header base on tmpLayoutCSV
          // EX3-650: add property for CSV layout when download
          for (let i = 0; i < tmpLayoutCSV.length; i++) {
            resData += layoutCSV?.doubleQuotation.value;
            // EX3-725: fix header for CSV output
            resData += tmpLayoutCSV[i].csvCell;

            resData += layoutCSV?.doubleQuotation.value;
            if (i < tmpLayoutCSV.length - 1) {
              resData += layoutCSV?.delimiter.value;
            }
          }
          resData += "\n";

          // Add value base on listIndex
          for (let i = 1; i < tmpData.length; i++) {
            for (let j = 0; j < listIndex.length; j++) {
              resData += layoutCSV?.doubleQuotation.value;
              if (listIndex[j].no === -1) {
                resData += "";
              }
              else if (listIndex[j].no === -2) {
                resData += tmpLayoutCSV[j].defaultCell;
              }
              else {
                if (tmpData[i][listIndex[j].no] === undefined) {
                  resData += "";
                }
                else {
                  resData += tmpData[i][listIndex[j].no];
                }
              }
              resData += layoutCSV?.doubleQuotation.value;
              if (j < listIndex.length - 1) {
                resData += layoutCSV?.delimiter.value;
              }
            };
            resData += "\n";
          }
        }
      }
      // If choose type default layout
      else {
        resData = result.data;
      }

      const Encoding = require('encoding-japanese');
      if (isDownNewLayout && tmpLayoutLotCSV.length > 0) {
        const utf8Array = new TextEncoder().encode(resData);
        // EX3-772: fix bug file CSV is garbled.
        const sjisArray = Encoding.convert(utf8Array, layoutCSV?.enclosingLetter.value || 'SJIS', 'UTF8');
        const blob = new Blob([new Uint8Array(sjisArray)]);

        const utf8ArrayLot = new TextEncoder().encode(resLotData);
        const sjisArrayLot = Encoding.convert(utf8ArrayLot, layoutCSV?.enclosingLetter.value || 'SJIS', 'UTF8');
        const blobLot = new Blob([new Uint8Array(sjisArrayLot)]);

        const zip = new JSZip();
        const currDate = new Date();
        const dateWithOffset = new Date(currDate.getTime() - currDate.getTimezoneOffset() * 60000);
        zip.file('出荷ファイル.csv', blob, { binary: true, date: dateWithOffset });
        zip.file('LOT情報.csv', blobLot, { binary: true , date: dateWithOffset });

        // Generate the zip file asynchronously
        zip.generateAsync({ type: 'blob' }).then((content: Blob | MediaSource) => {
          // Create a download link
          const url = URL.createObjectURL(content);

          // Trigger the download
          const a = document.createElement('a');
          a.href = url;
          a.textContent = 'download';
          document.body.append(a);
          const nameFile =
            templateName +
            "_" +
            getCurrentDate() +
            " " +
            getHH() +
            getMM() +
            getSS() +
            ".zip";
          a.setAttribute("download", nameFile);
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();

          // Clean up
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        });
      }
      else {
        const utf8Array = new TextEncoder().encode(resData);
        // EX3-772: fix bug file CSV is garbled.
        const sjisArray = Encoding.convert(utf8Array, layoutCSV?.enclosingLetter.value || 'SJIS', 'UTF8');
        const blob = new Blob([new Uint8Array(sjisArray)]);

        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.textContent = 'download';
        document.body.append(a);
        const nameFile =
          templateName +
          "_" +
          getCurrentDate() +
          " " +
          getHH() +
          getMM() +
          getSS() +
          ".csv";
        a.setAttribute("download", nameFile);
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      }
      dispatch(slice.actions.downloadCSVSuccess(true));
    } catch (error) {
      console.log(error);
      var err: Error = error;
      if (err.message === "Network Error") {
        dispatch(
          slice.actions.hasError(
            "ネットワーク通信が切れたため、CSV出力ができません。"
          )
        );
      } else {
        dispatch(
          slice.actions.hasError("エラーが発生したため、CSV出力ができません。")
        );
      }
    }
  };
}

function getCurrentDate(separator = '') {
  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
}

function getHH(separator = '') {
  let newDate = new Date()
  let hh = newDate.getHours();
  return `${separator}${hh < 10 ? `0${hh}` : `${hh}`}`
}

function getMM(separator = '') {
  let newDate = new Date()
  let mm = newDate.getMinutes();
  return `${separator}${mm < 10 ? `0${mm}` : `${mm}`}`
}

function getSS(separator = '') {
  let newDate = new Date()
  let ss = newDate.getSeconds();
  return `${separator}${ss < 10 ? `0${ss}` : `${ss}`}`
}