/* eslint-disable jsx-a11y/alt-text */
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import { AddUser, InviteItem, UserItem } from "./UserItem";
import Stack from "@mui/material/Stack";
import { useCompanyUsers } from "src/hooks/api/useCompanyUsers";
// import { COLORS } from 'src/utils/colors';
import { USER_ROLE_NAME } from "src/config";
import React from "react";
import { dispatch, useSelector } from "src/redux/store";
import { ExModal } from "../components/modal";
import { Company } from "src/@types/userProfile";
import { ExPagination } from "../components/pagination";
import { ConfirmDialog, SuccessDialog } from "src/components/table";
import { TypeConfirm } from "src/components/table/ConfirmDialog";
import { removeMessageInit } from "src/redux/slices/removeMessage";
import { removeMessageState } from "src/@types/removeMessageState";

type Props = {
  connection: Company;
  open: boolean;
  onClose: () => void;
};

const UserGrid = styled(Grid)({
  margin: "12px 0px 12px -16px",
});

// CD = Connection Destination
// const CD = styled(Box)({
//   display: 'flex',
//   alignItems: 'center',
//   marginTop: '14px'
// });
// const CDLabel = styled(Typography)({
//   color: COLORS.grey,
//   fontSize: '14px',
//   fontWeight: 'bold'
// });
// const CDName = styled('div')({
//   backgroundColor: "white",
//   width: "300px",
//   height: "30px",
//   padding: "5px 10px",
//   fontSize: "14px",
//   color: COLORS.grey,
//   marginLeft: "12px",
//   overflow: "hidden",
//   whiteSpace: "nowrap",
//   textOverflow: "ellipsis",
// });

const PaginationStack = styled(Stack)({
  paddingBottom: "20px",
});

export const UserManagementPopup = (props: Props) => {
  const [page, setPage] = React.useState(1);
  const { data, mutate, isLoading } = useCompanyUsers(
    props.connection.company_id.toString(),
    page
  );
  const currentUser = useSelector((state) => state.auth.userProfile);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  const { error }: removeMessageState = useSelector(
    (state) => state.removeMessage
  );

  React.useEffect(() => {
    setPage(1);
  }, [props.connection.company_id]);

  React.useEffect(() => {
    if (props.open) {
      mutate();
    }
  }, [props.open]);

  const connectionName = props.connection.company_name;

  return (
    <ExModal
      open={props.open}
      onClose={props.onClose}
      height={744}
      width={816}
      title={"ユーザー一覧"}
      hasScroll
      isLoading={isLoading}
      bottom={
        <PaginationStack
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
        >
          <ExPagination
            page={page}
            onChange={handleChangePage}
            count={data?.last_page}
            variant="outlined"
            shape="rounded"
          />
        </PaginationStack>
      }
    >
      <UserGrid container spacing={2} className="grid">
        {data?.data?.map((user) => (
          <Grid item xs={4} key={user.id}>
            <UserItem
              reload={mutate}
              connectionId={props.connection.company_id}
              connectionName={connectionName}
              isCanRemove={user.user_company[0].can_remove}
              isCanEdit={user.user_company[0].can_edit}
              id={user.id}
              email={user.mail}
              role={user.user_company[0]?.role_id}
              roleName={USER_ROLE_NAME[user.user_company[0]?.role_id]}
              isExistSystem={user.autho_id != null}
              isCurrentUser={currentUser?.id === user.id}
            />
          </Grid>
        ))}
        {data?.invites.map((invite) => (
          <Grid item xs={4} key={invite.id}>
            <InviteItem
              reload={mutate}
              connectionId={props.connection.company_id}
              connectionName={connectionName}
              isCanRemove={true}
              isCanEdit={true}
              id={invite.id}
              email={invite.mail}
              status={invite.status}
              // role={user.user_company[0]?.role_id}
              // roleName={USER_ROLE_NAME[user.user_company[0]?.role_id]}
              isExistSystem={invite.autho_id != null}
              isCurrentUser={currentUser?.id === invite.id}
            />
          </Grid>
        ))}
        <Grid item xs={4}>
          <AddUser
            connectionName={connectionName}
            connectionId={props.connection.company_id}
            reload={mutate}
          />
        </Grid>
      </UserGrid>

      {error !== null && error.data !== null && (
        <SuccessDialog
          isError={true}
          title={""}
          message={`既に${error.data.mail}さんにより、参加/拒否が選択されています。`}
          onClickOk={() => {
            dispatch(removeMessageInit());
          }}
          onCancel={() => {
            dispatch(removeMessageInit());
          }}
        />
      )}
    </ExModal>
  );
};
