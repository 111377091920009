import * as React from "react";
import ErrorPNGIcon from 'src/assets/error_ic_png.png';

import {
  TableRow,
  TableCell,
  Dialog,
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  styled,
  Grid,
} from '@mui/material';

import CssLog from 'src/utils/CssLog';
import { closeSVGIcon } from "src/assets";

type Props = {
  isError: boolean;
  title?: String;
  message: String;
  onClickOk: VoidFunction;
  onCancel: VoidFunction;
};

const CloseButton = styled(Box)({
  top: "18px",
  right: "20px",
  position: "absolute",
  cursor: "pointer",
  zIndex: 1,
});

export default function SuccessDialog({
  isError,
  title = 'CSV出力',
  message,
  onClickOk,
  onCancel,
}: Props) {

  const [open, setOpen] = React.useState(true);

  const handleOK = () => {
    onClickOk();
    handleClose();
  };

  const handleCancel = () => {
    onCancel();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleCancel}
      onBackdropClick={handleCancel}
      PaperProps={{
        style: {
          borderRadius: 5,
          width: '659px',
          height: '255px',
          maxWidth: "unset"
        }
      }}
    >
      {isError && <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          pl: '10px'
        }} style={{
          ...CssLog().popup.topViewError,
        }}>
        <Avatar style={{ ...CssLog().popup.icon }} alt="Remy Sharp" src={ErrorPNGIcon} />
        <DialogTitle style={{ ...CssLog().popup.title, padding: 'unset', paddingLeft: '14px' }}>{title}</DialogTitle>

        <CloseButton onClick={handleCancel}>
          <img alt="close" width={14} height={14} src={closeSVGIcon} />
        </CloseButton>
      </Grid>}

      {!isError && <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          pl: '18px'
        }} style={{
          ...CssLog().popup.topView,
        }}>
        <DialogTitle style={{ ...CssLog().popup.title, padding: 'unset', paddingLeft: '8px' }}>{title}</DialogTitle>
        <CloseButton onClick={handleCancel}>
          <img alt="close" width={14} height={14} src={closeSVGIcon} />
        </CloseButton>
      </Grid>}

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
      >
        <Box sx={{
          width: "100%",
          margin: "auto 0",
          overflowY: "auto ",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <TableRow>

            <TableCell style={{ padding: 'unset' }}>
              <DialogContent style={{
                padding: '0px 99px 0px 99px',
                maxHeight: '133px'
              }}>
                <DialogContentText style={{ ...CssLog().popup.content }} sx={{ overflowWrap: 'break-word', justifyContent: 'center', whiteSpace: "pre-line" }}>
                  {message}
                </DialogContentText>
              </DialogContent>
            </TableCell>
          </TableRow>
        </Box>

        <DialogActions style={{ justifyContent: 'center', }}>
          <Button onClick={handleOK} sx={{ ...CssLog().popup.buttonCommon }} style={{ ...CssLog().popup.successButton, borderRadius: '4px', }}>OK</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
