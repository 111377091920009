import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import apiClient from '../../utils/axios';

// EX3-602: add api create and check license
const initialState: {
  isLoading: Boolean,
  error: String,
  data: Array<any>,
} = { isLoading: false, error: '', data: [] };

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.data = action.payload.response.data;
    },

    // GET LICENSE
    getMessagesSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});



// Reducer
export default slice.reducer;
export function getMessages() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/messages`;
      const response = await apiClient.get<any>(url);
      dispatch(slice.actions.getMessagesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}