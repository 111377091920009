import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";


export const Callback = () => {
    const {isAuthenticated, isLoading, loginWithRedirect, logout, user} = useAuth0();

    React.useEffect(() => {
        if (isAuthenticated && !user?.email_verified) {
            logout({ returnTo: window.location.origin + PATH_DASHBOARD.invoice.list })
        }
        if (!isLoading && !isAuthenticated) loginWithRedirect().then()
        // eslint-disable-next-line
    }, [isLoading, isAuthenticated])

    if(isAuthenticated) {
        return <Navigate to={PATH_DASHBOARD.invoice.list}/>
    }
    return <></>;
};