import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { TemplateState } from "src/@types/templateState";
import { API_URL } from "src/config";
import { Template } from "src/@types/template";
import { selectedTemplate } from "./templateSelected";
import { allTemplate } from "./templateAll";
import apiClient from "../../utils/axios";
// import { selectedTemplate } from 'src/redux/slices/templateSelected';
const initialState: TemplateState = {
  isLoading: false,
  error: "",
  templates: [],
};

const slice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TEMPLATES
    getTemplatesSuccess(state, action) {
      state.isLoading = false;
      state.templates = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export function getTemplates(company_id: String) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const url = API_URL.GET_TEMPLATE_LIST + "?company_id=" + company_id;
      const response = await apiClient.get<Array<Template>>(url);
      if (response.data.length > 0) {
        dispatch(allTemplate(response.data));
        response.data = response.data.filter((template) => {
          return template.is_active;
        });
        // EX3-1275
        let useLocal = false;
        const localTemplate = localStorage.getItem("selectedTemplate");
        response.data.forEach((template) => {
          if (localTemplate === JSON.stringify(template.id?.toString())) {
            dispatch(selectedTemplate(template.id?.toString()));
            useLocal = true;
          }
        });

        if (!useLocal) {
          const firstTemplateId = response.data[0].id?.toString();
          dispatch(selectedTemplate(firstTemplateId));
        }
      }
      dispatch(slice.actions.getTemplatesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
