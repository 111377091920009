
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogTitle, IconButton, styled } from '@mui/material'

export const BootstrapDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        backgroundColor: '#ECECEC',
        width: 820,
        // minHeight: 292,
        borderRadius: '5px !important',
    },
    '& .MuiDialogTitle-root': {
        padding: '5px 16px',
    },
    '& .MuiDialogContent-root': {
        padding: '15px 24px 0px 24px',
        overflowX: 'auto',
        borderBottom: 'none',
        // minWidth: 900,
    },
    '& .MuiDialogActions-root': {
        padding: 0,
    },
}))

export const BootstrapDialogTitle = (props: any) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{
            padding: '15px !important', fontSize: 25, fontWeight: 'bold', marginTop: 'unset', height: 59
        }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}
