/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Stack, Drawer } from '@mui/material';
import { Icon } from "@iconify/react";
import QrCodeIcon from '@mui/icons-material/QrCode'
import CsvIcon from 'src/assets/CsvIcon';
// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { ConnectionsState } from "src/@types/companies";
import { useSelector } from "src/redux/store";
import { USER_ROLE } from "src/config";
// utils
import cssStyles from '../../../utils/cssStyles';
import { PATH_DASHBOARD, PATH_UPLOAD, PATH_DOWNLOAD_LOGS } from '../../../routes/paths';
// config
import { NAVBAR } from '../../../config';
// components
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navExtConfig from './NavExtConfig';
// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  templateSelected: number | undefined | string;
  onCloseSidebar: VoidFunction;
  onSelectId: (id: String) => void;
};

export default function NavbarVertical({ isOpenSidebar, templateSelected, onCloseSidebar, onSelectId }: Props) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const { selectedConnection }: ConnectionsState = useSelector((state) => state.connections);

  const { isCollapse, collapseClick, collapseHover } = useCollapseDrawer();

  const [navConfig, setNavConfig] = useState<{ subheader: string, items: { title: string, icon: JSX.Element, path: string }[] }[]>([]);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(() => {
    let navConfig = [
      {
        subheader: '',
        items: (!!selectedConnection && selectedConnection.role_id !== USER_ROLE.basic) ? [
          {
            title: '照合実績',
            icon: <QrCodeIcon />,
            path: PATH_DASHBOARD.invoice.list,
          },
          {
            // EX3-724: change label
            title: 'CSVレイアウト',
            icon: <CsvIcon path={PATH_UPLOAD.root} />,
            path: PATH_UPLOAD.root,
          },
          {
            // EX3-724: change label
            title: 'ログ情報を出力',
            icon: <Icon icon={'uil:file-download-alt'} />,
            path: PATH_DOWNLOAD_LOGS.root,
          },
        ] : [
          {
            title: '照合実績',
            icon: <QrCodeIcon />,
            path: PATH_DASHBOARD.invoice.list,
          },
          {
            // EX3-724: change label
            title: 'CSVレイアウト',
            icon: <CsvIcon path={PATH_UPLOAD.root} />,
            path: PATH_UPLOAD.root,
          },
        ],
      },
    ];
    setNavConfig(navConfig);
  }, [selectedConnection]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <NavSectionVertical sx={{ height: 52, paddingTop: 9 }} navConfig={navConfig} isCollapse={isCollapse} />

      {/* EX3-777: Add add-on other service */}
      <Stack sx={{ position: 'absolute', bottom: 50 }}>
        <NavSectionVertical sx={{ height: 140 }} navConfig={navExtConfig} isCollapse={isCollapse} />
      </Stack>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <Drawer
        open
        variant="permanent"
        // onMouseEnter={onHoverEnter}
        // onMouseLeave={onHoverLeave}
        PaperProps={{
          sx: {
            width: NAVBAR.DASHBOARD_WIDTH,
            borderRightStyle: 'hidden',
            bgcolor: 'background.default',
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.standard,
              }),
            ...(isCollapse && {
              width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
            }),
            ...(collapseHover && {
              ...cssStyles(theme).bgBlur(),
              boxShadow: (theme) => theme.customShadows.z24,
            }),
          },
        }}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
