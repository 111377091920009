import { createSlice } from '@reduxjs/toolkit';
import { TemplateState } from 'src/@types/templateState';
import { Template } from 'src/@types/template';
import { dispatch } from '../store';


const initialState: TemplateState = { isLoading: false, error: '', templates: []};

const slice = createSlice({
  name: 'templateAll',
  initialState,
  reducers: {
    template(state, action) {
      state.templates = action.payload;
    },
  },
});

export default slice.reducer;
export function allTemplate(templates: Array<Template>) {
  return async () => {
    dispatch(slice.actions.template(templates));
  };
}