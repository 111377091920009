const LicenseIcon = ({ width = 36, height = 36, color = '#0029ff' }) => {
    return (
        <svg width={width} height={height} viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M507.342 115.223c-4.474-10.571-11.884-19.57-21.424-26.028-9.789-6.617-21.214-10.112-33.104-10.112H59.186c-7.994 0-15.744 1.572-23.039 4.657a59.197 59.197 0 0 0-26.032 21.428C3.495 114.972-.003 126.418 0 138.273v235.454c0 7.987 1.564 15.738 4.658 23.043a59.064 59.064 0 0 0 21.425 26.022c9.792 6.623 21.238 10.126 33.104 10.126h393.627a58.942 58.942 0 0 0 23.039-4.658c10.578-4.471 19.581-11.891 26.028-21.436 6.624-9.788 10.122-21.234 10.119-33.097V138.273c0-7.987-1.564-15.738-4.658-23.05zm-23.71 258.504c0 4.155-.814 8.188-2.418 11.985a30.775 30.775 0 0 1-11.18 13.57 30.762 30.762 0 0 1-17.219 5.261H59.186c-4.158 0-8.191-.811-11.995-2.418-5.508-2.325-10.198-6.193-13.567-11.181a30.695 30.695 0 0 1-5.256-17.216V138.273c0-4.162.814-8.202 2.418-11.999a30.842 30.842 0 0 1 11.18-13.57 30.703 30.703 0 0 1 17.219-5.246h393.627c4.162 0 8.199.811 11.991 2.404a30.907 30.907 0 0 1 13.57 11.181c3.434 5.087 5.253 11.051 5.256 17.23v235.454zM129.08 261.217c24.834 0 44.963-20.13 44.963-44.96 0-24.837-20.129-44.966-44.963-44.966-24.83 0-44.96 20.129-44.96 44.966.001 24.831 20.13 44.96 44.96 44.96zm38.074 6.89c-.976-.976-5.411-1.22-6.613-.488-9.167 5.655-19.925 8.956-31.46 8.956-11.539 0-22.293-3.301-31.458-8.956-1.209-.732-5.637-.488-6.616.488-7.546 7.549-17.496 24.399-19.502 36.427-4.938 29.609 26.692 40.302 57.576 40.302 30.886 0 62.512-10.693 57.579-40.302-2.006-12.028-11.953-28.878-19.506-36.427zm66.09-87.523h202.124v22.49H233.244zm0 63.503h202.124v22.497H233.244zm0 63.511h125.392v22.49H233.244z'
                fill={color}
            />
        </svg>
    )
}

export default LicenseIcon