import { useState } from "react";
// @mui
import { Box, Button, styled, Menu } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
// #EX3-595: add change user profile popup layout
import Profile from "./profile/Profile";
import MyAvatar from "../../../components/MyAvatar";
import { UserIcon, LogoutIcon } from "src/assets";
import { useAuth0 } from "@auth0/auth0-react";
// import { useSelector } from "src/redux/store";
import { ExMenuItem } from "src/components/ex/components/dropdown";
import axios from "src/utils/axios";
import React from "react";

const MenuStyled = styled(Menu)({
  ".MuiMenu-paper": {
    boxShadow: "0px 0px 6px #00000029",
    borderRadius: "0px 0px 3px 3px",
    marginTop: "15px",
    position: "relative",
    overflow: "visible",
    "&:before": {
      content: '""',
      width: "10px",
      height: "10px",
      transform: "translateY(-50%) rotate(45deg)",
      top: 0,
      right: 12,
      backgroundColor: "white",
      position: "absolute",
    },
  },
});

export default function AccountPopover() {
  const { user, logout } = useAuth0();

  const isMountedRef = useIsMountedRef();

  // const currentUser = useSelector((state) => state.auth.userProfile);

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const [isOpenProfile, setIsOpenProfile] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    try {
      localStorage.removeItem("selectedConnection");
      localStorage.removeItem("selectedTemplate");

      axios.post("users/log/create");
      logout({
        returnTo: window.location.origin + PATH_DASHBOARD.invoice.list,
      });
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenProfile = () => {
    handleClose();
    setIsOpenProfile(true);
  };

  return (
    // #EX3-595: add change user profile popup layout
    <>
      {isOpenProfile && (
        <Profile
          isOpenProfile={isOpenProfile}
          setIsOpenProfile={setIsOpenProfile}
        ></Profile>
      )}

      <Button
        onClick={handleOpen}
        sx={{
          pr: "18px",
          pl: 1,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
            },
          }),
        }}
      >
        <MyAvatar />
        <Box sx={{ paddingRight: 0.2 }}></Box>
        <Box
          // Fix #EX3-587: hide text when overflow and show tooltip all text when hover
          title={user?.nickname}
          sx={{
            fontSize: 14,
            fontWeight: "bold",
            color: "white",
            maxWidth: 180,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            display: "block",
            marginX: "10px",
            textTransform: "none !important",
            // '&:hover': {
            //   maxWidth: '100%',
            //   maxHeight: '100%',
            // },
          }}
        >
          {user?.nickname}
        </Box>
        <ExpandMoreIcon
          sx={{ width: 24, height: 24, color: "#fff", cursor: "pointer" }}
        />
      </Button>

      <MenuStyled
        anchorEl={open}
        open={Boolean(open)}
        sx={{
          p: 0,
          width: 210,
          mt: 0.75,
          ml: 0.5,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        MenuListProps={{
          disablePadding: true,
        }}
        onClose={(_, reason: string) => {
          if (reason !== "tabKeyDown") {
            handleClose();
          }
        }}
      >
        <ExMenuItem
          sx={{
            borderBottom: "1px solid #F6EFEF",
          }}
          onClick={handleOpenProfile}
          disabled={false}
        >
          <UserIcon width={14} height={14} color="#6E6E6E" />

          <Box sx={{ marginLeft: "14px", fontSize: "14px", color: "#6E6E6E" }}>
            ユーザー情報
          </Box>
        </ExMenuItem>

        <ExMenuItem
          sx={{
            borderBottom: "1px solid #F6EFEF",
          }}
          onClick={handleLogout}
          disabled={false}
        >
          <LogoutIcon width={14} height={14} color="#6E6E6E" />
          <Box sx={{ marginLeft: "14px", fontSize: "14px", color: "#6E6E6E" }}>
            ログアウト
          </Box>
        </ExMenuItem>
      </MenuStyled>
    </>
  );
}
