import { Box, CircularProgress, styled } from '@mui/material';
import { COLORS } from 'src/utils/colors';

const LoadingWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.white
})

export const Loading = () => (
  <LoadingWrapper>
    <CircularProgress />
  </LoadingWrapper>
)