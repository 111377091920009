import React, { useState } from 'react'
import { IconButton, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import axios from "src/utils/axios"
import EmailIcon from '@mui/icons-material/Email';
import MailDialog from 'src/components/table/MailDialog';
import { getMessages } from 'src/redux/slices/messages';
import { dispatch } from 'src/redux/store';
import { SuccessDialog } from 'src/components/table';

const numToString = (num: number) => {
    if (num <= 9) return num.toString()
    else return num + '+'
}

export default function Message(input: any) {
    const [isOpenDialog, setIsOpenDialog] = useState<HTMLElement | null>(null)
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = React.useState(false)
    const [selectId, setSelectId] = React.useState(-1);
    const [content, setContent] = React.useState('');
    const [isMessageError, setIsMessageError] = React.useState(false)
    const [error, setError] = React.useState<any>(null)

    const handleOpenMessageDialog = async (event: React.MouseEvent<HTMLElement>) => {
        setIsOpenDialog(event.currentTarget)
    }

    const handleCloseMessageDialog = () => {
        setIsOpenDialog(null)
    }
    const handleOpenConfirmMessage = () => {
        setIsOpenConfirmDialog(true);
    }

    const handleOK = async (status: number) => {
        try {
            await axios.post(`/messages`, {
                id: selectId,
                status: status,
            })

            if (status === 1) {
                window.location.reload();
            }
            dispatch(getMessages());
        } catch (error) {
            // console.log(error)
            setIsMessageError(true)
            setError(error.response.data)
        }
    }
    const ITEM_HEIGHT = 48;

    return (
        <>
            {input.messages.data.length > 0 ? (
                <IconButton
                    onClick={(event) => { handleOpenMessageDialog(event) }}
                    sx={{
                        color: '#666666',
                        margin: 0,
                        '&:before': {
                            content: `"${numToString(input.messages.data.length)}"`,
                            display: 'block',
                            position: 'absolute',
                            fontFamily: 'メイリオ',
                            fontSize: 10,
                            top: 0,
                            width: 16,
                            height: 16,
                            padding: 0.1,
                            bgcolor: 'red',
                            borderRadius: '50%',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            transform: 'translateX(60%)',
                        },
                    }}
                >
                    <EmailIcon sx={{ color: '#FFFFFF' }} />
                </IconButton >
            ) : (
                <Box />
            )}
            {
                isOpenConfirmDialog ? <MailDialog
                    title={'接続先への招待'}
                    message={content}
                    onClickOk={() => {
                        handleOK(1);
                        setIsOpenConfirmDialog(false);
                    }}
                    onClickNo={() => {
                        handleOK(2);
                        setIsOpenConfirmDialog(false);
                    }} onClickClose={() => {
                        setIsOpenConfirmDialog(false);
                    }} /> : undefined
            }
            <Menu
                id="basic-menu"
                open={Boolean(isOpenDialog)}
                anchorEl={isOpenDialog}
                // disableScrollLock={true}
                anchorOrigin={{
                    vertical: 20,
                    // horizontal: 'left',
                    horizontal: -235,
                }}
                // transformOrigin={{ vertical: 'top', horizontal: 'right' }}

                onClose={handleCloseMessageDialog}
                PaperProps={{
                    sx: {
                        // border: `3px solid ${COLORS.textGrey}`,
                        borderRadius: '4px',
                        overflow: 'visible',
                        // overflowY: 'scroll',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 6,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                    style: {
                        // maxHeight: ITEM_HEIGHT * 3,
                        // width: '260px',
                        // borderRadius: '5px',
                    },
                }}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    width: 320,
                    borderRadius: '0px',
                    // position: 'relative',
                }}
            >
                <Box sx={
                    {
                        maxHeight: ITEM_HEIGHT * 3,
                        width: '260px',
                        borderRadius: '5px',
                        overflowY: 'auto',
                    }
                }>
                    {input.messages.data.map((message: any) => {
                        return (
                            <Tooltip title={message.company_name} enterDelay={1000} leaveDelay={0} followCursor>
                                <MenuItem
                                    onClick={() => {
                                        setSelectId(message.id);
                                        setContent(message.message);
                                        handleOpenConfirmMessage();
                                    }}
                                    sx={{ color: '#707070' }}
                                >
                                    {/* EX3-675: Change label of default layout */}
                                    {/* <Icon icon="fa6-solid:download" /> */}
                                    <ListItemText>
                                        <Typography
                                            sx={{
                                                marginLeft: '10px',
                                                flexGrow: 1,
                                                '& span': {
                                                    color: '#586069',
                                                },
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >{`招待： ${message.company_name}`}
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </Tooltip>
                        )
                    })}
                </Box>

                {isMessageError && (
                    <SuccessDialog
                        isError={true}
                        title={""}
                        message={`既に${error.data?.last_changed_by}さんにより、招待がキャンセルされています。`}
                        onClickOk={() => {
                            setIsMessageError(false)
                            dispatch(getMessages());
                        }}
                        onCancel={() => {
                            setIsMessageError(false)
                            dispatch(getMessages());
                        }}
                    />
                )
                }

            </Menu>
        </>
    )
}