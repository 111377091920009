import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import apiClient from '../../utils/axios';
import { AddConnectionsState } from 'src/@types/addConnection';

const initialState: AddConnectionsState = { isLoading: false, error: '', addData: {role_id: 0, role_name: '', company_id: 0, company_name: '', can_edit_company: false}};

const slice = createSlice({
  name: 'addConnection',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //USER ROLE PROFILE SUCCESS
    addConnectionSuccess(state, action) {
      state.isLoading = false;
      state.addData = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export function addConnection(name: string) {
  return async () => {
    try {
      const url = 'companies';
      const result = await apiClient.post(url, {name: name },);
      dispatch(slice.actions.addConnectionSuccess(result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
