// hooks
// utils
import { useAuth0 } from '@auth0/auth0-react';
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth0()

  return (
    <Avatar
      variant='rounded'
      src={user?.picture}
      alt={'My Avatar'}
      color={createAvatar('Ronney Woodkin').color}
      style={{ width: 30, height: 30, cursor: 'pointer' }}
      {...other}
    />
  );
}
