/* eslint-disable react-hooks/exhaustive-deps */
import { ExDropdown, ExMenuItem } from "../components/dropdown";
import { Box, styled } from "@mui/material";
import { TemplateState } from "src/@types/templateState";
import { useDispatch, useSelector } from "../../../redux/store";
import { getTemplates } from "src/redux/slices/template";
import React from "react";
import { selectedTemplate as selectTemplate } from "src/redux/slices/templateSelected";
import { COLORS } from "src/utils/colors";

const Wrapper = styled(Box)({
  ".dropdown-toggle": {
    color: "red",
  },
});

export const TemplateMenu = () => {
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const { templates }: TemplateState = useSelector((state) => state.templates);
  const selected = useSelector((state) => state.templateSelected.templateId);
  const selectedConnetion = useSelector(
    (state) => state.connections.selectedConnection
  );
  const addConnetion = useSelector((state) => state.addConnection);

  const dispatch = useDispatch();

  const getTemplateList = () => {
    var id = selectedConnetion?.company_id.toString() ?? "";
    if (id === "") {
      return;
    }
    dispatch(getTemplates(selectedConnetion?.company_id.toString() ?? ""));
  };

  React.useEffect(() => {
    getTemplateList();
  }, [selectedConnetion, addConnetion]);

  const selectedTemplate = templates?.find(
    (temp) => temp.id.toString() === selected
  );

  return (
    <Wrapper>
      <ExDropdown
        onToggle={(open) => setIsOpenMenu(open)}
        isOpen={isOpenMenu}
        title="テンプレート"
        subtitle={(selectedTemplate?.name as string) ?? ""}
        sx={{
          ".MuiMenu-paper": {
            maxWidth: "200px",
            maxHeight: "340px",
          },
        }}
      >
        <Box
          sx={{
            maxWidth: "200px",
            maxHeight: "340px",
            overflowY: "auto",
          }}
        >
          {templates.map((template) => {
            const templateId = template.id;
            const templateName = template.name;
            return (
              <ExMenuItem
                sx={{
                  display: "block",
                  width: "183px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  borderBottom: "1px solid #F6EFEF",
                  color:
                    selected === template.id.toString()
                      ? "#085749"
                      : COLORS.grey,
                }}
                onClick={() => {
                  setIsOpenMenu(false);
                  dispatch(selectTemplate(templateId.toString()));
                }}
                value={templateId}
                title={templateName as string}
                key={template.id}
              >
                {templateName}
              </ExMenuItem>
            );
          })}
        </Box>
      </ExDropdown>
    </Wrapper>
  );
};
