import {
  Box,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ExAdminIcon, ExBasicUserIcon } from "src/assets";
import { ExContractorIcon } from "src/assets/ExContractorIcon";
import { USER_ROLE } from "src/config";
import { Icon } from "@iconify/react";
import useToggle from "src/hooks/useToggle";
import { AddUserPopup } from "../add-user-popup";
import { useDispatch } from "../../../redux/store";
import ConfirmDialog, { TypeConfirm } from "src/components/table/ConfirmDialog";
import { removeUser } from "src/redux/slices/removeUser";
import { getLicense } from "src/redux/slices/license";
import { COLORS } from "src/utils/colors";
import { EditUserPopup } from "../edit-user-popup";
import { useSelector } from "src/redux/store";
import { LicenseState } from "src/@types/licenseState";
import CustomDialog from 'src/components/table/CustomDialog';
import { ExInviteIcon } from "src/assets/ExInviteIcon";
import { removeMessage } from "src/redux/slices/removeMessage";
import { getMessages } from "src/redux/slices/messages";
import { resendMessage } from "src/redux/slices/resendMessage";

const Item = styled(Paper)({
  padding: "10px",
  height: "100px",
  fontSize: "14px",
  position: "relative",
  ".profile": {
    display: "flex",
    alignItems: "center",
  },
  ".email": {
    fontWeight: "bold",
    marginLeft: "10px",
    color: "#131523",
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  ".role": {
    display: "flex",
    alignItems: "center",
    marginLeft: "50px",
  },
  ".role-label": {
    fontWeight: "bold",
    color: "#484848",
    fontSize: "14px",
  },
  ".role-name": {
    marginLeft: "5px",
    color: "#484848",
    fontSize: "14px",
  },
  ".edit-icon": {
    marginLeft: "10px",
  },
  ".role-user-exist": {
    color: "#7D7D7D",
    fontSize: "10px",
  },
});

type Props = {
  id: number;
  email: string;
  connectionName: string;
  connectionId: number;
  role: number;
  roleName: string;
  isCanRemove: boolean;
  isCanEdit: boolean;
  isExistSystem: boolean;
  reload: () => Promise<unknown>;
  isCurrentUser?: boolean;
};

const ActionWrapper = styled(Box)({
  position: "absolute",
  // justifyContent: 'space-between',
  bottom: "10px",
  right: "10px",
  display: "flex",
  cursor: "pointer",
  // width: 40,
});

export const UserItem = (props: Props) => {
  const roleIcon: Record<number, React.ReactNode> = {
    [USER_ROLE.contractor]: <ExContractorIcon />,
    [USER_ROLE.admin]: <ExAdminIcon />,
    [USER_ROLE.basic]: <ExBasicUserIcon />,
  };

  const { toggle, onOpen, onClose } = useToggle(false);
  const { toggle: isOpenEdit, onClose: closeEdit, onOpen: openEdit } = useToggle(false);

  const dispatch = useDispatch();
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

  return (
    <Item
      sx={

        // props.isCurrentUser
        // ? { border: "2px solid " + COLORS.primary }
        // : undefined
        {
          border: "1px solid #CECECE",
          borderRadius: '3px',
          // width: '235px',
          height: '106px'
        }
      }
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      <Box className="profile">
        <Box height={40}>{roleIcon[props.role] ?? null}</Box>
        <Typography title={props.email} className="email" style={{
        }} sx={{
          overflowWrap: 'break-word',
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical"
        }}>{props.email}</Typography>
      </Box>
      <Box className="role">
        <Typography className="role-label">権限：</Typography>
        <Typography className="role-name">{props.roleName}</Typography>
      </Box>

      {
        !props.isExistSystem && (
          <Box className="role">
            <Typography className="role-user-exist">未登録ユーザー</Typography>
          </Box>
        )
      }

      <ActionWrapper>
        {props.isCanRemove && toggle && (

          <IconButton>
            <Icon
              onClick={() => setOpenDialogConfirm(true)}
              icon="fa:trash"
              // color={COLORS.red}
              style={{
                fontSize: 14,
                color: COLORS.red
              }}
            />
          </IconButton>
        )}
        {props.isCanRemove && toggle && (
          <IconButton>
            <Icon
              // className="edit-icon"
              onClick={openEdit}
              icon="fa-solid:pen"
              // color={COLORS.darkGreen}
              style={{
                fontSize: 14,
                color: COLORS.darkGreen
              }}
            />
          </IconButton>
        )}
      </ActionWrapper>

      {
        openDialogConfirm && (
          <ConfirmDialog
            type={TypeConfirm.removeUser}
            title={""}
            header={props.email}
            message={
              "このユーザーIDを" +
              "「" +
              props.connectionName +
              "」" +
              "から解除します。よろしいですか?"
            }
            onClickOk={() => {
              setOpenDialogConfirm(false);
              dispatch(removeUser(props.connectionId.toString(), props.id.toString()));
              dispatch(getLicense(props.connectionId.toString())).then(() => props.reload());
            }}
            onClickCancel={() => setOpenDialogConfirm(false)}
          />
        )
      }
      {
        <EditUserPopup
          connectionName={props.connectionName}
          email={props.email}
          id={props.id.toString()}
          onClose={closeEdit}
          open={isOpenEdit}
          connectionId={props.connectionId}
          roleId={props.role}
          reload={props.reload}
          isAuthorized={props.isExistSystem}
        />
      }
    </Item >
  );
};

const IconLarge = styled(Icon)({
  width: "40px",
  height: "40px",
});

type AddUserProps = {
  connectionName: string;
  connectionId: number;
  reload: () => Promise<unknown>
};

export const AddUser = (props: AddUserProps) => {
  const { toggle, onClose, onOpen } = useToggle();
  const { dataLicense }: LicenseState = useSelector((state) => state.license);
  return (
    <>
      <Item
        sx={{
          border: "2px solid #085749",
          borderRadius: "5px",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#2db19a",
          fontSize: "14px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={onOpen}
      >
        <IconLarge icon="material-symbols:add-circle" sx={{ color: '#085749' }} />
        <span style={{ color: "#085749" }}>ユーザーを追加</span>
      </Item>
      {dataLicense.is_allow_add_user && (
        <AddUserPopup
          reload={props.reload}
          connectionName={props.connectionName}
          open={toggle}
          onClose={onClose}
          connectionId={props.connectionId}
        />
      )}
      {/* // EX3-737: add limit user device */}
      {toggle && !dataLicense.is_allow_add_user && (
        <CustomDialog
          isError={true}
          title={"ユーザー一覧"}
          message={"登録可能なユーザー数の上限を超えています。\n追加できません。"}
          onClickOk={onClose}
        />
      )}
    </>
  );
};

type InviteProps = {
  id: number;
  email: string;
  connectionName: string;
  connectionId: number;
  status: number;
  // role: number;
  // roleName: string;
  isCanRemove: boolean;
  isCanEdit: boolean;
  isExistSystem: boolean;
  reload: () => Promise<unknown>;
  isCurrentUser?: boolean;
};


export const InviteItem = (props: InviteProps) => {

  const dispatch = useDispatch();
  const { toggle, onOpen, onClose } = useToggle(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const reSend = (id: number) => {
    dispatch(resendMessage(props.id.toString(), props.connectionId.toString()));
  }

  // const { isLoading, error }: messages = useSelector((state) => state.messages);

  return (
    <Item
      sx={

        // props.isCurrentUser
        // ? { border: "2px solid " + COLORS.primary }
        // : undefined
        {
          border: "1px solid #CECECE",
          borderRadius: '3px',
          // width: '235px',
          height: '106px'
        }
      }
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      <Box className="profile">
        <Box height={40}>{<ExInviteIcon />}</Box>
        <Typography title={props.email} className="email" style={{
        }} sx={{
          overflowWrap: 'break-word',
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical"
        }}>{props.email}</Typography>
      </Box>
      <Box className="role">
        {
          props.status === 0 ? <Typography style={{
            color: COLORS.darkGreen
          }}> 招待中</Typography> :
            <Typography
              style={{
                color: COLORS.badgeRed
              }}
            > 招待拒否</Typography>

        }
        {/* <Typography className="role-label">{props.status === 0 ? "招待中" : "招待拒否"}</Typography> */}
        {/* <Typography className="role-name">{props.roleName}</Typography> */}
      </Box>

      <ActionWrapper>
        {props.isCanRemove && toggle && (
          <IconButton>
            <Icon
              onClick={() => {
                setOpenDialogConfirm(true)
              }}
              icon="fa:trash"
              style={{
                fontSize: 14,
                color: COLORS.red
              }}
            />
          </IconButton>
        )}

        {props.isCanRemove && toggle && props.status === 2 && (
          <IconButton>
            <Icon
              // className="edit-icon"
              onClick={() => {
                reSend(props.id)
                dispatch(getMessages()).then(() => props.reload());
              }}
              icon="fa:rotate-right"
              color={COLORS.darkGreen}
              style={{
                fontSize: 14,
                color: COLORS.red
              }}
            />
          </IconButton>

        )}
      </ActionWrapper>

      {
        openDialogConfirm && (
          <ConfirmDialog
            type={TypeConfirm.removeUser}
            title={""}
            header={props.email}
            message={
              "このユーザーIDを" +
              "「" +
              props.connectionName +
              "」" +
              "から解除します。よろしいですか?"
            }
            onClickOk={() => {
              setOpenDialogConfirm(false);
              dispatch(removeMessage(props.id.toString(), props.connectionId.toString()));
              dispatch(getLicense(props.connectionId.toString()));
              dispatch(getMessages()).then(() => props.reload());
            }}
            onClickCancel={() => setOpenDialogConfirm(false)}
          />
        )
      }
    </Item >
  );
};
