import React from 'react'
import { Box, Chip, DialogContent, Stack, styled, TextField } from '@mui/material'
import axios from "src/utils/axios"
import { useAuth0 } from '@auth0/auth0-react'
import { dispatch, useSelector } from "src/redux/store";
import { fetchUserProfile } from "src/redux/slices/auth";
// #EX3-496: add change user password popup layout
import ChangePassword from '../change-password/ChangePassword'
import { invalidParamProfileError, profileOtherError } from 'src/utils/CommonErrors'
import ProfileButton from 'src/components/ProfileButton'
import { BootstrapDialog, BootstrapDialogTitle } from 'src/components/ProfileDialog'
import { COLORS } from 'src/utils/colors';

// #EX3-595: add change user profile popup layout
const font16 = {
    fontSize: 16,
    color: '#707070',
}

// const flexRowStyle = {
//     display: 'flex',
//     flexDirection: 'row',
// }

const textMidStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    ...font16,
}

const FormStyle = styled(Box)(() => ({
    paddingTop: 22,
    // paddingLeft: 10,
    maxWidth: 750,
}))

const ContentBox = styled(Box)(() => ({
    '& .dgroupItem': {
        borderBottom: 0,
    },
    '& .dgroupItem:last-child': {
        borderBottom: '1px solid #D4D4D4',
    },
}))

const FootBox = styled(Box)(() => ({
    // ...flexRowStyle,
    display: 'flex',
    flexDirection: 'row',
    margin: 10,
    gap: 10,
    justifyContent: 'center',
}))

const GroupItemBox = styled(Box)(() => ({
    // ...flexRowStyle,
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #D4D4D4',
    maxWidth: 750,
}))

const Item1 = styled(Box)(() => ({
    textAlign: 'left',
    color: COLORS.textGrey,
    fontSize: "16px",
    fontWeight: "bold",
    width: '75%',
}))

const Item2 = styled(Box)(({ theme }) => ({
    textAlign: 'right',
    width: '25%',
}))

const GroupItem = (props: any) => {
    return (
        <GroupItemBox className={'dgroupItem'}>
            <GroupItemLabel sx={textMidStyle}>
                <Stack direction='row' spacing={1}>
                    <Item1>{props.label}</Item1>
                    {props?.chip && (
                        <Item2>
                            <Chip
                                label='必須'
                                color='primary'
                                size='small'
                                style={{
                                    backgroundColor: '#F35D5D',
                                    borderRadius: 3,
                                    height: '20px',
                                    color: '#FFFFFF',
                                }}
                            />
                        </Item2>
                    )}
                </Stack>
            </GroupItemLabel>
            <GroupItemField padding={10}>{props.children}</GroupItemField>
        </GroupItemBox>
    )
}

const GroupItemLabel = styled(Box)(() => ({
    backgroundColor: '#ECECEC',
    borderRight: '1px solid #D4D4D4',
    width: 240,
    minWidth: 240,
    maxWidth: 240,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold',
    ...font16,
}))

const GroupItemField = styled(Box)((props: { padding: any }) => ({
    // ...flexRowStyle,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    padding: props.padding,
}))

const DialogContentBox = styled(DialogContent)(() => ({
    flexDirection: 'column',
    gap: 15,
    backgroundColor: "#FFFFFF",
    padding: "0 0 0 35px !important",
}))

const CustomTextField = styled(TextField)(() => ({
    // border: '1px solid #CECECE',
    // borderRadius: '2px',
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: '#34314C',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '2px',
        '&.Mui-focused fieldset': {
            border: '2px solid #085749',
        },
    },
}))

const ErrorBox = styled(Box)((props: { value: any }) => ({
    fontSize: 16,
    maxHeight: 70,
    padding: '10px 10px 10px 10px',
    borderRadius: 5,
    margin: '0px 0px 20px 0px',
    color: '#af4442',
    backgroundColor: '#f2dede',
    value: props.value
}))

const ErrorAlert = styled(Box)({
    fontSize: '16px',
    maxHeight: '70px',
    padding: '10px 0px 10px 11px',
    borderRadius: '5px',
    margin: '7px 0px 20px',
    color: 'rgb(175, 68, 66)',
    backgroundColor: 'rgb(242, 222, 222)',
})

const ValueInfo = styled(Box)(() => ({
    height: 30,
    display: 'block',
    fontFamily: "Meiryo UI, sans-serif",
    fontSize: '18px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}))

const Profile = (props: any) => {
    const dennoUser = useSelector((state) => state.auth.userProfile);
    const { user } = useAuth0()
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = React.useState(false)
    const [usrDefault, setUsrDefault] = React.useState({
        sei: dennoUser?.sei,
        mei: dennoUser?.mei,
        seiKatakana: dennoUser?.sei_katakana,
        meiKatakana: dennoUser?.mei_katakana,
        company: dennoUser?.company_name,
        mail: user?.email,
        error: '',
    })
    const [usrObjState, setUsrObjState] = React.useState(usrDefault)
    const [modeProfile, setModeProfile] = React.useState(true)
    const [disabledBtn, setDisabledBtn] = React.useState(true)

    const handleClickProfile = async () => {
        try {
            setIsLoading(true)
            await axios.put(`users/${dennoUser?.id}`, {
                autho_id: dennoUser?.autho_id,
                sei: usrObjState.sei,
                mei: usrObjState.mei,
                sei_katakana: usrObjState.seiKatakana,
                mei_katakana: usrObjState.meiKatakana,
                company_name: usrObjState.company,
                mail: user?.email,
            })
            dispatch(fetchUserProfile());
            setIsLoading(false)
            setModeProfile(true)
            setUsrObjState({ ...usrObjState, error: '' })
            setUsrDefault({ ...usrObjState, error: '' })
        } catch (err) {
            console.error(err)
            if (err.errorCode === 'A2201') {
                setUsrObjState({
                    ...usrObjState,
                    error: invalidParamProfileError,
                })
            } else {
                // if  (err.errorCode === 'A2000'){
                setUsrObjState({ ...usrObjState, error: profileOtherError })
            }
        } finally {
            setIsLoading(false)
        }
    }
    const handleChange = (prop: any) => (event: any) => {
        setUsrObjState({ ...usrObjState, [prop]: event.target.value })
    }

    const handleCloseProfile = () => {
        props?.setIsOpenProfile(false)
    }

    const handleBack = () => {
        setModeProfile(true)
        setUsrObjState(usrDefault)
    }

    const checkSimilar = (obj1: any, obj2: any) => {
        if (
            obj1.sei === obj2.sei &&
            obj1.mei === obj2.mei &&
            obj1.seiKatakana === obj2.seiKatakana &&
            obj1.meiKatakana === obj2.meiKatakana &&
            obj1.company === obj2.company
        )
            return true
        else return false
    }

    const checkRequired = (obj: any) => {
        if (
            obj.sei === '' ||
            obj.mei === '' ||
            obj.seiKatakana === '' ||
            obj.meiKatakana === '' ||
            obj.company === ''
        )
            return true
        else return false
    }

    // EX3-668: change regex to input 「ー」
    const katakanaReg = /^[ァ-ヾｧ-ﾝﾞﾟ]+$/;

    const checkKatakanaRequired = (obj: any) => {
        const reg = new RegExp(katakanaReg);
        if (
            !reg.test(obj.seiKatakana) ||
            !reg.test(obj.meiKatakana)
        )
            return true
        else return false
    }

    const isNotKatakana = Boolean(checkKatakanaRequired(usrObjState));

    React.useEffect(() => {
        if (checkSimilar(usrObjState, usrDefault) || checkRequired(usrObjState) || checkKatakanaRequired(usrObjState))
            setDisabledBtn(true)
        else setDisabledBtn(false)
        // eslint-disable-next-line
    }, [usrObjState])

    return (
        <BootstrapDialog onClose={handleCloseProfile} open={props?.isOpenProfile} maxWidth={'lg'}>
            <BootstrapDialogTitle onClose={handleCloseProfile}><span style={{
                fontFamily: "Meiryo UI, sans-serif",
                fontSize: '20px',
                fontWeight: "bold",
                color: COLORS.textGrey
            }}>ユーザー情報</span></BootstrapDialogTitle>

            <DialogContentBox>
                <FormStyle>
                    {usrObjState.error !== '' && (
                        <ErrorBox value={usrObjState.error}>{usrObjState.error}</ErrorBox>
                    )}
                    {isNotKatakana && (
                        <ErrorAlert>
                            {"セイ/メイはカタカナで入力してください。"}
                        </ErrorAlert>
                    )}
                    <ContentBox>
                        {/* <GroupItem label={'ユーザーID'} className={'dgroupItem'}>
                            <ValueInfo sx={{ width: 428 }}>{dennoUser?.id}</ValueInfo>
                        </GroupItem> */}
                        <GroupItem label={'メールアドレス'} className={'dgroupItem'}>
                            <ValueInfo
                                title={user?.email}
                                sx={{ textAlign: 'left !important', width: 428 }}>{user?.email}</ValueInfo>
                        </GroupItem>
                        <GroupItem
                            label={'名前(姓/名)'}
                            className={'dgroupItem'}
                            chip={!modeProfile}
                        >
                            {modeProfile ? (
                                <ValueInfo
                                    title={usrObjState?.sei + " " + usrObjState?.mei}
                                    sx={{ textAlign: 'left !important', width: 428 }}>
                                    {usrObjState?.sei}　{usrObjState?.mei}
                                </ValueInfo>
                            ) : (
                                <>
                                    <CustomTextField
                                        disabled={modeProfile}
                                        id='forename-1'
                                        size='small'
                                        value={usrObjState?.sei}
                                        sx={{ width: 206 }}
                                        onChange={handleChange('sei')}
                                        required
                                        inputProps={{
                                            style: {
                                                height: '13px',
                                            },
                                            maxLength: 40,
                                        }}
                                    />
                                    <CustomTextField
                                        disabled={modeProfile}
                                        value={usrObjState?.mei}
                                        onChange={handleChange('mei')}
                                        id='name-1'
                                        variant='outlined'
                                        sx={{ width: 206, ml: 2 }}
                                        size='small'
                                        required
                                        inputProps={{
                                            style: {
                                                height: '13px',
                                            },
                                            maxLength: 40,
                                        }}
                                    />
                                </>
                            )}
                        </GroupItem>
                        <GroupItem
                            label={'フリガナ(セイ/メイ)'}
                            className={'dgroupItem'}
                            chip={!modeProfile}
                        >
                            {modeProfile ? (
                                <ValueInfo
                                    title={usrObjState?.seiKatakana + " " + usrObjState?.meiKatakana}
                                    sx={{
                                        textAlign: 'left !important',
                                        width: 428,
                                    }}>
                                    {usrObjState?.seiKatakana}　{usrObjState?.meiKatakana}
                                </ValueInfo>
                            ) : (
                                <>
                                    <CustomTextField
                                        disabled={modeProfile}
                                        id='forename-1'
                                        size='small'
                                        value={usrObjState?.seiKatakana}
                                        sx={{ width: 206 }}
                                        onChange={handleChange('seiKatakana')}
                                        required
                                        inputProps={{
                                            style: {
                                                height: '13px',
                                            },
                                            maxLength: 40,
                                        }}
                                    />
                                    <CustomTextField
                                        disabled={modeProfile}
                                        value={usrObjState?.meiKatakana}
                                        onChange={handleChange('meiKatakana')}
                                        id='name-1'
                                        variant='outlined'
                                        sx={{ width: 206, ml: 2 }}
                                        size='small'
                                        required
                                        inputProps={{
                                            style: {
                                                height: '13px',
                                            },
                                            maxLength: 40,
                                        }}
                                    />
                                </>
                            )}
                        </GroupItem>
                        <GroupItem label={'会社名'} className={'dgroupItem'} chip={!modeProfile}>
                            {modeProfile ? (
                                <ValueInfo
                                    title={usrObjState?.company}
                                    sx={{ textAlign: 'left !important', width: 428 }}>{usrObjState?.company}</ValueInfo>
                            ) : (
                                <CustomTextField
                                    value={usrObjState?.company}
                                    onChange={handleChange('company')}
                                    size='small'
                                    id='name-company'
                                    // variant="outlined"
                                    sx={{ width: 428 }}
                                    required
                                    inputProps={{
                                        style: {
                                            height: '13px',
                                        },
                                        maxLength: 40,
                                    }}
                                />
                            )}
                        </GroupItem>
                        <GroupItem label={'パスワード'} className={'dgroupItem'}>
                            <ChangePassword />
                        </GroupItem>
                    </ContentBox>
                    <FootBox>
                        {modeProfile && (
                            <ProfileButton
                                sx={{
                                    width: 157,
                                    height: 34,
                                    fontWeight: 'bold',
                                    borderRadius: '4px !important',
                                }}
                                customstyle={'mainly'}
                                onClick={() => {
                                    setModeProfile(false)
                                    setDisabledBtn(true)
                                }}
                                disabled={isLoading}
                            >
                                編集
                            </ProfileButton>
                        )}
                        {!modeProfile && (
                            <ProfileButton
                                sx={{
                                    width: 157,
                                    height: 34,
                                    fontWeight: 'bold',
                                    border: '1px solid #A4A4A4',
                                    borderRadius: '4px !important',
                                    color: '#A4A4A4 !important'
                                }}
                                onClick={handleBack}
                            >
                                戻る
                            </ProfileButton>
                        )}
                        {!modeProfile && (
                            <ProfileButton
                                sx={{
                                    width: 157,
                                    height: 34,
                                    fontWeight: 'bold',
                                    borderRadius: '4px !important',
                                    ":disabled": {
                                        backgroundColor: "#A4A4A4",
                                        color: "#FFFFFF",
                                    },
                                }}
                                customstyle={'mainly'}
                                onClick={handleClickProfile}
                                disabled={isLoading || disabledBtn}
                            >
                                保存
                            </ProfileButton>
                        )}
                    </FootBox>
                </FormStyle>
            </DialogContentBox>
        </BootstrapDialog>
    )
}

export default Profile
