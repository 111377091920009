import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'src/redux/store';
import { Loading } from 'src/components/ex/components/loading';
import { USER_ROLE } from "src/config";
import { ConnectionsState } from "src/@types/companies";

type Props = React.PropsWithChildren;

export const RoleGuard = (props: Props) => {
    const { isLoading, selectedConnection }: ConnectionsState = useSelector((state) => state.connections);

    const isRoleBasic = selectedConnection?.role_id === USER_ROLE.basic;

    if (!isLoading && !isRoleBasic) {
        return <>{props.children}</>
    }

    if (!isLoading && isRoleBasic) {
        return <Navigate to="/dashboard/logs/list" replace />
    }

    return <Loading/>
}