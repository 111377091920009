import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

export type TemplateSelectedState = {
  templateId: string | null;
  oldTemlateId: String | null;
};

const initialState: TemplateSelectedState = {
  templateId: "",
  oldTemlateId: "",
};

const slice = createSlice({
  name: "templateSelected",
  initialState,
  reducers: {
    template(state, action) {
      state.templateId = action.payload;
      // console.log("TemplateSelectedState: " + state.templateId);
      // EX3-1275
      localStorage.setItem("selectedTemplate", JSON.stringify(action.payload));
    },
    oldTemplate(state, action) {
      state.oldTemlateId = action.payload;
      // console.log("TemplateSelectedState: " + state.templateId);
    },
  },
});

export default slice.reducer;
export function selectedTemplate(id: String | null) {
  return async () => {
    dispatch(slice.actions.template(id));
  };
}

export function saveCheckTemplate(id: String | null) {
  return async () => {
    dispatch(slice.actions.oldTemplate(id));
  };
}
