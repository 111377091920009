// @mui
import { List, Box } from '@mui/material';
// hooks
//
import { NavSectionProps } from '../type';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((group) => (
      <List key={group.subheader} disablePadding>
          

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
        
      ))}
    </Box>
  );
}
