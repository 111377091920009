import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import apiClient from '../../utils/axios';
import { RemoveUserState } from 'src/@types/removeUserState';

const initialState: RemoveUserState = { isLoading: false, error: '', status: false};

const slice = createSlice({
  name: 'removeUser',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //REMOVE USER SUCCESS
    removeUserSuccess(state, action) {
      state.isLoading = false;
      state.status = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export function removeUser(connectionId: String, userId: String) {
  return async () => {
    try {
      const url = 'companies/' + connectionId + '/users/' + userId;
      await apiClient.delete(url);
      dispatch(slice.actions.removeUserSuccess(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
