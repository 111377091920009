import { memo } from 'react';
import { Box, BoxProps } from '@mui/material';
function SearchIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <image
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAABPBJREFUeJzVm0+IVlUYxt/3gIWtlMRwKHDSoEWLCmQ07A81oVHUKsKNCC2MAm2hLgpJQ8SYlEExsgQXgZgIwUAMKGgbBaEYItrohOBfRtF2Q21+T4vvTnzMfDNzzrnn++74wDAfM+99nvd5v3Pvufc957oVhqTnJb1nZn1mtsLd+yRN/b7t7nck3TazO9Xn0RDC5dJ5xMLrEkhaYmavSXrVzN5196czOG6Y2VkzO+fuZ93977p5dR2SHgF2ADdUEMBN4LOqsAsTwGbgt5LGOxTiCvCppEVN+/0fwMvAaDeNdyjEGLCpae9WDfd/e2l+WiG2N2n+TFPG2wEcLu1t3lkAuOHuT5YWzoWk8RDCM6X4wlz/BP5YSObNzNx9NTBRim/WAgCfu/tzpYRKwt2XA+eLcHX6o6T3zex0HWJJY+4+Iumuu98zs3uSHjezZdXPRndfX1PjSAhhWx2OGQDWAJOZF6lbkvYAL0RqrQK2Ab/XuDBuLV2A5Hm+zfiKTM3FwC7gZob2BNBfyvzmjAQuSXq2kH4/cCojh+Ha4mrd2yfd3gJnCvieAeBgRhEG6oruSBQ8WMjvbPkcT8znZLaYpKUpT3XAqYJe58rrdGIRNmQJAR8kiNwqdtGJy20kIbfdqfxTN0Jvxh7g7t+HEK6lCtXAdwmxa7MUgOsJ337WVFcHsaMA+EeJ/YMADLj7UzHB1bd/J89GLUSNAnd/1MySZoPg7m/FBks6m0JeCu5+XtJkZPhgCneQFDWkJf0ZQriUQl4K7j5pZhdjYmP9TCGYWdQB7t6Vm55YuHts8dMK4O59MYGS7qYQl0asfqyfKUSfAtUjbWOI1U8+BRIq1mgBYvVzRsDtmEC1mhmNIVZf0oMU3uDusfP6shTiLiBWP60AsSMgIYFuIVb/fgppMLPYEbAxhbgLiGqzmdmtFNLoU8Dd1wOrUsgL46XIuPEU0iBpNCH+7RTyUgDWunvs9HY1hTuEEC6rtT4fgw+BxSkChRDd+XX3pBFgZmntJ2BXskANSBpMyO2vVP6phsi5hGO29bIjJCl64cPdf8gVWZrSk1/APcH8znC1LSVFbKF1hUdqCUpaAlxJFD1eyO/0XPak5FHlEt3XnBXVnpxU1FpE7ZDDVxnmfyoiLmkRMJaRwAjwTk3twZQW+DT9vUUKYGYGbMpJor0Qkh5L0FsHnMjVbNPen+O34/4AYLu7Zy84qtXAvOjulzT7/oB1ZrbG3Zfn6nTQ/TqEsLMIGXC47rfSBFJXiufcJAVcdffV9UrZe0j6JoTwSUxszC6xiZLDtFeQdCyE8NF8cXPuEjMzCyE8IelCmbR6B3ffChydL27eApiZhRBel3Skflq9hbt/XHRzJbAVmGj2MpcO4FDJIvQDwz1K/ASwtxDXULEiVIUYAE6WSK5Dsj8Ca9q09heiPlC0CFVyG4DdwM/V+nyu6evAMPDKLDpDJSoA7Gvnrf3KTDvU2pwwYGaDai1RTX9n6IG1+vb3rdW9HTezq+4+7u6/zMcPDLt77W3zkr4MIXxRl6cRAEdLjARJW5r2kg3g27rugWuSVjbtJRslRkLxmaHXqPvABjx0d7gzAByqU4Sm8y+C3CkSaGTPU1cg6UBGAeZ9WHqoAOxLLMAbTedcHLHPDkDZV20WEiRtqeb52cw3vdeh+5C0EhgCLlS+fwWOAS+2x/0HzxPD0kznBTgAAAAASUVORK5CYII="
          width={13}
          height={13}
          transform="scale(1.33333)"
        />
      </svg>
    </Box>
  );
}
export default memo(SearchIcon);

