import React from 'react'
import {
    Box,
    Chip,
    DialogContent,
    IconButton,
    InputAdornment,
    Stack,
    styled,
    TextField,
} from '@mui/material'
import ProfileButton from 'src/components/ProfileButton'
import { VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material'
import axios from "src/utils/axios"
import { useSelector } from "src/redux/store"
import { useAuth0 } from '@auth0/auth0-react'
import { PATH_DASHBOARD } from 'src/routes/paths';
import {
    pwNotmatched,
    pwNotMachedRegex,
    passwordStrengthError,
    passwordHistoryError,
    PasswordDictionaryError,
    passwordNoUserInfoError,
    otherChangePWError,
    passwordLengthAtLeast,
    passwordRegexError,
} from 'src/utils/CommonErrors'
import { BootstrapDialog, BootstrapDialogTitle } from 'src/components/ProfileDialog'
import { COLORS } from 'src/utils/colors'
import ConfirmDialog, { TypeConfirm } from 'src/components/table/ConfirmDialog'

// #EX3-496: add change user password popup layout
const font16 = {
    fontSize: 16,
    color: '#707070',
}

const font18 = {
    fontSize: 16,
    color: '#434343',
}

// const flexRowStyle = {
//     display: 'flex',
//     flexDirection: 'row',
// }

const textMidStyle = {
    display: 'flex',
    backgroundColor: "unset",
    borderRight: "unset",
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    ...font18,
}

const FormStyle = styled(Box)(() => ({
    paddingLeft: 22,
    maxWidth: 750,
}))

const ContentBox = styled(Box)(() => ({
    paddingTop: '10px',
    '& .dgroupItem': {
        borderBottom: 0,
    },
    '& .dgroupItem:last-child': {
        borderBottom: '1px solid #D4D4D4',
    },
}))

const FootBox = styled(Box)(() => ({
    // ...flexRowStyle,
    display: 'flex',
    flexDirection: 'row',
    margin: 15,
    gap: 10,
    justifyContent: 'center',
}))

// const GroupItemBox = styled(Box)(() => ({
//     // ...flexRowStyle,
//     display: 'flex',
//     flexDirection: 'row',
//     border: '1px solid #D4D4D4',
//     maxWidth: 750,
// }))

const Item1 = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    fontFamily: "Meiryo UI, sans-serif",
    fontSize: '18px',
    fontWeight: 'bold',
    // width: '75%',
}))

// const Item2 = styled(Box)(({ theme }) => ({
//     textAlign: 'right',
//     width: '25%',
// }))

const GroupItem = (props: any) => {
    return (
        <GroupItemLabel sx={{ ...textMidStyle }}>
            <Stack direction='row' spacing={2}>
                <Item1>{props.label}</Item1>
                {props?.chip && (
                    // <Item2>
                    <Chip
                        label='必須'
                        color='primary'
                        size='small'
                        style={{
                            backgroundColor: '#F35D5D',
                            fontFamily: "Meiryo UI, sans-serif",
                            fontSize: '13px',
                            fontWeight: 'bold',
                            borderRadius: 3,
                            height: '20px',
                            color: '#FFFFFF',
                            margin: 'auto 10px'
                        }}
                    />
                    // </Item2>
                )}
            </Stack>
        </GroupItemLabel>
    )
}

const GroupItemLabel = styled(Box)(() => ({
    backgroundColor: '#ECECEC',
    borderRight: '1px solid #D4D4D4',
    width: 300,
    minWidth: 300,
    maxWidth: 300,
    // paddingLeft: 10,
    // paddingRight: 10,
    fontWeight: 'bold',
    ...font16,
}))

// const GroupItemField = styled(Box)((props: { padding: any }) => ({
//     // ...flexRowStyle,
//     display: 'flex',
//     flexDirection: 'row',
//     backgroundColor: '#FFFFFF',
//     padding: props.padding,
// }))

const DialogContentBox = styled(DialogContent)(() => ({
    flexDirection: 'column',
    gap: 15,
    backgroundColor: '#FFFFFF',
}))

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: '#34314C',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '2px !important',
        '&.Mui-focused fieldset': {
            borderColor: '#085749',
        },
    },
})

const NoteBox = styled(Box)((props: { value: any }) => ({
    fontSize: 14,
    // maxHeight: 220,
    padding: '5px 20px 5px 20px',
    borderRadius: 3,
    margin: '10px 20px 0px 0px',
    color: props.value ? '#af4442' : '707070',
    backgroundColor: props.value ? '#f2dede' : '#ffffff',
    justifyContent: 'right !important',
}))

const ChangePassword = () => {
    const dennoUser = useSelector((state) => state.auth.userProfile);
    const { logout } = useAuth0()
    // eslint-disable-next-line
    const [isOpenProfile, setIsOpenProfile] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)
    const [showRePassword, setShowRePassword] = React.useState(false)
    const [disabledBtn, setDisabledBtn] = React.useState(true)
    const [openConfirm, setOpenConfirm] = React.useState(false)
    const usrDefault = {
        newPassword: '',
        reNewPassword: '',
        error: '',
    }
    const [usrObjState, setUsrObjState] = React.useState(usrDefault)
    const valiRegex1 = /[A-Z]/
    const valiRegex2 = /[a-z]/
    const valiRegex3 = /[@%!＠#$％^&*]/
    const valiRegex4 = /[0-9]/

    const handleChange = (prop: any) => (event: any) => {
        setUsrObjState({ ...usrObjState, [prop]: event.target.value })
    }

    const handleOpenProfile = () => {
        setIsOpenProfile(true)
    }

    const handleCloseProfile = () => {
        setUsrObjState(usrDefault)
        setIsOpenProfile(false)
    }

    const handleBack = () => {
        handleCloseProfile()
    }

    const valiRegex = (value: any) => {
        let count = 0
        if (value.match(valiRegex1)) {
            count = count + 1
        }
        if (value.match(valiRegex2)) {
            count = count + 1
        }
        if (value.match(valiRegex3)) {
            count = count + 1
        }
        if (value.match(valiRegex4)) {
            count = count + 1
        }
        if (count >= 3) {
            return true
        } else return false
    }

    const handleOpenConfirm = async () => {
        if (usrObjState.newPassword !== usrObjState.reNewPassword)
            setUsrObjState({ ...usrObjState, error: pwNotmatched })
        else if (usrObjState.newPassword?.length < 8)
            setUsrObjState({ ...usrObjState, error: passwordLengthAtLeast })
        else if (!valiRegex(usrObjState.newPassword))
            setUsrObjState({ ...usrObjState, error: passwordRegexError })
        else {
            setUsrObjState({ ...usrObjState, error: '' })
            setOpenConfirm(true)
        }
    }

    const handleUpdate = async () => {
        setOpenConfirm(false)
        try {
            await axios.post(`users/changePassword`, {
                autho_id: dennoUser?.autho_id,
                newPassword: usrObjState?.newPassword,
            })
            logout({ returnTo: window.location.origin + PATH_DASHBOARD.invoice.list });
        } catch (err) {
            console.error(err)
            try {
                var message = err.response.data.error.message.split(' ');
                if (message[0] === 'PasswordDictionaryError:')
                    setUsrObjState({
                        ...usrObjState,
                        error: PasswordDictionaryError,
                    })
                else if (message[0] === 'PasswordStrengthError:')
                    setUsrObjState({ ...usrObjState, error: passwordStrengthError })
                else if (message[0] === 'PasswordHistoryError:')
                    setUsrObjState({ ...usrObjState, error: passwordHistoryError })
                else if (message[0] === 'PasswordNoUserInfoError:')
                    setUsrObjState({
                        ...usrObjState,
                        error: passwordNoUserInfoError,
                    })
                else setUsrObjState({ ...usrObjState, error: otherChangePWError })
            }
            catch (error) {
                console.error(error)
                setUsrObjState({ ...usrObjState, error: otherChangePWError })
            }
        }
    }

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault()
    }

    React.useEffect(() => {
        if (
            usrObjState.newPassword !== usrDefault.newPassword &&
            usrObjState.reNewPassword !== usrDefault.reNewPassword
        )
            setDisabledBtn(false)
        else setDisabledBtn(true)
        // eslint-disable-next-line
    }, [usrObjState])

    return (
        <>
            <Box
                onClick={handleOpenProfile}
                sx={{
                    width: 428,
                    color: '#085749',
                    textAlign: 'left !important',
                    cursor: 'pointer',
                    fontFamily: "Meiryo UI, sans-serif",
                    fontSize: '18px',
                    fontWeight: 'bold',
                    '&:hover': {
                        color: '#2DB19A',
                    },
                    textDecoration: 'underline',
                    // fontWeight: 'bolder',
                    pb: '0.3em',
                }}
            >
                パスワード変更
            </Box>
            <BootstrapDialog onClose={handleCloseProfile} open={isOpenProfile} maxWidth={'lg'} sx={{
                '& .MuiPaper-root': {
                    backgroundColor: '#ECECEC',
                    width: 659,
                    minHeight: 524,
                    borderRadius: '5px !important',
                },
            }}>
                <BootstrapDialogTitle onClose={handleCloseProfile}>
                    <span style={{
                        color: COLORS.textGrey,
                        fontFamily: "Meiryo UI, sans-serif",
                        fontSize: '20px',
                        fontWeight: 'bold',
                    }}>パスワード変更</span>
                </BootstrapDialogTitle>
                <DialogContentBox>
                    <FormStyle>
                        {usrObjState.error !== '' && (
                            <NoteBox value={true}>
                                <pre style={{
                                    fontFamily: "Meiryo UI, sans-serif",
                                    fontSize: '16px',
                                }}>{usrObjState.error}</pre>
                            </NoteBox>
                        )}
                        <ContentBox>
                            <GroupItem
                                label={'新しいパスワード'}
                                className={'dgroupItem'}
                                chip={true}
                            >
                            </GroupItem>
                            <CustomTextField
                                id='password'
                                // maxLength={20}
                                value={usrObjState?.newPassword}
                                variant='outlined'
                                onChange={handleChange('newPassword')}
                                size='small'
                                sx={{
                                    width: 566,
                                }}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    inputProps: {
                                        style: {
                                            height: '13px',
                                            fontFamily: "Meiryo UI, sans-serif",
                                            fontSize: '18px',
                                            fontWeight: 'normal',
                                            lineHeight: 2
                                            // fontSize: showPassword ? "18px" : "28px",
                                        },
                                        maxLength: '20',
                                    },
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                {showPassword ? (
                                                    <VisibilityOffRounded fontSize='small' />
                                                ) : (
                                                    <VisibilityRounded fontSize='small' />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box sx={{ height: 5 }}></Box>
                            <GroupItem
                                label={'新しいパスワード（確認)'}
                                className={'dgroupItem'}
                                chip={true}
                            >

                            </GroupItem>
                            <CustomTextField
                                id='re-password'
                                value={usrObjState?.reNewPassword}
                                variant='outlined'
                                onChange={handleChange('reNewPassword')}
                                size='small'
                                sx={{
                                    width: 566,
                                }}
                                type={showRePassword ? 'text' : 'password'}
                                InputProps={{
                                    inputProps: {
                                        style: {
                                            height: '13px',
                                            fontFamily: "Meiryo UI, sans-serif",
                                            fontSize: '18px',
                                            fontWeight: 'normal',
                                            lineHeight: 2
                                            // fontSize: showRePassword ? "18px" : "28px",
                                        },
                                        maxLength: '20',
                                    },
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() =>
                                                    setShowRePassword(!showRePassword)
                                                }
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                {showRePassword ? (
                                                    <VisibilityOffRounded fontSize='small' />
                                                ) : (
                                                    <VisibilityRounded fontSize='small' />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </ContentBox>
                        <NoteBox value={false} sx={{ mt: '10px', backgroundColor: "#EDEDED", width: 566, height: 253 }}>
                            <pre style={{
                                fontFamily: "Meiryo UI, sans-serif",
                                color: '#5A607F',
                                fontSize: 16
                            }}>{pwNotMachedRegex}</pre>
                        </NoteBox>
                        <FootBox>
                            <ProfileButton
                                sx={{
                                    width: 157,
                                    height: 34,
                                    border: '1px solid #A4A4A4',
                                    borderRadius: '4px !important',
                                    fontWeight: 'bold',
                                    color: '#A4A4A4 !important'

                                }}
                                onClick={handleBack}
                            >
                                キャンセル
                            </ProfileButton>
                            <ProfileButton
                                disabled={disabledBtn}
                                sx={{
                                    width: 157,
                                    height: 34,
                                    border: '2px',
                                    fontWeight: 'bold',
                                    borderRadius: '4px !important',
                                    ":disabled": {
                                        backgroundColor: "#A4A4A4",
                                        color: "#FFFFFF",
                                    },

                                }}
                                customstyle={'mainly'}
                                onClick={handleOpenConfirm}
                            >
                                更新
                            </ProfileButton>
                        </FootBox>
                    </FormStyle>
                </DialogContentBox>

                {openConfirm && (
                    <ConfirmDialog
                        type={TypeConfirm.removeUser}
                        title={"パスワード変更の確認"}
                        message={
                            "パスワードを変更すると、現在のアカウントからログアウトされます。\nよろしいですか？"
                        }
                        onClickOk={() => {
                            handleUpdate()
                        }}
                        onClickCancel={() => setOpenConfirm(false)}
                    />
                )}



            </BootstrapDialog >
        </>
    )
}

export default ChangePassword
