import { combineReducers } from 'redux';

// slices
import logscanReducer from './slices/logscan';
import templateReducer from './slices/template';
import templateSelectedReducer from './slices/templateSelected';
import templateAlldReducer from './slices/templateAll';
import settingTemplateReducer from './slices/settingTemplate';
import removeUserReducer from './slices/removeUser';
import downloadCSVReducer from './slices/downloadCSV';
import connectionsReducer from './slices/connections';
import addConnectionReducer from './slices/addConnection';
import editConnectionReducer from './slices/editConnection';
import authReducer from './slices/auth';
import licenseReducer from './slices/license';
import settingReducer from './slices/settingStore';
import messagesReducer from './slices/messages';
import removeMessageReducer from './slices/removeMessage';
import resendMessageReducer from './slices/resendMessage';

// ----------------------------------------------------------------------


const rootReducer = combineReducers({
  datalogs: logscanReducer,
  templates: templateReducer,
  templateSelected: templateSelectedReducer,
  templateAll: templateAlldReducer,
  settingTemplate: settingTemplateReducer,
  removeUser: removeUserReducer,
  downloadCSV: downloadCSVReducer,
  connections: connectionsReducer,
  addConnection: addConnectionReducer,
  editConnection: editConnectionReducer,
  auth: authReducer,
  license: licenseReducer,
  setting: settingReducer,
  messages: messagesReducer,
  removeMessage: removeMessageReducer,
  resendMessage: resendMessageReducer,
});

export { rootReducer };
