import { PaginationData } from "src/config";
import useSWR from "swr/immutable";

type Role = {
  id: number;
  name: string;
  description: string;
};

type Props = {
  companyId: string;
  isEdit?: boolean;
  isAuthorized?: boolean;
};

export const useRole = ({ companyId, isEdit, isAuthorized }: Props) => {
  const { data, error, mutate } = useSWR<PaginationData<Role[]>>(
    `/companies/${companyId}/roles${isEdit ? "?action=editUser" : ""}${
      isEdit ? `&isAuthorized=${isAuthorized}` : ""
    }`
  );

  return { data, error, mutate };
};
