import { styled, Button as MButton } from "@mui/material";
import { COLORS } from "src/utils/colors";

export const Button = styled(MButton)({
  width: '157px',
  height: '34px',
  borderRadius: '4px',
  backgroundColor: COLORS.primary,
  color: "#FFFFFF",
  padding: '4px 12px',
  ":disabled": {
    backgroundColor: "#A4A4A4",
    color: "#FFFFFF",
  },
  ":hover": {
    backgroundColor: COLORS.primary,
    opacity: 0.8,
  },
})