import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExAuth } from 'src/@types/exAuth';
import { UserProfile } from 'src/@types/userProfile';
import apiClient from 'src/utils/axios';
import { AppThunk } from '../store';

const initialState: ExAuth = {
  isLoading: true
};

const ReduxAuthError = {
  checkExistEmailError: 'CHECK_EXIST_EMAIL_ERROR'
}

export type CreateUserProfileData = {
  sei: string,
  mei: string,
  sei_katakana: string,
  mei_katakana: string,
  company_name?: string
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },
    successCheckExistEmail (state, action: PayloadAction<boolean>) {
      state.isExist = action.payload;
    },
    manualUpdateExistEmail(state, action: PayloadAction<boolean>) {
      state.isExist = action.payload;
    },
    successFetchProfile(state, action: PayloadAction<UserProfile>) {
      state.userProfile = action.payload;
    },
    hasError(state, action:PayloadAction<string>) {
      state.error = action.payload;
    },
  },
 
})

export default slice.reducer;

export const {
  successCheckExistEmail,
  hasError,
  startLoading,
  stopLoading,
  successFetchProfile,
  manualUpdateExistEmail
} = slice.actions;

const fetchProfile = () => apiClient.get<UserProfile>('users/profile').then(res => res.data);

export const initExAuth = (): AppThunk => async (dispatch) => {
  dispatch(startLoading())
  try {
    apiClient.post("users/log/create");
    const response =  await apiClient.get<{isExist: boolean}>('/auth/check-exist-mail');
    if(response.data?.isExist) {
      const userProfile = await fetchProfile();
      dispatch(successFetchProfile(userProfile));
    }
    dispatch(successCheckExistEmail(response.data.isExist));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(hasError(ReduxAuthError.checkExistEmailError));
    dispatch(stopLoading());
  }
}

export const fetchUserProfile = (): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
      const userProfile = await fetchProfile();
      dispatch(successFetchProfile(userProfile));
      dispatch(stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(stopLoading());
    }
}

export const createUserProfile = (userData: CreateUserProfileData): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  const body = {...userData};

  if(body.company_name === "") {
    delete body.company_name
  }
  try {
    const response = await apiClient.post('/auth/registry', body);
    if(response.status === 201) {
      dispatch(manualUpdateExistEmail(true));
      dispatch(fetchUserProfile());
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    dispatch(stopLoading());
  }
}