import { Box, styled, Typography, Modal } from "@mui/material";
import { PropsWithChildren } from "react";
import { closeSVGIcon } from "src/assets";
import { COLORS } from "src/utils/colors";
import { Loading } from "../loading";

type Props = PropsWithChildren<{
  title: string;
  width: number;
  height: number;
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
  bottom?: React.ReactNode;
  hasScroll?: boolean
}>;

const ModalWrapper = styled('section')({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const ModalContent = styled(Box)({
  maxHeight: '90vh',
  minHeight: 'max-content',
  backgroundColor: COLORS.panelBg,
  border: '1px solid ' + COLORS.textGrey,
  borderRadius: '5px',
  padding: '20px 28px',
  boxShadow: '0px 3px 6px #00000033',
})

const CloseButton = styled(Box)({
  top: '25px',
  right: '30px',
  position: 'absolute',
  cursor: 'pointer',
  zIndex: 1
});

const Title = styled(Typography)({
  padding: '15px',
  fontWeight: 'bold',
  color: COLORS.textGrey,
  fontSize: '20px'
});

const Bottom = styled(Box)({
  position: 'fixed',
  left: '0px',
  bottom: '2px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
})

const Top = styled(Box)({
  position: 'fixed',
  left: '1px',
  top: '1px',
  width: 'calc(100% - 2px)',
  height: '59px',
  display: 'flex',
  justifyContent: 'start',
  backgroundColor: '#EDEDED',
  borderTopLeftRadius: 'inherit',
  borderTopRightRadius: 'inherit'
})


export const ExModal = ({ width = 200, height = 200, ...props }: Props) => {


  return props.open ? (
    <Modal open onClose={props.onClose} disableAutoFocus>
      <ModalWrapper >
        <ModalContent
          sx={{
            width: `${width}px`,
            height: `${height}px`,
            overflow: props.hasScroll ? 'auto' : 'unset',
            backgroundColor: '#FFFFFF',
          }}
        >

          <Top>
            <Title sx={{
              fontFamily: "Meiryo UI, sans-serif",
              fontSize: '20px',
              fontWeight: 'bold',
            }}>{props.title}</Title>
          </Top>
          <Title></Title>
          <CloseButton onClick={props.onClose}>
            <img alt="close" width={15} height={15} src={closeSVGIcon} />
          </CloseButton>





          {/* <Box sx={{ backgroundColor: '#EDEDED' }}>
            <CloseButton onClick={props.onClose}>
              <img alt="close" width={15} height={15} src={closeSVGIcon} />
            </CloseButton>
            <Title>{props.title}</Title>
          </Box> */}

          {props.isLoading ? <Loading /> : props.children}
          <Bottom>{props.bottom}</Bottom>
        </ModalContent>
      </ModalWrapper>
    </Modal>
  ) : (
    <></>
  );
}