// components
import ZaicoSimulatorBoxLogo from 'src/assets/ZaicoSimulatorBoxLogo'
import ZaicoShindanBoxLogo from 'src/assets/ZaicoShindanBoxLogo'
import PriceSimulatorBoxLogo from 'src/assets/PriceSimulatorBoxLogo';
import { Launch } from '@mui/icons-material';
// import LaunchIcon from '@mui/icons-material/Launch'

const loadLink = () => {

  if (process.env.REACT_APP_ENV === "Dev")
    return {
      'ZaicoShindan': 'https://dev.dqjxdfj735m1z.amplifyapp.com',
      'ZaicoSimulator': 'https://dev.d1f4dt4wqxuren.amplifyapp.com',
      'PriceSimulator': 'https://dev.d2yz3zeoi8qori.amplifyapp.com'
    }
  else if (process.env.REACT_APP_ENV === "Stage") {
    return {
      'ZaicoShindan': 'https://stage.df4w0z5gnu404.amplifyapp.com',
      'ZaicoSimulator': 'https://stage.d1168bekabl2x1.amplifyapp.com',
      'PriceSimulator': 'https://stage.d18gojw0fahmnr.amplifyapp.com'
    }
  }
  else {
    return {
      'ZaicoShindan': 'https://zaikoshindan.ex-series.net',
      'ZaicoSimulator': 'https://www.zaikosimulator.ex-series.net',
      'PriceSimulator': 'https://www.chotatsukakaku.ex-series.net'
    }
  }
}

// EX3-777: Add add-on other service 
const navExtConfig = [
  {
    subheader: 'ext',
    items: [
      {
        title: 'EX在庫診断',
        icon: <ZaicoShindanBoxLogo />,
        path: loadLink()['ZaicoShindan'],
      },
      {
        title: 'EX在庫シミュレータ',
        icon: <ZaicoSimulatorBoxLogo />,
        path: loadLink()['ZaicoSimulator'],
      },

      {
        title: 'EX調達価格シミュレータ',
        icon: <PriceSimulatorBoxLogo />,
        path: loadLink()['PriceSimulator'],
      },

      {
        title: 'License Notice',
        icon: <Launch sx={{ color: "#666666" }} />,
        path: 'https://xeex-products.jp/qrgenpin/qr-license-notice/',
        // color: '#666666',
      },
    ],
  },
];

export default navExtConfig;