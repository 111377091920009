import React from 'react'
import { IconButton, styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import NoticeIcon from 'src/assets/NoticeIcon'
import axios from "src/utils/axios"
import DoneIcon from '@mui/icons-material/Done'
import { BootstrapDialog, BootstrapDialogTitle } from 'src/components/ProfileDialog'

const ContentNotifi = styled(Box)(() => ({
    margin: '5px 20px 20px 20px',
    padding: 8,
    width: 920,
    height: 720,
    backgroundColor: '#FFFFFF',
    border: '1px solid #D4D4D4',
    borderRadius: 5,
    overflow: 'auto',
    // overflowY: 'scroll',
}))

const LIMIT_TEXT = 200

const NotiElement = (props: any) => {
    const [expand, setExpand] = React.useState(false)

    const [value, setValue] = React.useState(props.value)

    const handleClickNoti = async () => {
        if (!value.read) {
            const newObj = { ...value, read: true }
            setValue(newObj)
            const objIndex = props.listNoti.findIndex(
                (obj: any) => obj.notification_id === newObj.notification_id,
            )
            const newList = props.listNoti
            newList[objIndex] = newObj
            let un_read = 0
            for (const el of newList) {
                if (!el.read) un_read++
            }
            props.setListNoti(newList)
            props.setNumNoti(un_read)
            props.setNumStrNoti(numToString(un_read))
            setExpand(!expand)
            try {
                await axios.put(
                    "notifications/read",
                    {
                        notification_id: value.notification_id,
                    },
                    {},
                )
            } catch (err) {
                console.error(err)
            }
        }
    }

    return (
        <Box
            onClick={handleClickNoti}
            key={value.id}
            sx={{
                mb: 1,
                padding: 1,
                bgcolor: value.read || props.readAll ? '#FFFFFF' : '#D4E5E2',
                borderRadius: 1,
                border: '1px solid #D4D4D4',
                cursor: value.read || props.readAll ? 'unset' : 'pointer',
                // boxShadow: '1px 2px #D4D4D4',
            }}
        >
            <Box
                sx={{
                    color: value.read || props.readAll ? '#707070' : '#085749',
                    fontWeight: 'bold',
                    fontSize: 16,
                }}
            >
                {value.title}
            </Box>
            <Box
                sx={{
                    color: '#085749',
                    fontStyle: 'italic',
                }}
            >
                {value.posted_at.slice(0, 10)}
            </Box>
            <Box
                sx={{
                    color: '#646464',
                    pl: 2,
                }}
            >
                {value.content?.length > LIMIT_TEXT ? (
                    <>
                        <Typography
                            component={'span'}
                            sx={{
                                textAlign: 'justify',
                            }}
                        >
                            <pre style={{ fontFamily: 'メイリオ' }}>
                                {expand
                                    ? value.content.slice(0, value.content?.length)
                                    : value.content.slice(0, LIMIT_TEXT) + '...'}
                            </pre>
                        </Typography>
                        <Typography
                            component={'span'}
                            sx={{
                                fontStyle: 'italic',
                                cursor: 'pointer',
                                color: '#2DB19A',
                            }}
                            onClick={() => {
                                setExpand(!expand)
                            }}
                        >
                            {expand ? '一部の表示 ▲' : 'もっと見る ▼'}
                        </Typography>
                    </>
                ) : (
                    <Typography
                        component={'span'}
                        sx={{
                            textAlign: 'justify',
                        }}
                    >
                        <pre style={{ fontFamily: 'メイリオ' }}>
                            {value.content.slice(0, value.content?.length)}
                        </pre>
                    </Typography>
                )}
            </Box>
        </Box>
    )
}

const numToString = (num: number) => {
    if (num <= 9) return num.toString()
    else return num + '+'
}

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '95%',
    backgroundColor: '#fff',
    '& .ag-overlay-loading': {
        backgroundColor: '#fff',
        zIndex: 1029,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        opacity: 0.35,
        transition: 'opacity 0.3s, visibility 0.3s',
    },
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}))

const NoResultsAndRows = () => (
    <svg width='80' height='74' viewBox='0 0 184 152' aria-hidden focusable='false'>
        <g fill='none' fillRule='evenodd'>
            <g transform='translate(24 31.67)'>
                <ellipse
                    className='ant-empty-img-5'
                    cx='67.797'
                    cy='106.89'
                    rx='67.797'
                    ry='12.668'
                />
                <path
                    className='ant-empty-img-1'
                    d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
                />
                <path
                    className='ant-empty-img-2'
                    d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'
                />
                <path
                    className='ant-empty-img-3'
                    d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'
                />
            </g>
            <path
                className='ant-empty-img-3'
                d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
            />
            <g className='ant-empty-img-4' transform='translate(149.65 15.383)'>
                <ellipse cx='20.654' cy='3.167' rx='2.849' ry='2.815' />
                <path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' />
            </g>
        </g>
    </svg>
)

interface Noti {
    notification_id: string,
    title: string,
    content: string,
    posted_at: Date,
    read: boolean
}

const Notification = () => {
    const [isOpenDialog, setIsOpenDialog] = React.useState(false)
    const [numNoti, setNumNoti] = React.useState(0)
    const [numStrNoti, setNumStrNoti] = React.useState(numToString(numNoti))
    const [listNoti, setListNoti] = React.useState<Array<Noti>>([])
    const [readAll, setReadAll] = React.useState(false)
    // eslint-disable-next-line
    React.useEffect(() => {
        getNotification();
    }, [])

    const getNotification = async () => {
        try {
            const res = await axios({ method: 'get', timeout: 10000, url: "notifications/" })
            setListNoti(res?.data?.notifications?.list)
            setNumNoti(res?.data?.notifications?.un_read)
            setNumStrNoti(numToString(res?.data?.notifications?.un_read))
        } catch (err) {
            console.error(err)
        }
    }

    const handleOpenNotifiDialog = async () => {
        setIsOpenDialog(true)
    }

    const handleCloseNotifiDialog = () => {
        setIsOpenDialog(false)
    }

    const handleReadAll = async () => {
        setReadAll(true)
        setNumNoti(0)
        setNumStrNoti(numToString(0))
        for (const el of listNoti) {
            if (!el.read) {
                const newObj = { ...el, read: true }
                const objIndex = listNoti.findIndex(
                    (obj: any) => obj.notification_id === newObj.notification_id,
                )
                const newList = listNoti
                newList[objIndex] = newObj
                setListNoti(newList)
                try {
                    await axios.put(
                        "notifications/read",
                        {
                            notification_id: el.notification_id,
                        },
                        {},
                    )
                } catch (err) {
                    console.error(err)
                }
            }
        }
    }

    return (
        <>
            {numNoti > 0 ? (
                <IconButton
                    onClick={handleOpenNotifiDialog}
                    sx={{
                        color: '#666666',
                        margin: 0,
                        '&:before': {
                            content: `"${numStrNoti}"`,
                            display: 'block',
                            position: 'absolute',
                            fontFamily: 'メイリオ',
                            fontSize: 10,
                            top: 0,
                            width: 16,
                            height: 16,
                            padding: 0.1,
                            bgcolor: 'red',
                            borderRadius: '50%',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            transform: 'translateX(60%)',
                        },
                    }}
                >
                    <NoticeIcon />
                </IconButton>
            ) : (
                <IconButton
                    onClick={handleOpenNotifiDialog}
                    sx={{
                        color: '#666666',
                        margin: 0,
                    }}
                >
                    <NoticeIcon />
                </IconButton>
            )}
            <BootstrapDialog onClose={handleCloseNotifiDialog} open={isOpenDialog} maxWidth={'lg'}
            PaperProps={{
                sx: {
                    width: "100%!important",
                    maxWidth: "960px",
                },
            }}>
                <BootstrapDialogTitle onClose={handleCloseNotifiDialog}>
                    <Box
                        sx={{
                            width: '95.5%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                mt: 0.5,
                            }}
                        >
                            通知情報
                        </Box>
                        <Box
                            sx={{
                                mt: 0.5,
                                fontSize: 17,
                                display: 'flex',
                                color: numNoti === 0 ? '##666666' : '#2DB19A',
                                opacity: numNoti === 0 ? '75%' : '100%',
                                cursor: numNoti === 0 ? 'default' : 'pointer',
                            }}
                            onClick={handleReadAll}
                        >
                            <DoneIcon />
                            <Box>既読</Box>
                        </Box>
                    </Box>
                </BootstrapDialogTitle>
                {listNoti?.length > 0 ? (
                    <ContentNotifi>
                        {listNoti.map((row) => {
                            return (
                                <NotiElement
                                    key={row.notification_id}
                                    value={row}
                                    listNoti={listNoti}
                                    setListNoti={setListNoti}
                                    setNumNoti={setNumNoti}
                                    setNumStrNoti={setNumStrNoti}
                                    readAll={readAll}
                                />
                            )
                        })}
                    </ContentNotifi>
                ) : (
                    <ContentNotifi>
                        <StyledGridOverlay>
                            <NoResultsAndRows />
                            <Box sx={{ opacity: '0.7' }}>通知はありません</Box>
                        </StyledGridOverlay>
                    </ContentNotifi>
                )}
            </BootstrapDialog>
        </>
    )
}

export default Notification
