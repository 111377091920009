import * as React from "react";

import {
  TableRow,
  TableCell,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  styled,
  Grid,
} from '@mui/material';
import CssLog from 'src/utils/CssLog';
import { closeSVGIcon } from "src/assets";
import { ExQuestionMarkIcon } from "src/assets/ExQuestionMarkIcon";


type Props = {
  title: String;
  header?: String;
  conectionName?: String;
  message: String;
  onClickOk: VoidFunction;
  onClickClose: VoidFunction;
  onClickNo: VoidFunction;
};

const CloseButton = styled(Box)({
  top: "18px",
  right: "20px",
  position: "absolute",
  cursor: "pointer",
  zIndex: 1,
});

export default function MailDialog({
  title,
  header,
  message,
  onClickOk,
  onClickClose,
  onClickNo
}: Props) {

  const [open, setOpen] = React.useState(true);

  const [isNoDisable, setIsNoDisable] = React.useState(false);
  const [isOkDisable, setIsOkDisable] = React.useState(false);

  const handleNo = () => {
    onClickNo();
    handleClose();
  };

  const handleOK = () => {
    onClickOk();
    handleClose();
  };

  const handleClose = () => {
    onClickClose();
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      // maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: 5,
          width: '659px',
          height: '255px',
          maxWidth: "unset"
        }
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          pl: '8px'
        }} style={{
          ...CssLog().popup.topViewWarning,

        }}>
        <ExQuestionMarkIcon />
        <DialogTitle style={{ ...CssLog().popup.title, padding: 'unset', paddingLeft: '16px' }} sx={{ wordBreak: 'break-word' }}>{title}</DialogTitle>
        <CloseButton onClick={handleClose}>
          <img alt="close" width={14} height={14} src={closeSVGIcon} />
        </CloseButton>
      </Grid>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
      >
        <Box sx={{
          width: "100%",
          margin: "auto 0",
          overflowY: "auto ",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <TableRow>
            <TableCell style={{ padding: 'unset' }}>
              <DialogContent style={{
                padding: '0px 99px 0px 99px',
                maxHeight: '133px'
              }}>
                {(header !== undefined) ? < DialogTitle style={{ ...CssLog().popup.title }} sx={{ wordBreak: 'break-word', justifyContent: 'center', padding: 'unset' }}>{header}</DialogTitle> : undefined}
                <DialogContentText style={{ ...CssLog().popup.content, }} sx={{
                  overflowWrap: 'break-word', justifyContent: 'center'
                }}>
                  {message}
                </DialogContentText>
              </DialogContent>
            </TableCell>
          </TableRow>
        </Box>

        {/* <Label sx={{ ...CssLog().popup.line }}></Label> */}
        <DialogActions style={{ justifyContent: 'center', }}>
          {<Button disabled={isNoDisable} onClick={() => {
            setIsNoDisable(true)
            handleNo()
            setTimeout(() => {
              setIsNoDisable(false)
            }, 1000)
          }} sx={{ ...CssLog().popup.buttonCommon }} style={{ backgroundColor: '#FFFFFF', borderColor: "#A4A4A4", borderRadius: '4px', color: '#A4A4A4' }}>拒否</Button>}
          {<Button disabled={isOkDisable} onClick={() => {
            setIsOkDisable(true)
            handleOK()
            setTimeout(() => {
              setIsOkDisable(false)
            }, 1000)
          }} sx={{ ...CssLog().popup.buttonCommon }} style={{ ...CssLog().popup.successButton, borderRadius: '4px', }}>参加</Button>}
        </DialogActions>
      </Box>

    </Dialog>
  );
}
