export const COLORS = {
  darkGreen: '#085749',
  grey: '#6E6E6E',
  borderGrey: '#D4D4D4',
  dropdownBg: 'rgba(45, 177, 154, 0.23)',
  panelBg: '#ECECEC',
  textGrey: '#707070',
  blue: '#0062FF',
  primary: '#085749',
  borderGreen: '#259A85',
  badgeRed: '#F35D5D',
  placeholderGrey: '#C5C5C5',
  ghostWhite: '#F5F6FA',
  mainGrey: '#8F8F8F',
  suvaGrey: '#8D8D8D',
  white: '#FFFFFF',
  textGray: '#434343',
  textBlack: '#464646',
  red: '#f80701',
}