import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import apiClient from '../../utils/axios';
import { EditConnectionsState } from 'src/@types/addConnection';
import { updateConnection } from './connections';

const initialState: EditConnectionsState = { isLoading: false, error: '', addData: {role_id: 0, role_name: '', company_id: 0, company_name: '', can_edit_company: false}};

const slice = createSlice({
  name: 'editConnection',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //USER ROLE PROFILE SUCCESS
    editConnectionSuccess(state, action) {
      state.isLoading = false;
      state.addData = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export function editConnection(id: string, name: string) {
  return async () => {
    try {
      const url = 'companies/' + id;
      const result = await apiClient.put(url, {name: name });
      dispatch(updateConnection(result.data));
      dispatch(slice.actions.editConnectionSuccess(result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
