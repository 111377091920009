import React, { PropsWithChildren, useEffect } from "react"
import { Menu, MenuItem, styled, SxProps, Theme } from "@mui/material"
import { COLORS } from "src/utils/colors";
import { Icon } from '@iconify/react';


const MenuToggleStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  userSelect: 'none',
  cursor: 'pointer',
  padding: '2px 4px',
  marginLeft: '16px',
  marginRight: '8px',
  borderRadius: '2px',
  '.dropdown-toggle': {},
  ':hover': {
    backgroundColor: COLORS.dropdownBg,
  },
  '& .menuTitle': {
    fontSize: '12px',
    fontWeight: 'bold',
    color: "white"
  },
  '& .menuSubtitle': {
    fontSize: '18px',
    fontWeight: 'bold',
    color: "white",
    display: 'flex',
    alignItems: 'center',
    '&-name': {
      // #EX3-421: only the first 10 characters will be displayed, the following letters will be displayed as 3 dots
      maxWidth: '213px',
      minWidth: '150px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }
  }
});

const MenuStyled = styled(Menu)({
  '.MuiMenu-paper': {
    boxShadow: "0px 0px 6px #00000029",
    borderRadius: "0px 0px 3px 3px",
    marginTop: '15px',
    position: 'relative',
    overflow: 'visible',
    '&:before': {
      content: '""',
      width: '10px',
      height: '10px',
      transform: 'translateY(-50%) rotate(45deg)',
      top: 0,
      left: 12,
      backgroundColor: 'white',
      position: 'absolute'
    },
  },
})

type Props = PropsWithChildren<{
  title: string,
  subtitle: string,
  isOpen: boolean,
  sx?: SxProps<Theme>,
  onToggle: (isOpen: boolean) => void,
}>

export const ExDropdown = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    props.onToggle(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    props.onToggle(false);
  };

  useEffect(() => {
    if (!props.isOpen) {
      handleClose();
    }
  }, [props.isOpen]);

  return (
    <>
      <MenuToggleStyled
        onClick={handleClick}
        sx={open ? { backgroundColor: COLORS.dropdownBg } : undefined}
        className="dropdown-toggle"
        title={props.subtitle}
      >
        <span className="menuTitle">{props.title}</span>
        <span className="menuSubtitle">
          <span className="menuSubtitle-name">{props.subtitle}</span>

          <Icon style={{ marginLeft: "6px" }} icon="fa:caret-down" />
        </span>
      </MenuToggleStyled>
      <MenuStyled
        anchorEl={anchorEl}
        open={open}
        sx={props.sx}
        MenuListProps={{
          disablePadding: true
        }}
        onClose={(_, reason: string) => {
          if (reason !== 'tabKeyDown') {
            handleClose();
          }
        }}>
        {props.children}
      </MenuStyled>
    </>
  );
}

export const ExMenuItem = styled(MenuItem)({
  color: COLORS.darkGreen,
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
})