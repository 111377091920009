import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography, Link, InputAdornment, IconButton, Input, Grid } from '@mui/material'
import { COLORS } from "src/utils/colors";
import ClearIcon from '@mui/icons-material/Clear'
import axios from "src/utils/axios"
import { BootstrapDialog, BootstrapDialogTitle } from 'src/components/ProfileDialog'
import { LoadingButton } from '@mui/lab'
import { useSelector } from "src/redux/store";
import { LicenseState } from "src/@types/licenseState";
import { ConnectionsState } from "src/@types/companies";
import CssLog from 'src/utils/CssLog';

const ContentConnection = styled(Box)(({ theme }) => ({
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 10,
    paddingBottom: 15,
    // width: 600,
}))

const NoteBox = styled(Box)((props: { value: any }) => ({
    fontSize: 14,
    // maxHeight: 220,
    width: 566,
    padding: '5px 20px 5px 20px',
    borderRadius: 3,
    margin: '0px 0px 10px 0px',
    color: props.value ? '#af4442' : '707070',
    backgroundColor: props.value ? '#f2dede' : '#ffffff',
    justifyContent: 'right !important',
}))

const NoteLicense = (props: any) => {
    const { status, roleId } = props

    if (status === -1 || status === null) {
        return (
            <Typography sx={{
                color: '#5A607F',
                fontFamily: "Meiryo UI, sans-serif",
                fontSize: '18px',
            }}>
                企業UUID、APIキーの取得方法は
                <Link
                    href='https://xeex-products.jp/zaikoshindan/license-registration/'
                    sx={{
                        color: '#085749',
                        textDecoration: 'underline',
                    }}
                    underline='none'
                    target='_blank'
                >
                    オンラインマニュアル
                </Link>
                を参照ください。
            </Typography>
        )
    } else if (status === 0 && roleId === 1) {
        return (
            <>
                <Typography>
                    {`この企業UUID、及びAPIキーはEXQR現品照合のサービスが契約されておりません。`}
                </Typography>
                <Typography>
                    <Link
                        href='https://ex-telligence.com/extelligence/service'
                        sx={{
                            color: '#2DB19A',
                            textDecoration: 'underline',
                        }}
                        underline='none'
                        target='_blank'
                    >
                        EXtelligence
                    </Link>
                    {`のサービスメニューより契約を行ってください。`}
                </Typography>
            </>
        )
    } else if (status === 1) {
        return <></>
    } else if (status === 2 && roleId === 1) {
        return (
            <>
                <Typography>{`ライセンスの有効期間が切れています。`}</Typography>
                <Typography>
                    <Link
                        href='https://ex-telligence.com/extelligence/service'
                        sx={{
                            color: '#2DB19A',
                            textDecoration: 'underline',
                        }}
                        underline='none'
                        target='_blank'
                    >
                        EXtelligence
                    </Link>
                    {`のサービスメニューより契約を行ってください。`}
                </Typography>
            </>
        )
    } else {
        return <></>
    }
}

const LicensePopup = (props: any) => {
    const { isOpenDialog, setIsOpenDialog } = props
    const { selectedConnection }: ConnectionsState = useSelector((state) => state.connections);
    const { dataLicense }: LicenseState = useSelector((state) => state.license);
    const [isLoading, setIsLoading] = React.useState(false)
    const [licenseObjState, setLicenseObjState] = React.useState({
        error: '',
        enterprise_id: dataLicense?.enterprise_id || '',
        api_key: dataLicense?.api_key || '',
        status: dataLicense?.status,
    })
    const [licenseInfoObjState, setLicenseInfoObjState] = React.useState({
        error: '',
        enterprise_id: dataLicense?.enterprise_id,
        api_key: dataLicense?.api_key,
        status: dataLicense?.status,
    })

    const companyUuidRegex = new RegExp(
        '^([a-fA-F0-9]{8})-([a-fA-F0-9]{4})-([a-fA-F0-9]{4})-([a-fA-F0-9]{4})-([a-fA-F0-9]{12})$',
    )
    const apiKeyRegex = new RegExp('^([a-zA-Z0-9]{64})$')

    const isValidCompanyUuid = (companyUuid: string) => {
        if (companyUuid == null) {
            return false
        }
        return companyUuidRegex.test(companyUuid)
    }

    const isValidApiKey = (apiKey: string) => {
        if (apiKey == null) {
            return false
        }
        return apiKeyRegex.test(apiKey)
    }

    // const delay = (ms) => new Promise((res) => setTimeout(res, ms))

    const handleCloseDialog = () => {
        setIsOpenDialog(false)
    }

    const handleChange = (prop: any) => (event: any) => {
        setLicenseObjState({ ...licenseObjState, [prop]: event.target.value?.trim() })
    }

    const handleClickRegister = async () => {
        setIsLoading(true)
        // EX3-798: add cancel license
        if (licenseObjState.enterprise_id === '' && licenseObjState.api_key === '') {
            try {
                const res = await axios.post(
                    "license/delete",
                    {
                        company_id: selectedConnection?.company_id,
                    },
                )

                setLicenseObjState({
                    ...licenseObjState,
                    status: res?.data?.status,
                    error: '',
                })
                setLicenseInfoObjState({
                    ...licenseObjState,
                    status: res?.data?.status,
                })
                if (res?.data.success && res?.data?.status === -1) {
                    handleCloseDialog()
                    window.location.reload()
                }
                return;
            } catch (err) {
                console.error(err)
                if (err?.response.status === 400 || err?.response.status >= 500) {
                    setLicenseObjState({
                        ...licenseObjState,
                        error: 'Delete license failed, please try again later',
                    })
                }
            }
        }
        if (!isValidCompanyUuid(licenseObjState.enterprise_id)) {
            if (!isValidApiKey(licenseObjState.api_key)) {
                setLicenseObjState({
                    ...licenseObjState,
                    error: '企業UUID及びAPIキーが無効です。',
                })
            } else {
                setLicenseObjState({
                    ...licenseObjState,
                    error: '企業UUIDが無効です。',
                })
            }
        }
        if (!isValidApiKey(licenseObjState.api_key) && isValidCompanyUuid(licenseObjState.enterprise_id)) {
            setLicenseObjState({
                ...licenseObjState,
                error: 'APIキーが無効です。',
            })
        }
        if (isValidCompanyUuid(licenseObjState.enterprise_id) && isValidApiKey(licenseObjState.api_key)) {
            try {
                const res = await axios.post(
                    "license/",
                    {
                        company_id: selectedConnection?.company_id,
                        name: selectedConnection?.company_name,
                        enterprise_id: licenseObjState.enterprise_id,
                        api_key: licenseObjState.api_key,
                    },
                )

                setLicenseObjState({
                    ...licenseObjState,
                    status: res?.data?.status,
                    error: '',
                })
                setLicenseInfoObjState({
                    ...licenseObjState,
                    status: res?.data?.status,
                })
                if (res?.data.success && res?.data?.status === 1) {
                    handleCloseDialog()
                    window.location.reload()
                }
            } catch (err) {
                console.error(err)
                if (err?.response.status === 409) {
                    setLicenseObjState({
                        ...licenseObjState,
                        error: '企業UUID及びAPIキーは別の接続先に使用されています。\n別の企業UUID及びAPIキーを入力してください。',
                    })
                }
                if (err?.response.status === 400 || err?.response.status >= 500) {
                    setLicenseObjState({
                        ...licenseObjState,
                        error: '入力した情報は使用できませんでした。\n企業UUID、APIキーを確認して、もう一度やり直してください。',
                    })
                }
            }
        }
        setIsLoading(false)
        // eslint-disable-next-line
    }

    // EX3-798: add cancel license
    const clearEID = () => {
        if (licenseObjState.enterprise_id) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => setLicenseObjState({
                            ...licenseObjState,
                            enterprise_id: '',
                        })}
                    >
                        <ClearIcon fontSize='small' />
                    </IconButton>
                </InputAdornment>
            );
        }

        return "";
    };

    const clearAID = () => {
        if (licenseObjState.api_key) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => setLicenseObjState({
                            ...licenseObjState,
                            api_key: '',
                        })}
                    >
                        <ClearIcon fontSize='small' />
                    </IconButton>
                </InputAdornment>
            );
        }

        return "";
    };

    return (
        <BootstrapDialog
            onClose={handleCloseDialog}
            aria-labelledby='uploadcsv-dialog-title'
            open={isOpenDialog}
            // fullWidth={true}
            maxWidth={'lg'}
            sx={{
                '& .MuiPaper-root': {
                    width: '659px',
                    // height: '292px',
                },
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                    pl: '18px'
                }}
                style={{
                    ...CssLog().popup.topView,
                }}>
                <BootstrapDialogTitle onClose={handleCloseDialog}>
                    <span style={{
                        fontFamily: "Meiryo UI, sans-serif",
                        fontSize: '20px',
                        color: COLORS.textGrey,
                    }}>ライセンス登録
                    </span>
                </BootstrapDialogTitle>
            </Grid>

            <ContentConnection sx={{
                background: "#FFFFFF",
            }}>
                {licenseObjState.error !== '' && (
                    <NoteBox value={true}>
                        <pre style={{
                            fontFamily: "Meiryo UI, sans-serif",
                            fontSize: '16px',
                        }}>{licenseObjState.error}</pre>
                    </NoteBox>
                )}
                <Box>
                    <Box
                        sx={{
                            fontFamily: "Meiryo UI, sans-serif",
                            fontSize: '18px',
                            fontWeight: 'bolder',
                            color: '#434343',
                            // mb: 1,
                        }}
                    >
                        企業UUID
                    </Box>
                    <Input
                        required
                        disableUnderline
                        id="e-id"
                        onChange={handleChange('enterprise_id')}
                        value={licenseObjState.enterprise_id || ''}
                        inputProps={{ maxLength: 50 }}
                        sx={{
                            width: 566,
                            height: 30,
                            backgroundColor: '#FFFFFF',
                            fontFamily: "Meiryo UI, sans-serif",
                            fontSize: '18px',
                            border: ' 1px solid #CECECE',
                            outline: 'none',
                            color: '#5A607F',
                            padding: '0px 8px',
                            borderRadius: '2px',

                            '::placeholder': {
                                color: '#5A607F',
                            },
                            '& .MuiInputBase-input': {
                                padding: '0px 0px 0px 0px'
                            },
                            "&:hover": {
                                border: ' 1px solid black',
                            },
                            '&:focus-within': {
                                border: '2px solid #085749',
                            },

                            // Autofill styles (the new part!)
                            'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
                                '-webkit-background-clip': 'text',
                                // '-webkit-text-fill-color': '#ffffff',  // Set the text color
                                // 'transition': 'background-color 5000s ease-in-out 0s'  // Delay transition
                            }
                        }}
                        endAdornment={clearEID()}
                    />
                </Box>
                <Box>
                    <Box
                        sx={{
                            fontFamily: "Meiryo UI, sans-serif",
                            fontSize: '18px',
                            fontWeight: 'bolder',
                            color: '#434343',
                            // mb: 1,
                            mt: 1,
                        }}
                    >
                        APIキー
                    </Box>
                    <Input
                        required
                        disableUnderline
                        id="api-key"
                        onChange={handleChange('api_key')}
                        value={licenseObjState.api_key || ''}
                        inputProps={{ maxLength: 100 }}
                        sx={{
                            width: 566,
                            height: 30,
                            backgroundColor: '#FFFFFF',
                            fontFamily: "Meiryo UI, sans-serif",
                            fontSize: '18px',
                            border: ' 1px solid #CECECE',
                            outline: 'none',
                            color: COLORS.textGrey,
                            padding: '0px 8px',
                            borderRadius: '2px',
                            '::placeholder': {
                                color: COLORS.placeholderGrey
                            },
                            '& .MuiInputBase-input': {
                                padding: '0px 0px 0px 0px'
                            },
                            "&:hover": {
                                border: '1px solid black',
                            },
                            '&:focus-within': {
                                border: '2px solid #085749',
                            },
                            // Autofill styles (the new part!)
                            'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
                                '-webkit-background-clip': 'text',
                                // '-webkit-text-fill-color': '#ffffff',  // Set the text color
                                // 'transition': 'background-color 5000s ease-in-out 0s'  // Delay transition
                            }
                        }}
                        endAdornment={clearAID()}
                    />
                </Box>
                <Box
                    sx={{
                        fontSize: 10,
                        color: '#ff0000',
                        // width: 520,
                        textAlign: 'center',
                        mt: 1,
                        mb: 2,
                    }}
                >
                    <NoteLicense status={licenseObjState.status} roleId={selectedConnection?.role_id} />
                </Box>
                <LoadingButton
                    loading={isLoading}
                    disabled={
                        // EX3-798: add cancel license
                        // licenseObjState.enterprise_id === '' ||
                        // licenseObjState.api_key === '' ||
                        (licenseObjState.enterprise_id === licenseInfoObjState?.enterprise_id &&
                            licenseObjState.api_key === licenseInfoObjState?.api_key)
                    }
                    onClick={handleClickRegister}
                    sx={{
                        width: '129px',
                        height: '31px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        display: 'flex',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        // mt: 1,
                        // padding: 'unset',
                        // border: '1px solid #259A85',
                        borderRadius: "4px",
                        backgroundColor: COLORS.primary,
                        '&:hover': {
                            backgroundColor: COLORS.primary,
                            opacity: 0.8,
                        },
                        ':disabled': {
                            backgroundColor: "#A4A4A4",
                            color: '#FFFFFF'
                        },
                        color: '#FFFFFF'
                    }}
                >
                    登録
                </LoadingButton>
            </ContentConnection>
        </BootstrapDialog >
    )
}

export default LicensePopup
