import { useLocation } from 'react-router-dom';
import { getActive } from 'src/components/nav-section/index';

const CsvIcon = (props: any) => {
    const { pathname } = useLocation();
    const active = getActive(props.path, pathname);
     
    return (
        <svg
            key={active}
            xmlns="http://www.w3.org/2000/svg"
            width={112.568}
            height={130.04}
            viewBox="0 0 112.568 130.04"
            {...props}
        >
            <g id="CSV_\u30A2\u30A4\u30B3\u30F3" transform="translate(-8912.5 -1263.5)">
                <path
                    id="Icon_metro-file-empty"
                    data-name="Icon metro-file-empty"
                    d="M99.944,31.019A112.216,112.216,0,0,0,90.2,18.357,106.565,106.565,0,0,0,79.053,7.286c-5.765-4.8-8.562-5.358-10.164-5.358H13.442C8.511,1.928,4.5,6.486,4.5,12.087V121.809c0,5.6,4.012,10.159,8.943,10.159H95.718c4.931,0,8.943-4.557,8.943-10.159V42.566c0-1.82-.489-5-4.717-11.547ZM85.14,24.1a107.636,107.636,0,0,1,8.114,10.334H76.043V14.888A102.811,102.811,0,0,1,85.14,24.1Zm12.367,97.7a1.948,1.948,0,0,1-1.789,2.032H13.442a1.948,1.948,0,0,1-1.789-2.032V12.087a1.948,1.948,0,0,1,1.789-2.032H68.889V38.5a3.844,3.844,0,0,0,3.577,4.064H97.506Z"
                    transform="translate(8915.423 1261.572)"
                    fill="currentColor"
                />
                <rect
                    id="\u9577\u65B9\u5F62_1051"
                    data-name="\u9577\u65B9\u5F62 1051"
                    width={112.568}
                    height={53.322}
                    transform="translate(8912.5 1313.411)"
                    fill="currentColor"
                />
                <text
                    id="CSV"
                    transform="translate(8932.521 1355.068)"
                    fill={active ? "#085749" : "#FFFFFF"}
                    fontSize={37}
                    fontFamily="corporate-logo-ver2"
                >
                    <tspan x={0} y={0}>
                        {"CSV"}
                    </tspan>
                </text>
            </g>
        </svg>
    )
};
export default CsvIcon;
