/* eslint-disable jsx-a11y/alt-text */
import {
  styled,
  Box,
  Modal,
  Button,
  Portal,
} from "@mui/material";
import { COLORS } from "src/utils/colors";
import closeSVGIcon from "src/assets/close_ic.svg";
import React from "react";
import { dispatch, useSelector } from "src/redux/store";
import { editConnection } from "src/redux/slices/editConnection";
import { Company } from "src/@types/userProfile";
type Props = {
  open: boolean;
  onClose: () => void;
  connection: Company | null,
};

const ModalWrapper = styled("section")({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const PopupContent = styled("div")({
  minHeight: "130px",
  maxHeight: "90vh",
  width: "444px",
  position: "relative",
  backgroundColor: COLORS.white,
  border: "1px solid " + COLORS.textGrey,
  borderRadius: "8px",
  padding: "20px 28px",
  outline: "none",
  ".grid": {
    margin: "12px 0px 12px -16px",
  },
});

const FormWrapper = styled(Box)({
  padding: "18px 20px"
})

const CloseButton = styled(Box)({
  top: "20px",
  right: "20px",
  position: "absolute",
  cursor: "pointer",
  zIndex: 1,
});

const InputBox = styled(Box)(() => ({
  "& input:hover": {
    border: '1px solid #000000 !important',
  },
  "& input:focus-visible": {
    outline: 'none',
    borderRadius: '3px !important',
    border: '2px solid #085749 !important',
  },
}))

const ConnectionInput = styled('input')({
  width: "100%",
  border: '1px solid #CECECE',
  outline: 'none',
  backgroundColor: 'white',
  color: COLORS.textGrey,
  height: '30px',
  fontSize: '16px',
  fontFamily: "Meiryo UI, sans-serif",
  paddingLeft: '5px',
  paddingBottom: '5px',
  marginTop: '5px',
});

const InputLabel = styled(Box)({
  fontFamily: "Meiryo UI, sans-serif",
  fontSize: '18px',
  fontWeight: 'bold',
  color: COLORS.textGrey,
  '&.role-label': {
    marginTop: '20px'
  }
});

const ButtonWrapper = styled(Box)({
  width: "100%",
  marginTop: '20px',
  paddingLeft: '90px',
})

const AddButton = styled(Button)({
  width: '157px',
  height: '34px',
  fontWeight: "bold",
  borderRadius: "4px",
  backgroundColor: COLORS.primary,
  color: "white",
  padding: '4px 12px',
  ":hover": {
    backgroundColor: COLORS.primary,
    opacity: 0.8,
  },
  ":disabled": {
    backgroundColor: "#A4A4A4",
    color: "white",
  }
})

export const EditConnectionPopup = (props: Props) => {
  const connectionRef = React.useRef<HTMLInputElement>(null);
  const [isValid, setIsValid] = React.useState(false);
  const editDataState = useSelector((state) => state.editConnection);

  const validRegex = /^(?!\s*$).+/;

  React.useEffect(() => {
    if (connectionRef.current) {
      connectionRef.current.value = '';
    }
    setIsValid(false);
  }, [props.open])


  React.useEffect(() => {
    props.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDataState])


  const handleEdit = async () => {
    dispatch(editConnection(props.connection?.company_id.toString() ?? '', connectionRef.current?.value ?? ''));
  }

  const handleConnectionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const isValid = validRegex.test(event.target.value);
    setIsValid(isValid);
  };

  return (
    <>
      <Modal open={props.open} onClose={props.onClose} disableAutoFocus>
        <ModalWrapper>
          <CloseButton onClick={props.onClose}> <img width={15} height={15} src={closeSVGIcon} />
          </CloseButton>
          <PopupContent>
            <FormWrapper>
              <InputLabel>接続先名</InputLabel>
              <InputBox>
                <ConnectionInput
                  required
                  id="e-id"
                  ref={connectionRef}
                  onChange={handleConnectionChange}
                  defaultValue={props.connection?.company_name ?? ''}
                  // #EX3-421: Set a limit up to 30 characters that can be entered for the connection name
                  maxLength={30}
                />
              </InputBox>
              <ButtonWrapper >
                <AddButton
                  // variant="contained"
                  disabled={!isValid}
                  onClick={handleEdit}
                >
                  更新
                </AddButton>
              </ButtonWrapper>
            </FormWrapper>
          </PopupContent>
        </ModalWrapper>
      </Modal>
      <Portal>
      </Portal>
    </>
  );
};
