import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import apiClient from '../../utils/axios';
import { removeMessageState } from 'src/@types/removeMessageState';

const initialState: removeMessageState = { isLoading: false, error: null, status: false };

const slice = createSlice({
  name: 'removeMessage',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //REMOVE USER SUCCESS
    removeMessageSuccess(state, action) {
      state.isLoading = false;
      state.status = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export function removeMessage(id: String, companyID: String) {
  return async () => {
    try {
      const url = 'messages/' + companyID + '/invites/remove';
      await apiClient.post(url, { id: id });
      dispatch(slice.actions.removeMessageSuccess(true));
    } catch (error) {
      // console.log(error);
      dispatch(slice.actions.hasError(error.response.data));
    }
  };
}

export function removeMessageInit() {
  return async () => {
    dispatch(slice.actions.hasError(null));
  };
}

