const CancelIcon = ({ width = 36, height = 36, color = '#0029ff' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 129.997 129.997" xmlns='http://www.w3.org/2000/svg'>
            <path
                d="M65,0a64.989,64.989,0,1,0,.02,0ZM81.469,88.528,65,72.064,48.528,88.535a4.992,4.992,0,0,1-7.117-7l.059-.059L57.94,65.005,41.469,48.534a4.994,4.994,0,0,1,7.059-7.065L65,57.94,81.469,41.469a5,5,0,1,1,7.124,7.007l-.058.058L72.064,65.005,88.535,81.476a5.022,5.022,0,0,1,0,7.065,4.959,4.959,0,0,1-7.013.045l-.046-.045h0Z"
                fill={color}
            />
        </svg>
    )
}

export default CancelIcon