import {
  styled,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Snackbar,
  Alert,
  AlertTitle,
  Portal,
} from "@mui/material";
import { COLORS } from "src/utils/colors";
import React from "react";
import { useRole } from "src/hooks/api/useRoles";
import { USER_ROLE, USER_ROLE_NAME } from "src/config";
import useToggle from "src/hooks/useToggle";
import { Icon } from "@iconify/react";
import apiClient from "src/utils/axios";
import { AxiosError } from "axios";
import ConfirmDialog, { TypeConfirm } from "src/components/table/ConfirmDialog";
import { ExModal } from "../components/modal";
import { Button } from "../components/button";
import { Input } from "../components/input";

type Props = {
  open: boolean;
  onClose: () => void;
  connectionName: string;
  connectionId: number;
  roleId: number;
  email: string;
  id: string;
  reload: () => Promise<unknown>;
  isAuthorized: boolean;
};

const FormWrapper = styled(Box)({
  padding: "18px 19px",
});

// const Email = styled(Box)({
//   fontSize: "16px",
//   color: COLORS.textGrey,
//   whiteSpace: "nowrap",
//   overflow: "hidden",
//   textOverflow: "ellipsis",
// });

const InputLabel = styled(Box)({
  fontFamily: "Meiryo UI, sans-serif",
  fontSize: "18px",
  fontWeight: "bold",
  color: COLORS.textGray,
  "&.role-label": {
    marginTop: "5px",
  },
});

const RoleSelect = styled(Select)({
  outline: 'none',
  fontFamily: "Meiryo UI, sans-serif",
  borderRadius: '2px',
  backgroundColor: 'white',
  width: '100px',
  height: '30px',
  padding: '0px 8px',
  color: COLORS.grey,
  // border: '1px solid #CECECE',
  '&:focus': {
    border: '1px solid #CECECE',
  },
  '&:active': {
    border: '1px solid #CECECE'
  },
  '.MuiOutlinedInput-notchedOutline': {
    // border: 'none'
    border: '1px solid #CECECE',
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // borderRadius: '3px',
    border: '2px solid #085749 !important',
  },

  '.MuiSelect-select': {
    padding: '0px',
    outline: 'none',
    borderRadius: '0px'
  },
  '& .MuiPaper-root': {
    borderRadius: '0px',
    padding: '0',
    marginTop: '2px !importain'
  }
});


const ButtonWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
  marginBottom: "14px"
});


const StyledIcon = styled(Icon)({
  userSelect: "none",
  width: "1em",
  height: "1em",
  display: "inline-block",
  fill: "currentColor",
  flexShrink: "0",
  transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  fontSize: "16px",
  right: "7px",
  position: "absolute",
  pointerEvents: "none",
  top: "calc(50% - .5em)",
  color: "#637381",
});

const SelectIcon = () => <StyledIcon icon="ant-design:caret-down-filled" />;

export const EditUserPopup = (props: Props) => {
  const emailRef = React.useRef<HTMLInputElement>(null);
  const [selectedRole, setSelectedRole] = React.useState<number | undefined>(
    props.roleId
  );
  const [isDuplicateEmail, setIsDuplicateEmail] = React.useState(false);

  const {
    toggle: isOpenComfirm,
    onClose: handleCloseConfirm,
    onOpen: handleOpenConfirm,
  } = useToggle();

  const { data } = useRole({
    companyId: props.connectionId.toString(),
    isEdit: true,
    isAuthorized: props.isAuthorized,
  });

  const roles = data?.data ?? [];

  React.useEffect(() => {
    setSelectedRole(props.roleId);
    if (emailRef.current) {
      emailRef.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const handleEditUser = async () => {
    if (selectedRole === USER_ROLE.contractor && !isOpenComfirm) {
      return handleOpenConfirm();
    }
    try {
      const response = await apiClient.put(
        `/companies/${props.connectionId}/users/${props.id}`,
        {
          role_id: selectedRole?.toString(),
        },
        {
          headers: {
            Authorization: "Bearer 1",
          },
        }
      );
      if (response) {
        await props.reload();
        handleCloseConfirm();
        props.onClose();
      }
    } catch (error) {
      if ((error as AxiosError).response?.status === 400) {
        setIsDuplicateEmail(true);
        handleCloseConfirm();
      }
    }
  };

  const handleRoleChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedRole(event.target.value as number);
  };

  return (
    <>
      <ExModal
        open={props.open}
        onClose={props.onClose}
        height={256}
        width={659}
        title={"ユーザー情報編集"}
      >
        <FormWrapper>
          <InputLabel>ユーザーID</InputLabel>
          {/* <Email>{props.email}</Email> */}
          <Input
            id="e-id"
            ref={emailRef}
            type="email"
            required
            value={props.email}
            disabled={true}
            sx={{
              background: '#EDEDED 0% 0% no-repeat padding-box',
            }}
          />
          <InputLabel className="role-label">権限</InputLabel>
          <RoleSelect
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedRole}
            onChange={handleRoleChange}
            IconComponent={SelectIcon}
            MenuProps={{
              PaperProps: {
                sx: {
                  color: '#5A607F',
                  borderRadius: "1%",
                  maxHeight: 555
                },
              },
            }}
          >
            {roles.map((role) => (
              <MenuItem value={role.id}>{USER_ROLE_NAME[role.id]}</MenuItem>
            ))}
          </RoleSelect>
          <ButtonWrapper>
            <Button
              // variant="contained"
              disabled={props.roleId === selectedRole}
              onClick={handleEditUser}
            >
              適用
            </Button>
          </ButtonWrapper>
        </FormWrapper>
      </ExModal>
      {isOpenComfirm && (
        <ConfirmDialog
          type={TypeConfirm.removeUser}
          title={"契約者権限の付与"}
          message={
            props.email +
            " を契約者に設定した場合、自分の権限は契約者から管理者権限へ切り替わります。本当に変更しますか？"
          }
          onClickOk={() => {
            handleEditUser();
          }}
          onClickCancel={() => handleCloseConfirm()}
        />
      )}
      <Portal>
        <Snackbar
          sx={{ width: 475 }}
          open={isDuplicateEmail}
          onClose={() => setIsDuplicateEmail(false)}
          // TransitionComponent={}
          autoHideDuration={5000}
        >
          <Alert severity="error" sx={{ width: 475 }}>
            <AlertTitle>失敗した</AlertTitle>
            <strong>{"既に追加されているユーザーです"}</strong>
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};
