import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import apiClient from "../../utils/axios";
import { ConnectionsState } from "src/@types/companies";

const initialState: ConnectionsState = {
  isLoading: false,
  error: "",
  connections: { total: 0, current_page: 0, limit: 0, last_page: 0, data: [] },
};

const slice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //USER ROLE PROFILE SUCCESS
    connectionsSuccess(state, action) {
      state.isLoading = false;
      state.connections = action.payload;
    },

    updateConnection(state, action) {
      const targetConnectionIndex = state.connections.data.findIndex(
        (conn) => conn.company_id === action.payload.company_id
      );
      if (targetConnectionIndex > -1) {
        state.connections.data[targetConnectionIndex] = {
          ...state.connections.data[targetConnectionIndex],
          ...action.payload,
        };
      }
    },

    setSelectedConnection(state, action) {
      state.selectedConnection = action.payload;
      // EX3-1275
      localStorage.setItem(
        "selectedConnection",
        JSON.stringify(action.payload.company_id)
      );
    },
  },
});

// Reducer
export default slice.reducer;

export const { updateConnection, setSelectedConnection } = slice.actions;

export function fetchCompanies() {
  return async () => {
    try {
      const url = "companies?page=1&limit=1000&order=-lastJoinedDate%2Cid";
      const result = await apiClient.get(url);
      dispatch(slice.actions.connectionsSuccess(result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
