/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Link, Typography, Box, Container, Button } from '@mui/material'
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useToggle from "src/hooks/useToggle";
import { HEADER, NAVBAR } from '../../config';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import LicensePopup from 'src/components/ex/connection-menu/LicensePopup'
import { useDispatch, useSelector } from '../../redux/store';
import { selectedTemplate } from 'src/redux/slices/templateSelected';
import { LicenseState } from "src/@types/licenseState";
import { AddConnectionPopup } from 'src/components/ex/connection-menu/AddConnectionPopup';
import { COLORS } from 'src/utils/colors';
import FullLogoVert from "src/assets/FullLogoVert";
import Page from 'src/components/Page';
import { getLicense } from "src/redux/slices/license";
import { getMessages } from 'src/redux/slices/messages';

type MainStyleProps = {
  collapseClick?: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  // [theme.breakpoints.up('lg')]: {
  paddingLeft: 1,
  paddingRight: 1,
  // paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
  // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
  width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
  transition: theme.transitions.create('margin-left', {
    duration: theme.transitions.duration.shorter,
  }),
  ...(collapseClick && {
    marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
  }),
  // },
}));
// EX3-735: add page do not have license
const Wrapper = styled(Page)({
  backgroundColor: COLORS.ghostWhite,
  padding: '15px',
  height: '100%',
  '.MuiPaper-root': {
    borderRadius: '3px'
  }
})

const LogsContainer = styled(Container)({
  padding: '0 !important'
});

const TextNoteLicense = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: 2,
}))

const NoteLicense = (props: any) => {
  const { status, roleId } = props
  const [openLicense, setOpenLicense] = useState(false)
  const { toggle, onClose, onOpen } = useToggle();

  if (status === -1 || status === null) {
    return (
      <>
        {roleId === 1 && openLicense && (
          <LicensePopup isOpenDialog={openLicense} setIsOpenDialog={setOpenLicense} />
        )}
        <TextNoteLicense>
          <Link
            href='#'
            underline='none'
            onClick={() => setOpenLicense(true)}
            sx={{
              color: '#2DB19A',
              textDecoration: 'underline',
            }}
          >
            こちら
          </Link>
          からライセンスを登録してください。
        </TextNoteLicense>
        <TextNoteLicense>
          企業UUID、APIキーの取得方法は
          <Link
            href='https://xeex-products.jp/zaikoshindan/license-registration/'
            sx={{
              color: '#2DB19A',
              textDecoration: 'underline',
            }}
            underline='none'
            target='_blank'
          >
            オンラインマニュアル
          </Link>
          をご参照ください。
        </TextNoteLicense>
        <Box margin={2} />
        <TextNoteLicense>
          無料でトライアルをお試しいただくことも可能です。担当よりトライアルの手続きについてご案内
        </TextNoteLicense>
        <TextNoteLicense>
          いたしますので、以下のお問い合わせフォームより気軽にご相談ください。
        </TextNoteLicense>
        <Box margin={1} />
        <Button
          onClick={() => {
            window.open("https://www.xeex.co.jp/ex-trial")
          }}
          sx={{
            bgcolor: '#F9613D',
            width: '150px',
            color: '#FFFFFF',
            fontWeight: 'bold',
            '&:hover': {
              bgcolor: '#E05736',
            }
          }}
        >
          トライアルお申込み
        </Button>
      </>
    )
    // EX3-788: add screen show when has not created connection
  } else if (status === -2) {
    return (
      <>
        <AddConnectionPopup
          open={toggle}
          onClose={onClose}
        />
        <TextNoteLicense>
          <Link
            href='#'
            underline='none'
            onClick={onOpen}
            sx={{
              color: '#2DB19A',
              textDecoration: 'underline',
            }}
          >
            こちら
          </Link>
          より、接続先を作成してください。
        </TextNoteLicense>
        <TextNoteLicense>
          作成方法のマニュアルは
          <Link
            href='https://xeex-products.jp/qrgenpin/qr-connection/'
            sx={{
              color: '#2DB19A',
              textDecoration: 'underline',
            }}
            underline='none'
            target='_blank'
          >
            こちら
          </Link>
          です。
        </TextNoteLicense>
      </>
    )
  } else if (status === 0) {
    return (
      <>
        <TextNoteLicense>
          {`この企業UUID、及びAPIキーはEXQR現品照合のサービスが契約されておりません。\n`}
        </TextNoteLicense>
        <TextNoteLicense>
          <Link
            href='https://ex-telligence.com/extelligence/service'
            sx={{
              color: '#2DB19A',
              textDecoration: 'underline',
            }}
            underline='none'
            target='_blank'
          >
            EXtelligence
          </Link>
          {`のサービスメニューより契約を行ってください。`}
        </TextNoteLicense>
      </>
    )
  } else if (status === 1) {
    if (roleId === 1 || roleId === 2) {
      return (
        <>
          {/* <TextNoteLicense>
            {`接続先に`}
            <Link
              href='#'
              underline='none'
              onClick={() => setOpenUpload(true)}
              sx={{
                color: '#2DB19A',
                textDecoration: 'underline',
              }}
            >
              CSVをアップロード
            </Link>
            {`してください。`}
          </TextNoteLicense> */}
          <TextNoteLicense>
            {`CSVについては`}
            <Link
              href='https://xeex-products.jp/zaikoshindan/csv-output/'
              sx={{
                color: '#2DB19A',
                textDecoration: 'underline',
              }}
              underline='none'
              target='_blank'
            >
              オンラインマニュアル
            </Link>
            {`をご参照ください。`}
          </TextNoteLicense>
        </>
      )
    } else {
      return (
        <>
          <TextNoteLicense>接続先にCSVがアップロードされていません。</TextNoteLicense>
          <TextNoteLicense>
            CSVのアップロードについては接続先の契約者または管理者に確認をして下さい。
          </TextNoteLicense>
        </>
      )
    }
  } else if (status === 2) {
    if (roleId === 1) {
      return (
        <>
          <TextNoteLicense>{`ライセンスの有効期間が切れています。`}</TextNoteLicense>
          <TextNoteLicense>
            <Link
              href='https://ex-telligence.com/extelligence/service'
              sx={{
                color: '#2DB19A',
                textDecoration: 'underline',
              }}
              underline='none'
              target='_blank'
            >
              EXtelligence
            </Link>
            {`のサービスメニューより契約を行ってください。`}
          </TextNoteLicense>
        </>
      )
    } else {
      return (
        <>
          <TextNoteLicense>ライセンスの有効期間が切れています。</TextNoteLicense>
          <TextNoteLicense>
            EXtelligenceの契約担当者にEXQR現品照合の契約状況をご確認ください。
          </TextNoteLicense>
        </>
      )
    }
  } else {
    return <></>
  }
}

// ---------------------------------------------------------------------
export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const selected = useSelector(state => state.templateSelected.templateId);
  const selectedConnetion = useSelector(state => state.connections.selectedConnection);
  const messages = useSelector(state => state.messages);

  const { dataLicense }: LicenseState = useSelector((state) => state.license);

  const dispatch = useDispatch();

  const getLicenseData = () => {
    var id = selectedConnetion?.company_id.toString() ?? '';
    if (id === '') { return }
    dispatch(getLicense(selectedConnetion?.company_id.toString() ?? ''));
  }

  useEffect(() => {
    // EX3-734: Clear column size when change template
    localStorage.removeItem('colWidths');
  }, [selected]);

  useEffect(() => {
    // fix no connection can get message
    dispatch(getMessages());
    getLicenseData();
  }, [selectedConnetion]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: 1,
        }}
      >
        <DashboardHeader
          isCollapse={isCollapse}
          onOpenSidebar={() => setOpen(true)}
          messages={messages}
        />
        <NavbarVertical
          isOpenSidebar={open}
          templateSelected={selected as string}
          onCloseSidebar={() => setOpen(false)}
          onSelectId={(id) => dispatch(selectedTemplate(id))}
        />
        {dataLicense.status === 1 ? (
          <>
            <MainStyle collapseClick={collapseClick}>
              {(selected !== null && selected !== '') ? (
                <Outlet />
              ) : (
                <Stack
                  justifyContent="space-between"
                  alignItems="top"
                  direction={{ sm: "row" }}
                  sx={{ height: "calc(100vh - 55px)" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Wrapper title="EXQR現品照合">
                      <LogsContainer maxWidth={false} sx={{ height: "100%" }}>
                        <Box
                          sx={{
                            backgroundColor: '#FFF',
                            borderRadius: 0.5,
                            textAlign: 'center',
                            flex: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignContent: 'center',
                            }}
                          >
                            <Box sx={{ width: '100%' }}>
                              <FullLogoVert />
                            </Box>
                            <Box
                              sx={{
                                width: '100%',
                                margin: '0 auto',
                                textAlign: 'center',
                                mt: 2,
                              }}
                            >
                              <TextNoteLicense>
                                {`iPhoneよりテンプレートの作成をしてください。\n`}
                              </TextNoteLicense>
                            </Box>
                          </Box>
                        </Box>
                      </LogsContainer>
                    </Wrapper>
                  </Box>
                </Stack>)}
            </MainStyle>
          </>
          // EX3-735: add page do not have license
        ) : (
          <>
            <MainStyle collapseClick={collapseClick}>
              <Stack
                justifyContent="space-between"
                alignItems="top"
                direction={{ sm: "row" }}
                sx={{ height: "calc(100vh - 55px)" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Wrapper title="EXQR現品照合">
                    <LogsContainer maxWidth={false} sx={{ height: "100%" }}>
                      <Box
                        sx={{
                          backgroundColor: '#FFF',
                          borderRadius: 0.5,
                          textAlign: 'center',
                          flex: 'auto',
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center',
                          }}
                        >
                          <Box sx={{ width: '100%' }}>
                            <FullLogoVert />
                          </Box>
                          <Box
                            sx={{
                              width: '100%',
                              margin: '0 auto',
                              textAlign: 'center',
                              mt: 2,
                            }}
                          >
                            <NoteLicense
                              status={dataLicense.status}
                              roleId={selectedConnetion?.role_id}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </LogsContainer>
                  </Wrapper>
                </Box>
              </Stack>
            </MainStyle>
          </>
        )}
      </Box>
    </>
  );
}
