import * as React from "react";
import WarningSVGIcon from 'src/assets/warning_ic.svg';

import {
  TableRow,
  TableCell,
  Dialog,
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  styled,
  Grid,
} from '@mui/material';
import CssLog from 'src/utils/CssLog';
import { closeSVGIcon } from "src/assets";

export enum TypeConfirm {
  downloadCSV,
  removeUser,
  deleteSavedLogs,
};

type Props = {
  type: TypeConfirm;
  title: String;
  header?: String;
  conectionName?: String;
  message: String | JSX.Element;
  onClickOk: VoidFunction;
  onClickCancel: VoidFunction;
};

type CustomProps = {
  type: TypeConfirm;
  title?: String;
  message: String;
  messageAction: String;
  messageSubContent: String;
  messageContent: String;
  onClickOk: VoidFunction;
  onClickRef: VoidFunction;
  onCancel: VoidFunction;
};

const CloseButton = styled(Box)({
  top: "18px",
  right: "20px",
  position: "absolute",
  cursor: "pointer",
  zIndex: 1,
});

export default function ConfirmDialog({
  type,
  title,
  header,
  message,
  onClickOk,
  onClickCancel,
}: Props) {

  const [open, setOpen] = React.useState(true);

  const handleCancel = () => {
    onClickCancel();
    handleClose();
  };

  const handleOK = () => {
    onClickOk();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      // maxWidth={"sm"}
      open={open}
      onClose={handleCancel}
      PaperProps={{
        style: {
          borderRadius: 5,
          width: '659px',
          height: '255px',
          maxWidth: "unset"
        }
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          pl: '8px'
        }} style={{
          ...CssLog().popup.topViewWarning,

        }}>
        <Avatar variant="rounded" style={{ ...CssLog().popup.icon }} src={WarningSVGIcon} />
        <DialogTitle style={{ ...CssLog().popup.title, padding: 'unset', paddingLeft: '16px' }} sx={{ wordBreak: 'break-word' }}>{title}</DialogTitle>
        <CloseButton onClick={handleCancel}>
          <img alt="close" width={14} height={14} src={closeSVGIcon} />
        </CloseButton>
      </Grid>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
      >
        <Box sx={{
          width: "100%",
          margin: "auto 0",
          overflowY: "auto ",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <TableRow>
            <TableCell style={{ padding: 'unset' }}>
              <DialogContent style={{
                padding: '0px 99px 0px 99px',
                maxHeight: '133px'
              }}>
                {(header !== undefined) ? < DialogTitle style={{ ...CssLog().popup.title }} sx={{ wordBreak: 'break-word', justifyContent: 'center', padding: 'unset' }}>{header}</DialogTitle> : undefined}
                <DialogContentText style={{ ...CssLog().popup.content, }} sx={{
                  overflowWrap: 'break-word', justifyContent: 'center'
                }}>
                  {message}
                </DialogContentText>
              </DialogContent>
            </TableCell>
          </TableRow>
        </Box>

        {/* <Label sx={{ ...CssLog().popup.line }}></Label> */}
        <DialogActions style={{ justifyContent: 'center', }}>
          {type !== TypeConfirm.downloadCSV && <Button onClick={handleCancel} sx={{ ...CssLog().popup.buttonCommon }} style={{ backgroundColor: '#FFFFFF', borderColor: "#A4A4A4", borderRadius: '4px', color: '#A4A4A4' }}>キャンセル</Button>}
          {type !== TypeConfirm.downloadCSV && <Button onClick={handleOK} sx={{ ...CssLog().popup.buttonCommon }} style={{ ...CssLog().popup.successRemoveUserButton, borderRadius: '4px', }}>OK</Button>}
          {type === TypeConfirm.downloadCSV && <Button onClick={handleOK} sx={{ ...CssLog().popup.buttonCommon }} style={{ ...CssLog().popup.successButton, borderRadius: '4px', }}>OK</Button>}
        </DialogActions>
      </Box>

    </Dialog>
  );
}


export function CustomConfirmDialog({
  type,
  title,
  message,
  messageAction,
  messageSubContent,
  messageContent,
  onClickOk,
  onClickRef,
  onCancel,
}: CustomProps) {

  const [open, setOpen] = React.useState(true);

  const handleCancel = () => {
    onCancel();
    handleClose();
  };

  const handleOK = () => {
    onClickOk();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      // maxWidth={"sm"}
      open={open}
      onClose={handleCancel}
      PaperProps={{
        style: {
          borderRadius: 5,
          width: '659px',
          height: '255px',
          maxWidth: "unset"
        }
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          pl: '8px'
        }} style={{
          ...CssLog().popup.topViewWarning,

        }}>
        <Avatar variant="rounded" style={{ ...CssLog().popup.icon }} src={WarningSVGIcon} />
        <DialogTitle style={{ ...CssLog().popup.title, padding: 'unset', paddingLeft: '16px' }} sx={{ wordBreak: 'break-word' }}>{title}</DialogTitle>
        <CloseButton onClick={handleCancel}>
          <img alt="close" width={14} height={14} src={closeSVGIcon} />
        </CloseButton>
      </Grid>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
      >
        <Box sx={{
          width: "100%",
          margin: "auto 0",
          overflowY: "auto ",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <TableRow>
            <TableCell style={{ padding: 'unset' }}>
              <DialogContent style={{
                padding: '0px 99px 0px 99px',
                maxHeight: '133px'
              }}>
                <DialogContentText style={{ ...CssLog().popup.content, }} sx={{
                  overflowWrap: 'break-word', justifyContent: 'center', textAlign: "center"
                }}>
                  {message}
                  <p style={{ ...CssLog().textRed }}>{messageAction}</p>


                  <p style={{ ...CssLog().textRed }}>
                    <span
                      style={{
                        // fontWeight: 'bold',
                        cursor: 'pointer',
                        ...CssLog().solidRed,
                        textDecoration: 'underline'
                      }}
                      onClick={onClickRef}
                    >
                      {messageSubContent}
                    </span>
                    {messageContent}
                  </p>
                </DialogContentText>

              </DialogContent>
            </TableCell>
          </TableRow>
        </Box>

        {/* <Label sx={{ ...CssLog().popup.line }}></Label> */}
        <DialogActions style={{ justifyContent: 'center', }}>
          {type !== TypeConfirm.downloadCSV && <Button onClick={handleCancel} sx={{ ...CssLog().popup.buttonCommon }} style={{ backgroundColor: '#FFFFFF', borderColor: "#A4A4A4", borderRadius: '4px', color: '#A4A4A4' }}>キャンセル</Button>}
          {type !== TypeConfirm.downloadCSV && <Button onClick={handleOK} sx={{ ...CssLog().popup.buttonCommon }} style={{ ...CssLog().popup.successRemoveUserButton, borderRadius: '4px', }}>OK</Button>}
          {type === TypeConfirm.downloadCSV && <Button onClick={handleOK} sx={{ ...CssLog().popup.buttonCommon }} style={{ ...CssLog().popup.successButton, borderRadius: '4px', }}>OK</Button>}
        </DialogActions>
      </Box>

    </Dialog>
  );
}
