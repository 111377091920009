// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Chip } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
// import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
// components
import Logo from '../../../components/Logo';
// import Iconify from '../../../components/Iconify';
// import { IconButtonAnimate } from '../../../components/animate';
//
import AccountPopover from './AccountPopover';
// import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
// import CollapseButton from '../navbar/CollapseButton';
import { ConnectionMenu } from 'src/components/ex/connection-menu';
import { TemplateMenu } from 'src/components/ex/template-menu';
import { ExQuestionIcon } from 'src/assets/ExQuestionIcon';
import Notification from 'src/components/ex/notification/Notification'
import { COLORS } from 'src/utils/colors';
import Message from 'src/components/ex/message/Message';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout', })<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  backgroundColor: COLORS.mainGrey,
  boxShadow: 'none',
  height: 55,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  // [theme.breakpoints.up('lg')]: {
  //   height: 64,
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   ...(isCollapse && {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   }),
  //   ...(isOffset && {
  //     height: 64,
  //   }),
  //   ...(verticalLayout && {
  //     width: '100%',
  //     height: 64,
  //     backgroundColor: theme.palette.background.default,
  //   }),
  // },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
  messages?: any;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  messages,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  // const { collapseClick, onToggleCollapse } =
  //   useCollapseDrawer();


  // const isDesktop = useResponsive('up', 'lg');
  // console.log(messages.data);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <AppBar position="fixed" sx={{ height: 55, boxShadow: 'none', backgroundColor: COLORS.primary }}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: '100% !important',
            // px: { lg: 0 },
            // paddingTop: 1.8,
          }}
        >

          {/* {isCollapse && (
          <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
        )} */}

          {isCollapse && <Logo />}

          {/* {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )} */}
          <Box sx={{ width: '1px', height: 'calc(100% - 10px)', backgroundColor: '#B8B8B8', marginLeft: '8px' }}></Box>
          <ConnectionMenu />
          <Box sx={{ width: '1px', height: 'calc(100% - 10px)', backgroundColor: '#B8B8B8', marginLeft: '8px' }}></Box>
          <TemplateMenu />
          {/* <Searchbar /> */}
          {process.env.REACT_APP_ENV === "Stage" && (
            <Chip label="STAGE" color="secondary" sx={{ width: '80px', height: '35px', margin: '15px', fontSize: '16px', fontWeight: 'bold', borderRadius: '4px' }} />
          )}
          {process.env.REACT_APP_ENV === "Dev" && (
            <Chip label="DEV" color="secondary" sx={{ width: '80px', height: '35px', margin: '15px', backgroundColor: "#FFBB00", fontSize: '16px', fontWeight: 'bold', borderRadius: '4px' }} />
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 2 }}>
            {/* <LanguagePopover /> */}
            {/* <NotificationsPopover/> */}
            {/* <ContactsPopover /> */}
            {messages.data.length > 0 ? <Message messages={messages} /> : undefined}
            <Notification />
            <a href="https://xeex-products.jp/qrgenpin/qrgenpin-top/" style={{ display: 'flex' }} target="_blank" rel="noreferrer">
              <ExQuestionIcon />
            </a>
            <AccountPopover />
          </Stack>
        </Toolbar>
      </AppBar>
    </RootStyle>
  );
}
